import { Box, Center, Flex, HStack, Text, chakra } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PageAnimate } from '../../animations/pageAnimate';
import AddDomain from './AddDomain';
import Verification from './Verification';

function SetupDomain() {
    const [isActive, setIsActive]=useState(1)
    return (
    <PageAnimate>
      <Box
        bg="white"
        rounded="4px"
        mt="8px"
        w="full"
        minHeight={"calc(100vh - 98px)"}
      >
        {/* header */}
        <Box p={5}>
        <Text color={'#171D26'} fontSize={20} fontWeight={700}>Setup Domain</Text></Box>
        <Flex
          w="full"
          px={{ base: "24px", md: "40px" }}
          alignItems={"center"}
         
          borderColor={"#eee"}
        >
          <HStack m="auto" spacing={"25px"}  borderBottomWidth="1px">
           
                <Center
                
                  flexDir={"column"}
                  textAlign="center"
                  pt="20px"
                  pb="10px"
                  mb={"-3px"}
                  px="32px"
                  cursor={"pointer"}
                  borderBottom={isActive === 1 ?'1px solid #0066FF': 'none'}
                  onClick={()=>{setIsActive(1)}}
                >

                  <Text
                    fontWeight={500}
                    fontSize="10px"
                    mt="8px"
                    color={isActive === 1 ?"#171D26":"#7489AA"}
                  >
                    1. Add Domain and Senders
                  </Text>
                </Center>
                <Center
                
                  flexDir={"column"}
                  textAlign="center"
                  pt="20px"
                  pb="10px"
                  mb={"-3px"}
                  px="32px"
                  cursor={"pointer"}
                  borderBottom={isActive === 2 ?'1px solid #0066FF': 'none'}
                  onClick={()=>{setIsActive(2)}}
                >
                  <Text
                    fontWeight={500}
                    fontSize="10px"
                    mt="8px"
                    color={isActive === 2 ?"#171D26":"#7489AA"}
                  >
                    2. Verification
                  </Text>
                </Center>
           
          </HStack>

        </Flex> 
        {isActive === 1 ? <AddDomain/> : <Verification/>}

        </Box>
        </PageAnimate>
    );
}

export default SetupDomain;