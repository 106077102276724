import { Box } from "@chakra-ui/react";
import { FC } from "react";
import FormInput from "../../UI/form/FormInput";
import DataComp from "./DataComp";
import { removeHTMLTags } from "../../../functions";

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  provider_reference: string;
  sms_campaign_uid: string;
  contact_uid: string;
  message_body: string;
  message: string;
  message_body_count: number;
  message_from: string;
  status: string;
  failed_reason: string;
  created_at: Date | string;
  updated_at: Date | string;
  contact: {
    id: number;
    uid: string;
    company_uid: string;
    profile_picture: null;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    instagram_username: string;
    instagram_id: string;
    facebook_username: string;
    facebook_id: string;
    twitter_username: string;
    twitter_id: string;
    is_blocked: number;
    deleted_at: string;
    data_source: string;
    metadata: any;
    created_at: Date | string;
    updated_at: Date | string;
  };
}

const MoreDetails: FC<{ data: listProps; sender: string }> = ({
  data,
  sender,
}) => {
  const statusColorsToUse = (status: string) => {
    switch (status) {
      case "sent":
        return {
          color: "#159D6F",
          bg: "rgba(21, 157, 111, 0.04)",
        };
      case "expired":
        return {
          color: "rgb(237, 108, 90)",
          bg: "rgba(237, 108, 90, 0.04)",
        };
      case "delivered":
        return {
          color: "rgb(0, 102, 255)",
          bg: "rgba(0, 102, 255, 0.04)",
        };
      case "rejected":
        return {
          color: "rgb(25, 63, 100)",
          bg: "rgba(25, 63, 100, 0.04)",
        };
      case "failed":
        return {
          color: "rgb(248, 169, 76)",
          bg: "rgba(248, 169, 76, 0.04)",
        };
      case "pending":
        return {
          color: "rgb(25, 63, 100)",
          bg: "rgba(25, 63, 100, 0.04)",
        };
      default:
    }
  };

  return (
    <Box mt="28px">
      {/* Top Box */}
      <Box borderWidth={"1px"} borderColor="borders.100" rounded="4px" w="full">
        <DataComp name="To" value={data?.contact?.phone_number || "---"} />
        <DataComp name="From" value={sender || data?.message_from} />
        <DataComp
          name="Message count"
          value={data?.message_body_count || "---"}
        />
        <DataComp
          name="No. of pages"
          value={
            Math.floor(data?.message_body_count / 160) === 0
              ? 1
              : Math.floor(data?.message_body_count / 160) || "---"
          }
        />

        <DataComp
          name="Status"
          value={
            <Box
              bg={statusColorsToUse(data?.status)?.bg}
              w="fit-content"
              py="4px"
              px="8px"
              rounded="full"
              borderWidth={"0.5px"}
              borderColor={statusColorsToUse(data?.status)?.color}
              color={statusColorsToUse(data?.status)?.color}
              textTransform="capitalize"
              fontWeight={500}
            >
              {data?.status === "in-progress" ? "In Progress" : data?.status}
            </Box>
          }
        />
        <DataComp
          name="Failed reason"
          value={data?.failed_reason || "No reason available"}
        />
      </Box>

      {/* history */}
      {/* <Box my='32px'>
        {timelineData?.map((timeline, index) => {
          const isLast = index === timelineData?.length - 1;

          return (
            <SingleTimeline
              key={index}
              action={timeline?.action}
              title={timeline?.title}
              date={timeline?.date}
              hideRect={isLast}
            />
          );
        })}
      </Box> */}

      {/* Message Textarea */}
      <FormInput
        label="Message"
        formControlProps={{
          mt: "32px",
          mb: "40px",
        }}
        textareaProps={{
          minH: "96px",
          placeholder: "Message",
          isReadOnly: true,
          value: removeHTMLTags(data?.message_body || data?.message),
        }}
        type="textarea"
        for="message"
      />
    </Box>
  );
};

export default MoreDetails;
