import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  chakra,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ChakraSelect from "../../../../components/UI/form/ChakraSelect";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { getListContactsData } from "../../../../API/account/list";
import SettingsListContacts from "../../../../components/account/lists/SettingsListContacts";
import AddContactToList from "../../../../components/contacts/AddContactToList";
import CustomModal from "../../../../components/UI/CustomModal";
import Empty from "../../../../components/UI/Empty";
import { usePagination } from "@ajna/pagination";
import CustomPaginaton from "../../../../components/UI/CustomPaginaton";
import { GoBack } from "../../../../components/UI/Buttons";
import API from "../../../../API";
import toast, { Toaster } from "react-hot-toast";

const SingleListContacts = () => {
  const { id } = useParams();
  const [dataToShow, setDataToShow] = useState(15);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [listUid, setListUid] = useState<string>("");

  const { isLoading, data } = useQuery(
    ["contactlistData", myCurrentPage, dataToShow],
    () => {
      return getListContactsData(id, myCurrentPage, dataToShow);
    }
  );

  // console.log("we haveeeee", data);

  const disclosure = useDisclosure();
  const removeDisclosure = useDisclosure();

  const [searchParams] = useSearchParams();

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uid: string
  ) => {
    // if (event.target.checked) {
    //   setCheckedItems((prev) => [...prev, uid]);
    // } else {
    //   setCheckedItems((prev) => prev.filter((itemUid) => itemUid !== uid));
    // }
    setCheckedItems((prev) =>
      prev.includes(uid)
        ? prev.filter((itemUid) => itemUid !== uid)
        : [...prev, uid]
    );
  };
  // console.log("checked", checkedItems);

  const handleCheckAllChange = (checked: boolean) => {
    // const checked = event.target.checked;
    console.log("state of check", isAllChecked);
    setIsAllChecked(true);
    console.log("state of isAll", isAllChecked);
    if (checked) {
      setCheckedItems(data?.docs?.map((item: any) => item.contact.uid));
    } else {
      setCheckedItems([]);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await API.post("/contact-list/remove-contact", {
        list_uid: listUid,
        contact_uids: checkedItems,
      });
      removeDisclosure.onClose();
      toast.success(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error: any) {
      toast.error("Error posting data to backend:", error);
    }
  };

  // useEffect(() => { setIsAllChecked(checkedItems.length === data?.docs?.length && data?.docs.length > 0); }, [checkedItems, data?.docs]);

  return (
    <Box w="full" h="full">
      <Toaster />
      {/* <Link to="/account/lists">
        {" "}
        <HStack spacing={"4px"}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5L8.5 10.25L12 7"
              stroke="#7489AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text fontSize={"xs"} color="grays.12">
            Back to list
          </Text>
        </HStack>
      </Link> */}
      <GoBack>Back</GoBack>

      {/* Table header */}
      <Flex mt="20px">
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          {searchParams?.get("name")}
          <Text as="span" color={"grays.12"}>
            {" "}
            ({data?.docs?.length} contacts)
          </Text>
        </Text>

        <HStack ml="auto" my="auto" spacing={"16px"}>
          {/* <Button variant={'outline'} colorScheme={'primary'} size={'sm'}>
            Import contact to list
          </Button> */}

          <Button
            colorScheme={"primary"}
            size={"sm"}
            onClick={disclosure.onOpen}
          >
            Add contact to list
          </Button>
          <Button
            colorScheme={"primary"}
            size={"sm"}
            onClick={removeDisclosure.onOpen}
          >
            Remove contact from list
          </Button>
        </HStack>
      </Flex>

      <Box mt="32px">
        {/* Empty */}
        {!isLoading && data?.docs?.length === 0 && (
          <Empty
            title="You haven’t made any contacts yet"
            description="Add a new contact"
            buttonText="Add Contact"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M82 40.76H70.44C60.96 40.76 53.24 33.04 53.24 23.56V12C53.24 9.8 51.44 8 49.24 8H32.28C19.96 8 10 16 10 30.28V65.72C10 80 19.96 88 32.28 88H63.72C76.04 88 86 80 86 65.72V44.76C86 42.56 84.2 40.76 82 40.76Z"
                  fill="#7489AA"
                />
                <path
                  d="M63.1987 8.84193C61.5587 7.20193 58.7188 8.32193 58.7188 10.6019V24.5619C58.7188 30.4019 63.6787 35.2419 69.7187 35.2419C73.5187 35.2819 78.7987 35.2819 83.3187 35.2819C85.5987 35.2819 86.7987 32.6019 85.1987 31.0019C79.4387 25.2019 69.1187 14.7619 63.1987 8.84193Z"
                  fill="#7489AA"
                />
                <path
                  d="M54 55H30C28.36 55 27 53.64 27 52C27 50.36 28.36 49 30 49H54C55.64 49 57 50.36 57 52C57 53.64 55.64 55 54 55Z"
                  fill="#7489AA"
                />
                <path
                  d="M46 71H30C28.36 71 27 69.64 27 68C27 66.36 28.36 65 30 65H46C47.64 65 49 66.36 49 68C49 69.64 47.64 71 46 71Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={disclosure.onOpen}
          />
        )}

        {isLoading && (
          <Center flexDir={"column"} h="50vh">
            <Spinner colorScheme={"blue"} />
            <Text mt={5} fontSize="sm" fontWeight={500} color="grays.12">
              Fetching data...
            </Text>
          </Center>
        )}

        {!isLoading && data?.docs?.length > 0 && (
          <>
            <SettingsListContacts
              data={data?.docs}
              handleCheckboxChange={handleCheckboxChange}
              setListUid={setListUid}
              handleCheckAllChange={handleCheckAllChange}
              isAllChecked={isAllChecked}
              checkedItems={checkedItems}
            />

            <HStack
              spacing={"10px"}
              pt="30px"
              pb={"36px"}
              w="100%"
              justifyContent={"space-between"}
            >
              <HStack>
                <Text color="grays.12" fontWeight={500} fontSize="xs">
                  Show
                </Text>
                <ChakraSelect
                  selectProps={{
                    w: "fit-content",
                    h: "24px",
                    borderColor: "grays.12",
                    fontWeight: 500,
                    color: "grays.12",
                    value: Number(dataToShow),
                    onChange: (e) => {
                      setDataToShow(Number(e.target.value));
                    },
                  }}
                >
                  <chakra.option value={15}>15</chakra.option>
                  <chakra.option value={20}>20</chakra.option>
                  <chakra.option value={25}>25</chakra.option>
                  <chakra.option value={30}>30</chakra.option>
                  <chakra.option value={50}>50</chakra.option>
                </ChakraSelect>
              </HStack>

              <CustomPaginaton
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
                numberOfdataToShow={dataToShow}
                setDataToShow={setDataToShow}
              />
            </HStack>
          </>
        )}
      </Box>
      <CustomModal disclosure={removeDisclosure}>
        <Box mt="38px" pb="32px" textAlign={"center"} w="full">
        {checkedItems.length > 0 ? (
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Remove contact
          </Text>) : (<Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Select contact
          </Text>)}

          {checkedItems.length > 0 ? (
            <Box
              mt="5px"
              fontSize={"sm"}
              fontWeight="medium"
              color="grays.12"
              maxW={"360px"}
              mx="auto"
            >
              <Text>
                Contact will be removed from this list but not deleted.
              </Text>
              <Text mt="20px">Are you sure you wish to do this?.</Text>
            </Box>
          ) : (
            <Box
              mt="5px"
              fontSize={"sm"}
              fontWeight="medium"
              color="grays.12"
              maxW={"360px"}
              mx="auto"
            >
              <Text>No Contacts Selected</Text>
              <Text>Please select the contacts you want to remove</Text>
            </Box>
          )}

          {checkedItems.length > 0 && (
            <Button
              w="full"
              colorScheme={"red"}
              mt="40px"
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Remove contact
            </Button>
          )}
        </Box>
      </CustomModal>

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Add contact
          </Text>
        }
      >
        <AddContactToList isContactList={true} close={disclosure.onClose} />
      </CustomModal>
    </Box>
  );
};

export default SingleListContacts;
