import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendLoginOTP } from "../API/auth";
import AuthLayout from "../components/general/AuthLayout";
import FormInput from "../components/UI/form/FormInput";
import { AUTH_ROUTES } from "../utils/routes";
import { toaster } from "../utils/toast";

const Login = () => {
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(sendLoginOTP, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      navigate(AUTH_ROUTES?.CONFIRM_EMAIL + `?email=${email}`);
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  // console.log(`${process.env.REACT_APP_API_URL} ziz!`)
  return (
    <AuthLayout
      title="What’s your business email?"
      description="We’ll send you a verification code there"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate(email);
        }}
      >
        <FormInput
          label="Business email address"
          type={"email"}
          for="business email"
          formControlProps={{
            isRequired: true,
            mt: "32px",
          }}
          inputProps={{
            placeholder: "Enter business mail",
            onChange: (e) => {
              setEmail(e.target.value);
            },
          }}
        />

        <Button
          isDisabled={!email}
          isLoading={isLoading}
          type="submit"
          w="full"
          colorScheme={"primary"}
          mt="40px"
        >
          Continue
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
