import {
  Text,
  Box,
  Flex,
  Progress,
  Divider,
  Button,
  HStack,
  useDisclosure,
  // chakra,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import BillingsTable from "../../../components/account/billings/billingsTable";
import { useQuery } from "@tanstack/react-query";
import {
  getCurrentPlan,
  getDefaultCard,
  listAllSubscriptions,
} from "../../../API/account/billings";
import { useNavigate } from "react-router-dom";
import Empty from "../../../components/UI/Empty";
import Loader from "../../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import { useState } from "react";
import CustomPaginaton from "../../../components/UI/CustomPaginaton";
import DefaultCard from "../../../components/account/billings/defaultCard";
import { AddIcon } from "../../../icons/addIcon";
import AddCardModal from "../../../components/account/billings/AddCardModal";
import { getTeammatesCount } from "../../../API/account/teammates";
import BillingReceipt from "../../../components/account/billings/billingInvoice";

const Billings = () => {
  const navigate = useNavigate();
  const addNewCardDisclosure = useDisclosure();
  const [dataToShow, setDataToShow] = useState(10);
  const [myCurrentPage, setMyCurrentPage] = useState(1);

  const [invoice, setInvoice] = useState(false);

  //redirect users to upgrade their plan after adding a card
  const redirectToPlans = () => {
    navigate("/account/billings/plans");
  };

  const { data: defaultCard } = useQuery(["defCard"], getDefaultCard);

  const { data: currentPlan } = useQuery(["currentPlan"], getCurrentPlan);

  const { isLoading: loadingCount, data: teammateCount } = useQuery(
    ["countTeammate"],
    getTeammatesCount
  );
  const { isLoading: loadingBillingHistory, data: allSubscriptions } = useQuery(
    ["subscriptionList", myCurrentPage, dataToShow],
    () => {
      return listAllSubscriptions(myCurrentPage, dataToShow);
    }
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: allSubscriptions?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <>
      {!invoice ? (
        <Box w="full" h="full">
          <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
            Billing
          </Text>
          <Text color="grays.12" fontSize={"12px"} fontWeight={"500"}>
            Manage your billing and payment details.
          </Text>
          <Flex justifyContent="space-between" mt="20px">
            <Box>
              <Box
                p="20px"
                h={"174px"}
                w={"555px"}
                borderColor="borders.600"
                borderWidth={"1px"}
                borderRadius={"4px"}
              >
                <Flex justifyContent={"space-between"}>
                  <Box>
                    <Text color="grays.9" fontSize="14px" fontWeight="700">
                      {currentPlan?.plan?.name || "Free"} plan
                    </Text>
                    <Text color="grays.12" fontSize={"12px"} fontWeight="500">
                      You are currently on the{" "}
                      {currentPlan?.plan?.name?.toLowerCase() || "free"} plan
                      with{" "}
                      {!currentPlan || currentPlan?.plan?.name === "Free"
                        ? 3
                        : 20}{" "}
                      users.
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={"32px"} color="grays.9" fontWeight="700">
                      ${currentPlan?.plan?.amount || 0}
                      <Text
                        as="span"
                        color="grays.12"
                        display="inline"
                        fontSize={"12px"}
                        fontWeight={"500"}
                      >
                        per month
                      </Text>
                    </Text>
                  </Box>
                </Flex>
                {loadingCount && <Text>Loading...</Text>}

                {!loadingCount && (
                  <Box>
                    <Text fontSize={"12px"} fontWeight="700" mt={"5px"}>
                      {teammateCount?.count} of{" "}
                      {!currentPlan || currentPlan?.plan?.name === "Free"
                        ? 3
                        : 20}{" "}
                      users onboarded
                    </Text>
                    <Progress
                      value={teammateCount?.count}
                      max={
                        !currentPlan || currentPlan?.plan?.name === "Free"
                          ? 3
                          : 20
                      }
                      colorScheme={"primary"}
                      size="sm"
                      borderRadius={"50px"}
                    />
                  </Box>
                )}
              </Box>
              <Box mt={"-55px"}>
                <Divider colorScheme={"primary"} />
              </Box>
              <Box
                mr="10px"
                display="flex"
                justifyContent="end"
                alignItems="center"
                justifyItems="center"
              >
                {/* <Button
                  my="20px"
                  colorScheme="primary"
                  variant="link"
                  size="sm"
                  onClick={() => navigate(`/account/billings/plans`)}
                >
                  View plans and Upgrade
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L6 13.5"
                      stroke="#0066FF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 12.3988V5H7.60124"
                      stroke="#0066FF"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button> */}
              </Box>
            </Box>

            <Box>
              <Box
                p="20px"
                w="345px"
                h="174px"
                borderColor="borders.600"
                borderWidth={"1px"}
                borderRadius={"4px"}
              >
                <Link to="/account/billings/cards">
                  <Text fontSize={"14px"} fontWeight="700" color="grays.9">
                    Payment methods
                  </Text>
                  <Text color="grays.12" fontSize={"12px"} fontWeight="500">
                    Manage how you pay for your plan
                  </Text>
                </Link>

                {defaultCard ? (
                  <DefaultCard data={defaultCard} />
                ) : (
                  <Box
                    onClick={addNewCardDisclosure.onOpen}
                    mt={4}
                    cursor="pointer"
                  >
                    <HStack justifyContent="center">
                      <AddIcon color="#0066FF" />
                      <Text color="#0066FF" fontSize={"12px"} fontWeight={700}>
                        Add new payment method
                      </Text>
                    </HStack>
                  </Box>
                )}
              </Box>
              <Box mt={"-55px"}>
                <Divider colorScheme={"primary"} />
              </Box>
              {!defaultCard ? (
                <Link to="/account/billings/cards">
                  <Box
                    mr="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    justifyItems="center"
                  >
                    <Button
                      my="10px"
                      color="grays.12"
                      variant="unstyled"
                      size="sm"
                    >
                      Manage Cards
                    </Button>
                  </Box>
                </Link>
              ) : (
                <Box
                  mr="10px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  justifyItems="center"
                  onClick={addNewCardDisclosure.onOpen}
                >
                  <Button
                    my="10px"
                    color="grays.12"
                    variant="unstyled"
                    size="sm"
                  >
                    <HStack justifyContent={"center"} alignItems={"center"}>
                      <AddIcon />
                      <Text>Add new payment method</Text>
                    </HStack>
                  </Button>
                </Box>
              )}
            </Box>
          </Flex>

          <Box pt={"24px"}>
            <Heading
              as={"h3"}
              fontSize={"16px"}
              color="grays.8"
              fontWeight={500}
              mb={"16px"}
            >
              Billing History
            </Heading>
            {loadingBillingHistory && <Loader />}
            <>
              {!loadingBillingHistory &&
                allSubscriptions?.docs?.length === 0 && (
                  <Empty description="You have no billing history yet." />
                )}
              {!loadingBillingHistory && allSubscriptions?.docs?.length > 0 && (
                <>
                  <Box
                    borderWidth={"1px"}
                    borderColor={"borders.100"}
                    height="40vh"
                    overflowY={"auto"}
                    className="thinSB"
                  >
                    <BillingsTable
                      billingsData={allSubscriptions?.docs}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      onView={() => {
                        // console.log(allSubscriptions?.docs);
                        setInvoice(true);
                      }}
                    />
                  </Box>

                  <HStack
                    // spacing={"10px"}
                    pb="30px"
                    // ml="auto"
                    // w="fit-content"
                    justifyContent={"end"}
                  >
                    {/* <Text color="grays.12" fontWeight={500} fontSize="xs">
                      Show
                    </Text>

                    <ChakraSelect
                      selectProps={{
                        h: "24px",
                        borderColor: "grays.12",
                        fontWeight: 500,
                        color: "grays.12",
                        value: Number(dataToShow),
                        onChange: (e) => {
                          setDataToShow(Number(e.target.value));
                        },
                      }}
                    >
                      <chakra.option value={10}>10</chakra.option>
                      <chakra.option value={20}>20</chakra.option>
                      <chakra.option value={25}>25</chakra.option>
                      <chakra.option value={30}>30</chakra.option>
                      <chakra.option value={50}>50</chakra.option>
                    </ChakraSelect> */}
                    <CustomPaginaton
                      pagesCount={pagesCount}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      pages={pages}
                      numberOfdataToShow={dataToShow}
                      setDataToShow={setDataToShow}
                    />
                  </HStack>
                </>
              )}
            </>
          </Box>
          <AddCardModal
            disclosure={addNewCardDisclosure}
            onAddCardSuccess={redirectToPlans}
          />
        </Box>
      ) : (
        <BillingReceipt
          data={allSubscriptions?.docs}
          onHistory={() => setInvoice(!invoice)}
        />
      )}
    </>
  );
};

export default Billings;
