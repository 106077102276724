import { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  AddContactIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from "../../../assets/svgs/svg";
import { SingleContactProps } from "../../../utils/generalProps";
// import LabelValue from '../../conversations/ChatData/LabelValue';
// import CustomModal from '../../UI/CustomModal';
import FormInput from "../../UI/form/FormInput";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSingleContact } from "../../../API/contacts";
import { toaster } from "../../../utils/toast";
import ProgressLoader from "../../UI/ProgressLoader";
import { DropIn } from "../../animations";
// import CustomPhoneInput from "../../UI/form/CustomPhoneInput";

type addedFrom = "import" | "mobile" | "form";
type CustomAttr = { label: string; value: any };

const ContactInfo: FC<{
  data: SingleContactProps | undefined;
  query: any;
  refetchConversationHistory: () => any;
}> = ({ data, query, refetchConversationHistory }) => {
  let colors = {
    bgColor: "",
    textColor: "",
  };
  const getAddedFromColor = (type: addedFrom) => {
    if (type === "import") {
      colors = {
        bgColor: "rgba(0, 102, 255, 0.08)",
        textColor: "primary.500",
      };
    }

    if (type === "mobile") {
      colors = {
        bgColor: "rgba(21, 157, 111, 0.08)",
        textColor: "#159D6F",
      };
    }

    if (type === "form") {
      colors = {
        bgColor: "rgba(253, 189, 79, 0.08)",
        textColor: "#FDBD4F",
      };
    }

    return colors;
  };
  const [extraAttributes, setExtraAttributes] = useState<CustomAttr[]>([]);
  const [fullName, setFullName] = useState(
    `${data?.first_name === null ? "" : data?.first_name} ${
      data?.last_name === null ? "" : data?.last_name
    }`
  );

  const [email, setEmail] = useState(data?.email === null ? "" : data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number);
  const [uid] = useState(data?.uid);
  const [viewmore, setViewmore] = useState(false);
  const [signedUp] = useState(
    dayjs(data?.updated_at).format("DD/MM/YYYY - hh:mmA")
  );

  const queryClient = useQueryClient();
  const toast = useToast();
  const updateContactMutation = useMutation(updateSingleContact, {
    onSuccess: (data) => {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(query);

      refetchConversationHistory();
    },
    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const onSubmit = ({
    attr,
    newValue,
    isName,
  }: {
    attr: string;
    newValue: any;
    isName?: boolean;
  }) => {
    if (isName) {
      let firstName = fullName.split(" ").slice(0, -1).join(" ");
      let lastName = fullName.split(" ").slice(-1).join(" ");

      updateContactMutation.mutate({
        uid: uid!,
        attr: "first_name",
        newValue: firstName,
        attr2: "last_name",
        newValue2: lastName,
      });
    } else {
      updateContactMutation.mutate({
        uid: uid!,
        attr: attr,
        newValue: newValue,
        // first_name: firstName,
        // last_name: lastName,
        // email: email!,
        // phone_number: phoneNumber!,
      });
    }
  };

  // check for extra attributes
  useEffect(() => {
    if (data?.custom_attributes) {
      let op = [];
      for (const [key, value] of Object.entries(data.custom_attributes)) {
        op.push({ label: key, value });
      }

      setExtraAttributes(op);
    }
  }, [data]);

  return (
    <>
      {updateContactMutation.isLoading && <ProgressLoader />}

      <SimpleGrid bg="#f2f4f7" rowGap="1.5em" width={"360px"}>
        <DropIn fullWidth>
          <Box bg={"white"} px="20px" borderRadius={"4px"}>
            <Link to="/contacts">
              <HStack spacing={"4px"}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13.5L8.5 10.25L12 7"
                    stroke="#7489AA"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <Text fontSize={"xs"} color="grays.12">
                  Back to contacts
                </Text>
              </HStack>
            </Link>

            {/*  */}
            <Flex
              columnGap={"16px"}
              mt="32px"
              pb={"32px"}
              alignItems={"center"}
            >
              <Avatar
                w="76px"
                h="76px"
                name={`${data?.first_name} ${data?.last_name}`}
                src={data?.profile_picture}
                objectFit="cover"
              />
              <SimpleGrid rowGap={"2px"}>
                <Text
                  fontSize={"20px"}
                  fontWeight={700}
                  color="grays.8"
                  maxWidth={"340px"}
                  overflowX={"hidden"}
                >
                  {data?.first_name} {data?.last_name}
                </Text>

                <HStack spacing={"4px"}>
                  <Flex>
                    <AddContactIcon />
                    <Text fontSize={"xs"} color="grays.12">
                      Added from:
                    </Text>
                  </Flex>

                  <Badge
                    textTransform={"none"}
                    size="sm"
                    color={getAddedFromColor("mobile")?.textColor}
                    bg={getAddedFromColor("mobile")?.bgColor}
                  >
                    {data?.data_source || "Not Available"}
                  </Badge>
                </HStack>
              </SimpleGrid>
            </Flex>
          </Box>
        </DropIn>

        <DropIn fullWidth delay={0.5}>
          <Box bg="white" display="grid" px="4" py="4">
            <Text fontSize="14px" fontWeight="700" mb="8px">
              Contact Attributes
            </Text>
            <VStack align={"flex-start"}>
              <FormInput
                m="0em"
                py="2"
                borderBottom=" 1px solid rgba(0, 102, 255, 0.04)"
                label="Full name"
                type="text"
                for="Full name"
                labelColor="#7489AA"
                value={fullName}
                editableText={true}
                inputProps={{
                  placeholder: "Enter Full name",
                  value: fullName,
                  onChange: (e) => {
                    setFullName(e.target.value);
                  },
                }}
                onMutate={() => {
                  onSubmit({
                    attr: "fullName",
                    newValue: fullName,
                    isName: true,
                  });
                }}
              />
              <FormInput
                m="0em"
                py="2"
                borderBottom=" 1px solid rgba(0, 102, 255, 0.04)"
                label="Email address"
                type="text"
                for="Email address"
                labelColor="#7489AA"
                value={email}
                editableText={true}
                inputProps={{
                  placeholder: "Enter email address",
                  value: email,
                  onChange: (e) => {
                    setEmail(e.target.value);
                  },
                }}
                onMutate={() => {
                  onSubmit({
                    attr: "email",
                    newValue: email,
                  });
                }}
              />

              {/* <CustomPhoneInput label="Phone number" isEditable /> */}

              <FormInput
                m="0em"
                py="2"
                borderBottom=" 1px solid rgba(0, 102, 255, 0.04)"
                label="Phone number"
                type="text"
                for="Phone number"
                labelColor="#7489AA"
                value={phoneNumber}
                editableText={true}
                inputProps={{
                  placeholder: "Enter Phone number",
                  value: phoneNumber,
                  onChange: (e) => {
                    setPhoneNumber(e.target.value);
                  },
                }}
                onMutate={() => {
                  onSubmit({
                    attr: "phone_number",
                    newValue: phoneNumber,
                  });
                }}
              />

              <FormInput
                m="0em"
                py="2"
                borderBottom=" 1px solid rgba(0, 102, 255, 0.04)"
                label="User id"
                type="text"
                for="User id"
                noEdit={true}
                labelColor="#7489AA"
                value={uid}
                editableText={true}
                inputProps={{
                  placeholder: "Enter User id",
                  value: uid,
                  // onChange: (e) => {
                  //   setUid(e.target.value);
                  //   // setLastName(e.target.value)
                  // },
                }}
              />
              <FormInput
                m="0em"
                py="2"
                borderBottom=" 1px solid rgba(0, 102, 255, 0.04)"
                label="Signed Up"
                type="text"
                for="Signed Up"
                noEdit={true}
                labelColor="#7489AA"
                value={signedUp}
                editableText={true}
                inputProps={{
                  placeholder: "",
                  value: signedUp,
                  // onChange: (e) => {
                  //   setUid(e.target.value);
                  //   // setLastName(e.target.value)
                  // },
                }}
              />

              {!viewmore && (
                <Text
                  cursor={"pointer"}
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"brand.main"}
                  pt={"8px"}
                  onClick={() => setViewmore(!viewmore)}
                >
                  View more
                </Text>
              )}

              {viewmore && (
                <>
                  {extraAttributes.length > 0 ? (
                    <VStack
                      width={"100%"}
                      alignItems={"flex-start"}
                      spacing={"12px"}
                    >
                      {extraAttributes.map((item, index) => (
                        <Box key={index} width={"100%"}>
                          {typeof item.value === "boolean" ? (
                            <></>
                          ) : (
                            <FormInput
                              m="0em"
                              py="2"
                              borderBottom="1px solid rgba(0, 102, 255, 0.04)"
                              label={item.label}
                              type={
                                typeof item.value === "string"
                                  ? "text"
                                  : typeof item.value
                              }
                              for={item.label}
                              labelColor="#7489AA"
                              value={item.value}
                              editableText={true}
                              inputProps={{
                                placeholder: `Enter ${item.label}`,
                                value: item.value,
                                onChange: (e) => {
                                  setExtraAttributes((prev) => {
                                    let cp = prev.map((x) => {
                                      if (x.label === item.label) {
                                        return {
                                          label: item.label,
                                          value: e.target.value,
                                        };
                                      } else {
                                        return x;
                                      }
                                    });
                                    return cp;
                                  });
                                },
                              }}
                              onMutate={() => {
                                onSubmit({
                                  attr: "custom_attributes",
                                  newValue: {
                                    [item.label]: item.value,
                                  },
                                });
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </VStack>
                  ) : (
                    <Text> No extra attributes for this contact</Text>
                  )}

                  <Text
                    cursor={"pointer"}
                    fontSize={"12px"}
                    fontWeight={500}
                    color={"brand.main"}
                    pt={"8px"}
                    onClick={() => setViewmore(!viewmore)}
                  >
                    view less
                  </Text>
                </>
              )}
            </VStack>
          </Box>
        </DropIn>

        {/* <DropIn fullWidth delay={1}>
          <SimpleGrid
            bg="white"
            py="4"
            px="4"
            rowGap="1.5em"
            borderRadius={"4px"}
          >
            <Text
              fontSize="14px"
              fontWeight="700"
              // pb='4'
            >
              Channels
            </Text>
            <HStack spacing={"32px"}>
              <Flex alignItems="center" columnGap=".3em">
                <FacebookIcon width={"25px"} />{" "}
                <Text fontWeight={500} fontSize={"12px"}>
                  {data?.data_source === "facebook"
                    ? `${data.first_name} ${data.last_name}`
                    : "---"}
                </Text>
              </Flex>
              <Flex alignItems="center" columnGap=".3em">
                <TwitterIcon width={"25px"} />{" "}
                <Text fontWeight={500} fontSize={"12px"}>
                  {data?.twitter_username || "---"}
                </Text>
              </Flex>
            </HStack>

            <Flex alignItems="center" columnGap=".3em">
              <InstagramIcon width={"25px"} />{" "}
              <Text fontWeight={500} fontSize={"12px"}>
                {data?.instagram_username || "---"}
              </Text>
            </Flex>
          </SimpleGrid>
        </DropIn> */}
      </SimpleGrid>
    </>
  );
};

export default ContactInfo;
