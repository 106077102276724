import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputProps,
  FormControlProps,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Box,
  TextareaProps,
  Textarea,
  InputLeftElement,
  Flex,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { EditIcon } from "../../../assets/svgs/svg";

interface FormInputProps {
  label?: any;
  labelIcon?: any;
  for: string;
  br?: string;
  type: string;
  helperText?: string;
  inputProps?: InputProps;
  textareaProps?: TextareaProps;
  formControlProps?: FormControlProps;
  rightElement?: any;
  leftElement?: any;
  listLabel?: string;
  listData?: string[];
  ref?: any;
  disabled?: boolean;
  labelColor?: string;
  editableText?: boolean;
  value?: any;
  noEdit?: boolean;
  py?: string;
  m?: string;
  my?: string;
  w?: string;
  borderBottom?: string;
  autoFocus?: boolean;
  required?:boolean;
  onMutate?: () => any;
}

const FormInput: FC<FormInputProps> = (props) => {
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(true);
  const handleClick = () => setShow(!show);
  const toggleInput = () => setIsShown(false);

  return (
    <FormControl
      m={props.m}
      my={props.my}
      py={props.py}
      borderBottom={props.borderBottom}
      {...props.formControlProps}
      w={props.w}
    >
      {props.label && (
        <FormLabel
          fontWeight={"bold"}
          fontSize="xs"
          color={props?.labelColor || "grays.9"}
          htmlFor={props.for}
          mb="4px"
          display="flex"
        >
          <Text as="span">{props.label} {props.required ? (<Text color={'red'} display={"inline"}>*</Text>):(null)}</Text>

          {props.labelIcon && (
            <Box ml="auto" my="auto">
              {props.labelIcon}
            </Box>
          )}
        </FormLabel>
      )}

      <InputGroup size="md">
        {props.leftElement && (
          <InputLeftElement pointerEvents="none">
            {props.leftElement}
          </InputLeftElement>
        )}

        {isShown && props.editableText ? (
          <Flex w="full" justifyContent={"space-between"}>
            <Text fontSize="14px" fontWeight="500">
              {props?.value}
            </Text>
            {!props.noEdit ? (
              <Box cursor={"pointer"}>
                <EditIcon onClick={toggleInput} />
              </Box>
            ) : (
              ""
            )}
          </Flex>
        ) : props.type !== "textarea" ? (
          <Input
            autoFocus={props.autoFocus}
            onBlur={() => {
              setIsShown(true);
              if (props.onMutate) {
                props.onMutate();
              }
            }}
            value={props?.value}
            disabled={props.disabled}
            borderRadius={props?.br ? props?.br : "8px"}
            borderColor="borders.100"
            _hover={{
              borderColor: "borders.100",
            }}
            color="grays.9"
            _placeholder={{
              color: "grays.12",
            }}
            h="44px"
            bg="white"
            id={props.for}
            type={!show ? props.type : "text"}
            list={props.listLabel}
            {...props.inputProps}
            ref={props?.ref}
            fontSize="sm"
          />
        ) : (
          <Textarea
            pt={4}
            bg="white"
            borderRadius={"8px"}
            borderColor="borders.100"
            _hover={{
              borderColor: "borders.100",
            }}
            color="grays.12"
            h="96px"
            _placeholder={{
              color: "grays.12",
            }}
            {...props.textareaProps}
            ref={props?.ref}
            fontSize="sm"
          />
        )}

        <datalist id={props.listLabel}>
          {props.listData?.map((value) => (
            <option key={value} value={value} />
          ))}
        </datalist>

        {(props.rightElement || props.type === "password") && (
          <InputRightElement pointerEvents="none">
            {props.rightElement}

            {props.type === "password" && (
              <Button
                size="sm"
                mt={2}
                mr={2}
                p={0}
                fontSize="lg"
                variant={"ghost"}
                onClick={handleClick}
                color="gray.400"
              >
                {show ? <HiOutlineEyeOff /> : <HiOutlineEye />}
              </Button>
            )}
          </InputRightElement>
        )}
      </InputGroup>

      {props.helperText && (
        <FormHelperText fontSize={"xs"} fontWeight={700} color="grays.8">
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormInput;
