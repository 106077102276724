import {
  Box,
  Text,
  Button,
  HStack,
  VStack,
  Heading,
  chakra,
  Icon,
  StatGroup,
  Divider,
  Tooltip,
  IconProps,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PageAnimate } from "../components/animations/pageAnimate";
import CustomMenu from "../components/UI/CustomMenu";
import { HiChevronDown } from "react-icons/hi";
import Stat from "../components/UI/Cards/stat.component";
import { DASH_ROUTES } from "../utils/routes";
import { InfoCircleIcon } from "../icons/InfoCircle";
import { ApexLineChart } from "../components/UI/LineChart";
import { useQuery } from "@tanstack/react-query";
import { getDashboardCampaignData} from "../API/dashboard";
import CustomButton from "../components/Ziz_components/Button.component";
import ExportIcon from "../icons/ExportIcon";
import { useEffect, useState } from "react";
import ChevronDown from "../icons/ChevronDown";
import DatePicker from "react-datepicker";
import { getProfileData } from "../API/account/profile";
import { profileAtom } from "../atoms/user";
import { useSetRecoilState } from "recoil";
import { ApexOptions } from "apexcharts";
import { formatDistance, subDays } from "date-fns";


interface InfoTooltipProps {
  label: string;
  headerText?: string;
  color?: string;
}

const CircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

function InfoTooltip({ label, headerText, color }: InfoTooltipProps) {
  return (
    <Tooltip label={label}>
      <Box
        display="inline-flex"
        alignItems="center"
        gap="8px"
        cursor={"pointer"}
      >
        {color && <CircleIcon boxSize={4} color={color} />} {headerText}{" "}
        <InfoCircleIcon />
      </Box>
    </Tooltip>
  );
}
const emailOps: ApexOptions = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  colors:['#ffff00', '#0000ff', '#ffa500', "#008000"],

  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [1, 1, 1,1],
    curve: "smooth",
    dashArray: [0, 0, 0,0],
  },
  title: {
    text: "Page Statistics",
    align: "left",
  },
  legend: {
    tooltipHoverFormatter: function (val, opts) {
      return (
        val +
        " - <strong>" +
        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
        "</strong>"
      );
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    categories: [
      "01 Jan",
      "02 Jan",
      "03 Jan",
      "04 Jan",
      "05 Jan",
      "06 Jan",
      "07 Jan",
      "08 Jan",
      "09 Jan",
      "10 Jan",
      "11 Jan",
      "12 Jan",
    ],
  },
  tooltip: {
    y: [
      {
        title: {
          formatter: function (val) {
            return val + " (mins)";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val + " per session";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val;
          },
        },
      },
    ],
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  fill:{
    colors: ['#F44336', '#E91E63', '#9C27B0', "#fffff"]
  }
};
const smsOps: ApexOptions = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  colors:['#ff0000', '#0000ff', '#800080', "#008000"],

  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [1, 1, 1,1],
    curve: "smooth",
    dashArray: [0, 0, 0,0],
  },
  title: {
    text: "Page Statistics",
    align: "left",
  },
  legend: {
    tooltipHoverFormatter: function (val, opts) {
      return (
        val +
        " - <strong>" +
        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
        "</strong>"
      );
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    categories: [
      "01 Jan",
      "02 Jan",
      "03 Jan",
      "04 Jan",
      "05 Jan",
      "06 Jan",
      "07 Jan",
      "08 Jan",
      "09 Jan",
      "10 Jan",
      "11 Jan",
      "12 Jan",
    ],
  },
  tooltip: {
    y: [
      {
        title: {
          formatter: function (val) {
            return val + " (mins)";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val + " per session";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val;
          },
        },
      },
    ],
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  fill:{
    colors: ['#F44336', '#E91E63', '#9C27B0', "#fffff"]
  }
};
const emailSeries = [
  {
    name: "Open Rate",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],
    // type: "line",
  },
  {
    name: "Click Rate",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],

  },
  {
    name: "CTOR",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],

  },
  {
    name: "Delivery Rate",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],

  },

];
const smsSeries = [
  {
    name: "Bounced",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],
    // type: "line",
  },
  {
    name: "Click Rate",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],
  },
  {
    name: "CTR",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],
  },
  {
    name: "Delivery Rate",
    data: [0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0],
  },

];

const DashboardOverview = () => {
  const setProfileData = useSetRecoilState(profileAtom);
  const [filterByDuration, setFilterByDuration] = useState<any>("Today");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // fetch profile info
  const { data } = useQuery(["profileData"], getProfileData, {
    onSuccess(data) {
      console.log(data)
      setProfileData(data);
    },
  });

  const { isLoading, data: dashboardStats } = useQuery(
    ["dashboardEmailData",
      startDate,
      endDate,
    ],
    () => {
      return getDashboardCampaignData({
        startDate,
        endDate,
      });
    }
  );

  useEffect(() => {
    if (filterByDuration === "today") {
      setStartDate(new Date());
      setEndDate(new Date());
    }else if( filterByDuration === "last_week"){
      setStartDate(subDays(new Date(), 7))
      setEndDate(new Date());
    }else if( filterByDuration === "last_month"){
      setStartDate(subDays(new Date(), 30))
      setEndDate(new Date());
    }
    else if( filterByDuration === "last_quarter"){
      setStartDate(subDays(new Date(), 120))
      setEndDate(new Date());
    }
    else if( filterByDuration === "last_year"){
      setStartDate(subDays(new Date(), 365))
      setEndDate(new Date());
    }
  }, [filterByDuration]);



  return (
    <Box
      minH={"100vh"}
      // overflowX={"hidden"}
      // overflowY="scroll"
      bg="white"
    >
      <PageAnimate>
        <VStack w="full" mb={"42px"} spacing={0}>
          <HStack
            borderBottom="1px solid"
            borderColor="#E7E9EF"
            w="full"
            justifyContent="space-between"
            px={10}
            pt={8}
            pb={5}
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Heading textAlign="start" fontSize={"26px"}>
                Dashboard
              </Heading>
              <chakra.p fontSize="16px" color="grays.15">
                Hi {data?.name || `--`} 👋🏽, here’s what has been happening
              </chakra.p>
            </VStack>

            <Box>
              <a href="/campaigns/new">
                <Button
                  fontSize={"14px"}
                  colorScheme="primary"
                  w="full"
                  maxW="240px"
                >
                  Create New Campaign
                </Button>
              </a>
            </Box>
          </HStack>

          <VStack
            borderX="1px solid"
            borderTop="1px solid"
            borderColor="grays.18"
            w="full"
            border={"none"}
            spacing={0}
            pb={12}
          >
            <HStack
              w="full"
              bg="#F7FBFF"
              px={10}
              py={"1rem"}
              h="full"
              justifyContent="space-between"
              // borderTop="1px solid"
              // borderColor="grays.18"
              border={"none"}
            >
              <Heading as="h4" fontSize="16px">
                Overview
              </Heading>

              {/* <CustomMenu
                buttonTitle={
                  <HStack spacing={2} px="12px">
                    <Text
                      color="grays.9"
                      fontSize={"xs"}
                      py="8px"
                      borderRadius={"4px"}
                      fontWeight="500"
                    >
                      This Week
                    </Text>

                    <Icon as={HiChevronDown} />
                  </HStack>
                }
                buttonProps={{
                  _hover: {
                    backgroundColor: "rgba(0, 102, 255, 0.04)",
                    borderRadius: "4px",
                  },
                }}
                itemsData={[
                  {
                    title: "Last 30 Days",
                    itemProps: {
                      fontSize: "xs",
                    },
                    isLink: true,
                    // href: "/contacts",
                  },
                  {
                    title: "Last 6 Months",
                    itemProps: {
                      fontSize: "xs",
                    },
                    isLink: true,
                    // href: "/account/contactdata/lists",
                  },
                  // {
                  //   title: "Import History",
                  //   itemProps: {
                  //     fontSize: "xs",
                  //   },
                  //   isLink: true,
                  //   href: "account/contactdata/importhistory",
                  // },
                ]}
              /> */}
               <Box mt={{ base: 5, md: 0 }} ml={{ base: 0, md: "64px" }}>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "16px", md: "12px" }}
                align="center"
              >
                <Text fontSize={"xs"} w="full" color="rgba(23, 29, 38, 0.56)">
                  Date by:{" "}
                </Text>

                <CustomMenu
                  buttonTitle={
                    <HStack textAlign={"left"} spacing={"14px"}>
                      <Text flex={1}>
                        {filterByDuration.charAt(0).toUpperCase() +
                          filterByDuration?.slice(1).replace("_", " ")}
                      </Text>
                      <ChevronDown />
                    </HStack>
                  }
                  buttonProps={{
                    fontSize: "xs",
                    color: "grays.9",
                    type: "button",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "#D2D2D4",
                    borderRadius: "4px",
                    py: "10px",
                    px: "12px",
                    minW: "130px",
                    maxW: "130px",
                  }}
                  isSelect
                  itemsSelectData={[
                    {
                      title: "Today",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "today",
                      },
                    },
                    {
                      title: "Last week",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_week",
                      },
                    },
                    {
                      title: "Last month",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_month",
                      },
                    },
                    {
                      title: "Last Quarter",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_quarter",
                      },
                    },
                    {
                      title: "Last Year",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_year",
                      },
                    },
                  ]}
                  menuSelectProps={{
                    defaultValue: "today",
                    onChange: (value) => {
                      setFilterByDuration(value);
                    },
                  }}
                />

                <Text fontSize={"xs"} color="grays.8">
                  OR
                </Text>
                <DatePicker
                  selectsRange
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChange}
                  dateFormat="yyyy-MM-dd"
                />
              </Stack>
            </Box>
            </HStack>

            <StatGroup
              w="full"
              height="130px"
              maxH="max-content"
              bg="white"
              px={6}
              borderY="1px solid"
              borderColor="#E7E9EF"
              marginTop="0px !important"
            >
              <Stat
                label="Total Contacts"
                amount={dashboardStats?.contacts}
                subText={
                  dashboardStats?.contacts > 0 ? (""):(
                    <HStack>
                    <Text>No contact yet? </Text>{" "}
                    <Link to={DASH_ROUTES.CONTACTS} color="blue"> Add contacts now</Link>
                  </HStack>
                  )
                  
                }
                mainStat
              />
              <Divider orientation="vertical" />
              <Stat label="Total Campaign" amount={dashboardStats?.totalCampaigns} />
              <Divider orientation="vertical" />
              <Stat label="Total Delivered" amount={dashboardStats?.totalDelivered} />
              <Divider orientation="vertical" />
              <Stat label="Total Open Messages" amount={dashboardStats?.totalOpenMessages} />
            </StatGroup>

            {/* <HStack
              w="full"
              bg="#F7FBFF"
              py="1.5rem"
              px={10}
              marginTop="0px !important"
              h="full"
              // borderColor="grays.18"
              justifyContent="space-between"
            >
              <Heading as="h4" fontSize="16px">
                Campaigns
              </Heading>
            </HStack>

            <StatGroup
              w="full"
              marginTop="0px !important"
              maxH="max-content"
              height="140px"
              bg="white"
              px={6}
              borderY="1px solid"
              borderColor="#E7E9EF"
            >
              <Stat
                label="Email Sent"
                amount={dashboardStats?.deliveredEmails}
                subText=""
                mainStat
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="yellow"
                    label="The percentage of all delivered emails that are opened"
                    headerText="Open Rate"
                  />
                }
                label=""
                amount={dashboardStats?.openEmails}
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="blue"
                    label="The total number of times any link in your email was clicked"
                    headerText="Click Rate"
                  />
                }
                label=""
                amount={0}
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="orange"
                    label="The percentage of unique clicks and unique opens for your email campaign"
                    headerText="CTOR"
                  />
                }
                label=""
                amount={0}
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="green"
                    label="The number of contacts who successfully received an email to their inbox"
                    headerText="Delivery Rate"
                  />
                }
                label=""
                amount={0}
              />
            </StatGroup>

            <ApexLineChart series={emailSeries} ops={emailOps}/>

            <StatGroup
              w="full"
              marginTop="0px !important"
              maxH="max-content"
              height="140px"
              bg="white"
              px={6}
              borderY="1px solid"
              borderColor="#E7E9EF"
            >
              <Stat
                label="SMS Sent"
                amount={dashboardStats?.deliveredSms}
                subText=""
                mainStat
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="red"
                    label="The number of sms that were not successfully delivered"
                    headerText="Bounced"
                  />
                }
                label=""
                amount={0}
              />
              
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="blue"
                    label="The total number of times any link in your sms was clicked"
                    headerText="Click Rate"
                  />
                }
                label=""
                amount={0}
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="purple"
                    label="The percentage of recipients who engaged with SMS  through a clickable link."
                    headerText="CTR"
                  />
                }
                label=""
                amount={0}
              />
              <Divider orientation="vertical" />
              <Stat
                preText={
                  <InfoTooltip
                    color="green"
                    label="The number of contacts who successfully received an sms to their inbox"
                    headerText="Delivery Rate"
                  />
                }
                label=""
                amount={0}
              />
            </StatGroup>
            <ApexLineChart series={smsSeries} ops={smsOps} /> */}
          </VStack>
        </VStack>
      </PageAnimate>
    </Box>
  );
};

export default DashboardOverview;
