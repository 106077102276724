import {
  Flex,
  Text,
  Box,
  SimpleGrid,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Input,
  Select,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCampaignReportAnalytics,
  getCampaignReportContactsData,
} from "../../API/campaign";
import CampaignReportList from "../../components/campaigns/CampaignReportsList";
import Back from "../../components/UI/Back";
import Empty from "../../components/UI/Empty";
import Loader from "../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import CustomPaginaton from "../../components/UI/CustomPaginaton";
// import PieChart from "../../components/UI/PieChart";
// import DataTable from "../../components/UI/Table/DataTable";
// import { ScrollBox } from "../../components/Ziz_components/drop-down/dropDown";
import { PageAnimate } from "../../components/animations/pageAnimate";
import { ApexPieChart } from "../../components/UI/ApexPieChart";
import ProgressLoader from "../../components/UI/ProgressLoader";
import { link } from "fs";

const stat = {
  delivered: 2,
  open: 2,
  ctor: 2,
  clicks: 2,
  unsubscribed: 2,
  spam: 2,
  ctr: 2,
  bounced: 2,

};
const linkClicks = {
  clicks: 5,
  link: "https:www.ifeoluwa.com",
};

const CampaignReport = () => {
  const { channel, id } = useParams();

  const [dataToShow, setDataToShow] = useState(15);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [linkReport, setLinkReport] = useState<{
    clicks: number;
    link: string;
  }>({ clicks: 0, link: "---" });

  const { isLoading, data: statistics } = useQuery(
    ["campaignReportAnalytics", dataToShow, id],
    () => {
      return getCampaignReportAnalytics(id, channel);
    }
  );
  console.log("statistics",statistics)
  console.log("channel",channel)


  const {
    isLoading: campaignReportContactsDataLoading,
    data: campaignReportContactsData,
  } = useQuery(
    ["campaignReportContactsData", myCurrentPage, dataToShow],
    () => {
      return getCampaignReportContactsData({
        uid: id,
        page: myCurrentPage,
        limit: dataToShow,
        channel: channel,
      });
    }
  );
  console.log("contactdata",campaignReportContactsData)

  const linkActivityQuery = useQuery(
    ["get_link_activity"],
    () => {
      return getCampaignReportContactsData({
        uid: id,
        channel: channel,
      });
    },
    {
      onSuccess: (data) => {
        const { link_analysis } = data;
        setLinkReport({
          clicks: link_analysis?.total_clicks || 0,
          link: link_analysis?.link || "www.example.com",
        });
      },
    }
  );

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: campaignReportContactsData?.sms?.totalPages,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: { currentPage: 1 },
  });
  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  // const statData = useMemo(() => {
  //   return [
  //     {
  //       name: "Pending",
  //       label:
  //         "Bodsquare is in the process of sending the message. This status is only present for a short period of time",
  //       statistics: statistics?.pending || 0,
  //       color: "#FBA705",
  //     },
  //     {
  //       name: "Sent",
  //       label:
  //         "Message has been processed but no delivery report yet. Note that a sent status can be replaced by a delivered or an undelivered status within seconds or minutes.",
  //       statistics: statistics?.sent || 0,
  //       color: "#00B9D2",
  //     },
  //     {
  //       name: "Delivered",
  //       label:
  //         "Bodsquare received delivery report that message got to destination device.",
  //       statistics: statistics?.delivered || 0,
  //       color: "#159D6F",
  //     },
  //     {
  //       name: "Failed",
  //       label:
  //         "Bodsquare received a delivery report indicating that the message was not delivered. It can be due to a number of reasons including carrier content filtering, availability of the destination handset, etc. Error code and message should provide more information on the reason.",
  //       statistics: statistics?.failed || 0,
  //       color: "#e41010",
  //     },
  //     {
  //       name: "Rejected",
  //       label: "Message was sent but got rejected by telco.",
  //       statistics: statistics?.rejected || 0,
  //       color: "#E16B61",
  //     },
  //     {
  //       name: "Expired",
  //       label:
  //         "Message went through the retry scheme and could not be delivered within the SMS life-span.",
  //       statistics: statistics?.expired || 0,
  //       color: "#e9cbcbd8",
  //     },
  //     statistics?.success
  //       ? {
  //           name: "Success",
  //           label: "Message went through successfully",
  //           statistics: statistics?.success || 0,
  //           color: "#193F64",
  //         }
  //       : {},
  //   ];
  // }, [statistics]);
  const dataEmail = [
    {
      id: 1,
      uid: "01",
      company_uid: "company",
      provider_reference: "provider reference",
      sms_campaign_uid: "sms campaign",
      contact_uid: "contact uid",
      message_body: "message_body",
      message_body_count: 5,
      message_from: "message from",
      message: "message",
      status: "delivered",
      failed_reason: "server error",
      created_at: "27/02/2024",
      updated_at: "27/02/2024",
      contact: {
        id: 1,
        uid: "01",
        company_uid: "company",
        profile_picture: "pic",
        first_name: "Ifeoluwa",
        last_name: "Oke",
        email: "ifeoluwa@go54.com",
        phone_number: "081000000000",
        instagram_username: "ifee",
        instagram_id: "ifee",
        facebook_username: "ifee",
        facebook_id: "ifee",
        twitter_username: "ifee",
        twitter_id: "ifee",
        is_blocked: 1,
        deleted_at: "27/02/2024",
        data_source: "Bodsquare",
        metadata: "yes",
        created_at: "27/02/2024",
        updated_at: "27/02/2024",
      },
    },
    {
      id: 1,
      uid: "01",
      company_uid: "company",
      provider_reference: "provider reference",
      sms_campaign_uid: "sms campaign",
      contact_uid: "contact uid",
      message_body: "message_body",
      message_body_count: 5,
      message_from: "message from",
      message: "message",
      status: "rejected",
      failed_reason: "server error",
      created_at: "27/02/2024",
      updated_at: "27/02/2024",
      contact: {
        id: 1,
        uid: "01",
        company_uid: "company",
        profile_picture: "pic",
        first_name: "Ifeoluwa",
        last_name: "Oke",
        email: "ifeoluwa@go54.com",
        phone_number: "081000000000",
        instagram_username: "ifee",
        instagram_id: "ifee",
        facebook_username: "ifee",
        facebook_id: "ifee",
        twitter_username: "ifee",
        twitter_id: "ifee",
        is_blocked: 1,
        deleted_at: "27/02/2024",
        data_source: "Bodsquare",
        metadata: "yes",
        created_at: "27/02/2024",
        updated_at: "27/02/2024",
      },
    },
    {
      id: 1,
      uid: "01",
      company_uid: "company",
      provider_reference: "provider reference",
      sms_campaign_uid: "sms campaign",
      contact_uid: "contact uid",
      message_body: "message_body",
      message_body_count: 5,
      message_from: "message from",
      message: "message",
      status: "expired",
      failed_reason: "server error",
      created_at: "27/02/2024",
      updated_at: "27/02/2024",
      contact: {
        id: 1,
        uid: "01",
        company_uid: "company",
        profile_picture: "pic",
        first_name: "Ifeoluwa",
        last_name: "Oke",
        email: "ifeoluwa@go54.com",
        phone_number: "081000000000",
        instagram_username: "ifee",
        instagram_id: "ifee",
        facebook_username: "ifee",
        facebook_id: "ifee",
        twitter_username: "ifee",
        twitter_id: "ifee",
        is_blocked: 1,
        deleted_at: "27/02/2024",
        data_source: "Bodsquare",
        metadata: "yes",
        created_at: "27/02/2024",
        updated_at: "27/02/2024",
      },
    },
    {
      id: 1,
      uid: "01",
      company_uid: "company",
      provider_reference: "provider reference",
      sms_campaign_uid: "sms campaign",
      contact_uid: "contact uid",
      message_body: "message_body",
      message_body_count: 5,
      message_from: "message from",
      message: "message",
      status: "pending",
      failed_reason: "server error",
      created_at: "27/02/2024",
      updated_at: "27/02/2024",
      contact: {
        id: 1,
        uid: "01",
        company_uid: "company",
        profile_picture: "pic",
        first_name: "Ifeoluwa",
        last_name: "Oke",
        email: "ifeoluwa@go54.com",
        phone_number: "081000000000",
        instagram_username: "ifee",
        instagram_id: "ifee",
        facebook_username: "ifee",
        facebook_id: "ifee",
        twitter_username: "ifee",
        twitter_id: "ifee",
        is_blocked: 1,
        deleted_at: "27/02/2024",
        data_source: "Bodsquare",
        metadata: "yes",
        created_at: "27/02/2024",
        updated_at: "27/02/2024",
      },
    },
    {
      id: 1,
      uid: "01",
      company_uid: "company",
      provider_reference: "provider reference",
      sms_campaign_uid: "sms campaign",
      contact_uid: "contact uid",
      message_body: "message_body",
      message_body_count: 5,
      message_from: "message from",
      message: "message",
      status: "sent",
      failed_reason: "server error",
      created_at: "27/02/2024",
      updated_at: "27/02/2024",
      contact: {
        id: 1,
        uid: "01",
        company_uid: "company",
        profile_picture: "pic",
        first_name: "Ifeoluwa",
        last_name: "Oke",
        email: "ifeoluwa@go54.com",
        phone_number: "081000000000",
        instagram_username: "ifee",
        instagram_id: "ifee",
        facebook_username: "ifee",
        facebook_id: "ifee",
        twitter_username: "ifee",
        twitter_id: "ifee",
        is_blocked: 1,
        deleted_at: "27/02/2024",
        data_source: "Bodsquare",
        metadata: "yes",
        created_at: "27/02/2024",
        updated_at: "27/02/2024",
      },
    },
  ];
  const senderEmail = {
    id: 1,
    uid: "001",
    company_uid: "company",
    name: "ife",
    display_name: "Ifeoluwa Oke",
    status: "Scheduled",
    deleted_at: "27/02/2014",
    created_at: "27/02/2014",
    updated_at: "27/02/2014",
  };
  const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <PageAnimate>
      {linkActivityQuery.isLoading && <ProgressLoader />}
     
      <Flex
        flexDir={"column"}
        // maxW="1440px"
        // mx="auto"
        w="full"
        // px={{ base: "10px", md: "40px" }}
        py="31px"
      >
        <Back path="/campaigns" title="Back to manage campaigns" />
        <Box display={'flex'} justifyContent={'space-between'} mt={2}>
          <Box>
          
            <Text fontSize={20} fontWeight={700} color={"#171D26"}>
            {channel?.charAt(0).toUpperCase() === "E" ? "Email": "SMS"} Campaign Report
            </Text>
          </Box>
          <Box><Button colorScheme="primary" onClick={()=>{onOpen()}}>Export</Button></Box>

          <Modal isOpen={isOpen} onClose={onClose} size="sm">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                <Box display={'flex'} justifyContent={'space-between'} borderBottom={'1px solid #0066FF29'}>
                      <Text color={'#171D26'} fontSize={20} fontWeight={700}>Export Report</Text>
                      <Text decoration={'underline'} color={'#0066FF'} role="button" onClick={()=>{onClose()}}>Cancel</Text>
                    </Box>
                </ModalHeader>
                <ModalBody>
                  <Box h={'287px'} w={'full'} >
                    <Box  margin={'auto'}  w={'full'}>
                    <Box mt={3}>
                      <Text fontWeight={700} fontSize={12}>Teamate Mail</Text>
                      <Select mb={10}>
                        <option value="teammate@workmail.com">teammate@workmail.com</option>
                      </Select>
                    </Box>
                    <Button colorScheme="primary" w={'full'}>Export</Button>
                    </Box>

                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
        </Box>

        {isLoading && !statistics && (
          <Box
            bg="white"
            w="full"
            borderWidth={"1px"}
            borderColor="borders.300"
            px="20px"
            rounded="4px"
            mt="44px"
          >
            <Loader height="25vh" />
          </Box>
        )}

        {statistics && !isLoading && (
          <SimpleGrid
            columnGap="16px"
            gridTemplateColumns={{ base: "1fr", xl: "67% 32%" }}
            bg="#f2f4f7"
            w="full"
            rounded="4px"
            mt="24px"
          >
            <HStack mb="5" bg="white" borderRadius="4px" minHeight={"376px"}>
              {/* <PieChart
                series={statData.map((s) => {
                  if (typeof s.statistics !== "undefined") {
                    return Number.parseInt(s.statistics + "");
                  } else {
                    return 0;
                  }
                })}
                stat={statData}
                filteredData={[]}
                show={!isLoading}
              /> */}
              <ApexPieChart stats={statistics.reportSummary} />
            </HStack>

            <Box p="20px" mb="5" bg="white" borderRadius="4px">
              <Text
                fontSize="20px"
                fontWeight="700"
                pb={"12px"}
                borderBottom="1px solid #0066FF14"
              >
                Link activity
              </Text>
              {/* <ScrollBox
                table={true}
                scrollMaxH="17em"
                _bgHover="#657ea5"
                scrollHue="#b7c1d2"
                color="#7489AA"
              >
                {/* @ts-ignore
                <DataTable
                  columns={tableColumns}
                  column2={false}
                  data={tableData}
                  isLoading={isLoading}
                  // paginationDetails={reports2}
                />
              </ScrollBox> */}

              <TableContainer>
                <Table variant="simple" color={"grays.12"}>
                  <TableCaption
                    fontSize={"12px"}
                    color={"grays.12"}
                    mt={"160px"}
                  >
                    Number of times your link was clicked
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th
                        fontWeight={500}
                        fontSize={"xs"}
                        color={"grays.12"}
                        px={"12px"}
                      >{`Link(URL)`}</Th>
                      <Th
                        fontWeight={500}
                        fontSize={"xs"}
                        color={"grays.12"}
                        px={"12px"}
                        isNumeric
                      >
                        Total
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td
                        fontWeight={500}
                        fontSize={"xs"}
                        color={"grays.12"}
                        px={"12px"}
                      >
                        {linkReport?.link}
                      </Td>
                      <Td
                        fontWeight={500}
                        fontSize={"xs"}
                        color={"grays.12"}
                        px={"12px"}
                        isNumeric
                      >
                        {linkReport?.clicks}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            {/*  */}
          </SimpleGrid>
        )}

        <Flex
          mt="10px"
          bg="white"
          w="full"
          borderWidth={"1px"}
          borderColor="borders.300"
          // px='20px'
          rounded="4px"
          flexDir={"column"}
        >
         {channel?.charAt(0).toUpperCase() === "E"  && campaignReportContactsData?.emails?.docs?.length > 0 ? (
          <CampaignReportList
          data={campaignReportContactsData?.emails?.docs}
          sender={campaignReportContactsData?.email_sender}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          listDataToShow={dataToShow}
          setListDataToShow={setDataToShow}
          channel={channel}
        />
         ):(null)} 
          
          {!campaignReportContactsData &&
            (campaignReportContactsData?.sms?.docs?.length ||
              campaignReportContactsData?.whatsapp?.docs.length) === 0 && (
              <Empty
                title="No data available"
                icon={
                  <svg
                    width="96"
                    height="96"
                    viewBox="0 0 96 96"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M77 40.0078C77 50.9234 71.4 61.0078 66 61.0078C60.6 61.0078 55 50.9234 55 40.0078C55 29.0921 60.6 19.0078 66 19.0078C71.4 19.0078 77 29.0921 77 40.0078Z"
                      stroke="#7489AA"
                      strokeWidth="5"
                    />
                    <path
                      d="M66 61.0078C66 61.0078 32 54.0078 28 53.0078C24 52.0078 19 46.765 19 40.0078C19 33.2505 24 28.0078 28 27.0078C32 26.0078 66 19.0078 66 19.0078"
                      stroke="#7489AA"
                      strokeWidth="5"
                    />
                    <path
                      d="M27 54V69C27 73.4184 30.5817 77 35 77H37C41.4184 77 45 73.4184 45 69V58"
                      stroke="#7489AA"
                      strokeWidth="5"
                    />
                  </svg>
                }
              />
            )}

          {campaignReportContactsDataLoading && <Loader />}

          {!isLoading &&
            (campaignReportContactsData?.sms?.docs ||
              campaignReportContactsData?.whatsapp?.docs) && (
              <>
                {(campaignReportContactsData?.sms?.docs.length ||
                  campaignReportContactsData?.whatsapp?.docs.length) > 0 ? (
                  <>
                    <Box h="47vh" overflowY={"auto"}>
                      <CampaignReportList
                        data={
                          campaignReportContactsData?.sms?.docs ||
                          campaignReportContactsData?.whatsapp?.docs
                        }
                        sender={
                          campaignReportContactsData?.sms_sender ||
                          campaignReportContactsData?.whatsapp_sender
                        }
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        listDataToShow={dataToShow}
                        setListDataToShow={setDataToShow}
                      />
                    </Box>

                    <Box py={3} px={3}>
                      <CustomPaginaton
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        pages={pages}
                        numberOfdataToShow={dataToShow}
                        setDataToShow={setDataToShow}
                      />
                    </Box>
                  </>
                ) : (
                  <Box
                    pt={"63px"}
                    bg="white"
                    w="full"
                    borderWidth={"1px"}
                    borderColor="borders.300"
                    px="20px"
                    rounded="4px"
                  >
                    <Empty
                      // title='You currently don’t have any sender ID added'
                      description={<Text as="span">No reports available</Text>}
                      icon={
                        <svg
                          width="96"
                          height="96"
                          viewBox="0 0 96 96"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M77 40.0078C77 50.9234 71.4 61.0078 66 61.0078C60.6 61.0078 55 50.9234 55 40.0078C55 29.0921 60.6 19.0078 66 19.0078C71.4 19.0078 77 29.0921 77 40.0078Z"
                            stroke="#7489AA"
                            strokeWidth="5"
                          />
                          <path
                            d="M66 61.0078C66 61.0078 32 54.0078 28 53.0078C24 52.0078 19 46.765 19 40.0078C19 33.2505 24 28.0078 28 27.0078C32 26.0078 66 19.0078 66 19.0078"
                            stroke="#7489AA"
                            strokeWidth="5"
                          />
                          <path
                            d="M27 54V69C27 73.4184 30.5817 77 35 77H37C41.4184 77 45 73.4184 45 69V58"
                            stroke="#7489AA"
                            strokeWidth="5"
                          />
                        </svg>
                      }
                    />
                  </Box>
                )}
              </>
            )}
        </Flex>
      </Flex>
    </PageAnimate>
  );
};

export default CampaignReport;
