import { Button, chakra, useToast, VStack } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveAdditionalData } from "../API/auth";
import { getData } from "../API/data";
import AuthLayout from "../components/general/AuthLayout";
import CustomPhoneInput from "../components/UI/form/CustomPhoneInput";
import FormInput from "../components/UI/form/FormInput";
import SearchableSelect from "../components/UI/form/SearchableSelect";
import { DASH_ROUTES } from "../utils/routes";
import { toaster } from "../utils/toast";

const GetStarted = () => {
  const [businessName, setBusinessName] = useState("");
  const [selectedindustry, setSelectedIndustry] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const { data } = useQuery(["bodData"], getData);

  const queryClient = useQueryClient();

  const industries = useMemo(() => {
    return data?.industries?.map((industry) => {
      return {
        label: industry,
        value: industry,
      };
    });
  }, [data?.industries]);

  const referralOptions = useMemo(() => {
    return data?.referral_platform?.map((platform) => {
      return {
        label: platform,
        value: platform,
      };
    });
  }, [data?.referral_platform]);

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(saveAdditionalData, {
    onSuccess(data) {
      const success = { ...data };
      navigate(DASH_ROUTES.DASHBOARD);
      queryClient.invalidateQueries(["profileData"]);
      toaster(toast, success);
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <AuthLayout title="Let’s get started" description="Business Information">
      <chakra.form
        mt="24px"
        onSubmit={(e) => {
          e.preventDefault();

          mutate({
            industry: selectedindustry,
            referral_channel: selectedReferralType,
            email: localStorage.getItem("confirmedEmail")!,
            name: businessName,
            phone_number: businessPhoneNumber,
            description: businessDescription,
            referral: referralCode,
          });
        }}
      >
        <VStack align={"flex-start"} spacing="20px">
          <FormInput
            label="Business name"
            type="text"
            for="Business name"
            formControlProps={{
              isRequired: true,
            }}
            inputProps={{
              placeholder: "Enter your business name",
              onChange: (e) => {
                setBusinessName(e.target.value);
              },
              value: businessName,
            }}
          />

          <SearchableSelect
            label="What indsutry is your business in?"
            options={industries}
            onChange={(selected: { label: string; value: string }) => {
              setSelectedIndustry(selected?.value);
            }}
            formControlProps={{
              isRequired: true,
            }}
          />

          <FormInput
            label="Business email"
            type="email"
            for="Business email"
            formControlProps={{
              isRequired: true,
            }}
            inputProps={{
              placeholder: "Enter your business email",
              defaultValue: localStorage.getItem("confirmedEmail")!,
              // isReadOnly: true,
            }}
          />

          <CustomPhoneInput
            label="Business phone number"
            value={businessPhoneNumber}
            onChange={(phone) => {
              setBusinessPhoneNumber(phone);
            }}
          />

          <FormInput
            label="Business description"
            type="textarea"
            for="Business description"
            textareaProps={{
              placeholder: "Describe what your business does",
              onChange: (e) => {
                setBusinessDescription(e.target.value);
              },
              value: businessDescription,
            }}
          />

          <SearchableSelect
            label="How did you hear about us?"
            options={referralOptions}
            onChange={(selected: { label: string; value: string }) => {
              setSelectedReferralType(selected?.value);
            }}
            formControlProps={{
              isRequired: true,
            }}
          />

          <FormInput
            label="Referral code"
            type="text"
            for="Referral code"
            inputProps={{
              placeholder: "12AB34",
              onChange: (e) => {
                setReferralCode(e.target.value);
              },
              value: referralCode,
            }}
          />
        </VStack>

        <Button
          type="submit"
          isLoading={isLoading}
          isDisabled={
            !businessName ||
            !selectedindustry ||
            !businessPhoneNumber ||
            !selectedReferralType
          }
          w="full"
          colorScheme={"primary"}
          mt="40px"
        >
          Open Account
        </Button>
      </chakra.form>
    </AuthLayout>
  );
};

export default GetStarted;
