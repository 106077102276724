import React from "react";
import { Box, HStack, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { InfoCircleIcon } from "../../icons/InfoCircle";
import { removeUnderscores } from "../../functions";

const ops = {
  chart: {
    type: "donut",
  },
  //   pending, sent, delivered, read, bounced, undelivered
  colors: ["#008000", "#ffff00", "#FFA500", "#0000FF", "#E16B61", "#193F64", "#800080","#ff0000"],
  labels: ["Delivered", "Open", "CTOR", "Clicks", "Unsubscribed", "Spam", "CTR", "Bounced"],
  noData: {
    text: "No data",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: -18,
    style: {
      color: "#13161D",
      fontSize: "20px",
      fontWeight: "500",
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      //   customScale: 0.6,
      donut: {
        size: "65%",
        labels: {
          show: true,
          name: {
            show: false,
            offsetY: 20,
            textAlign: "center",
            style: {
              fontSize: "20px",
              fontFamily: "Inter",
              fontWeight: 500,
            },
          },
          value: {
            show: true,
            fontSize: "24px",
            fontWeight: 700,
            color: "#171D26",
            // color: "#6682A2",
            // offsetY: -20,
          },
          total: {
            show: true,
            showAlways: true,
            color: "#13161D",
            fontSize: "20px",
            fontWeight: "700",
            offsetX: 0,
            offsetY: -28,
            label: " ",
          },
        },
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 100,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const pieColors = [
  {
    color: "#008000",
    name: "delivered",
    label:
      "The number of contacts who successfully received an email/sms to their inbox",
  },
  {
    color: "#ffff00",
    name: "open",
    label:
      "The percentage of all delivered emails that are opened",
  },
  {
    color: "#ffa500",
    name: "ctor",
    label:
      "The percentage of unique clicks and unique opens for your email campaign",
  },
  {
    color: "#0000ff",
    name: "clicks",
    label:
      "The total number of times any link in your email/sms was clicked",
  },
  {
    color: "#E16B61",
    name: "unsubscribed",
    label:
      "Bodsquare received a delivery report indicating that the message was not delivered. It can be due to a number of reasons including carrier content filtering, availability of the destination handset, etc. Error code and message should provide more information on the reason.",
  },
  {
    color: "#193F64",
    name: "spam",
    label: "Message was sent but got rejected by telco.",
  },
  {
    color: "#800080",
    name: "ctr",
    label: "The percentage of recipients who engaged with SMS  through a clickable link.",
  },
  {
    color: "#ff0000",
    name: "bounced",
    label: "The number of sms that were not successfully delivered.",
  },
];
export function ApexPieChart({
  stats,
}: {
  stats: {
    delivered: number | string;
    open: number | string;
    ctor: number | string;
    clicks: number | string;
    unsubscribed: number | string;
    spam: number | string;
    ctr: number | string;
    bounced: number | string;
  };
  
}) {
  const [pieSeries, setPieSeries] = React.useState([44, 5, 41, 17, 15, 100]);
  const [pieOptions] = React.useState(ops);
  const [isEmpty, setIsEmpty] = React.useState(false);

  // console.log("stataaaaaaa", stats);

  React.useEffect(() => {
    let newArr = pieColors.map((obj) => {
      // @ts-ignore
      let nu = Number.parseInt(stats[`${obj.name}`] + "");
      return nu;
    });

    let check = newArr.filter((item) => item !== 0);
    if (check.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    setPieSeries(newArr);
  }, [stats]);

  return (
    <Box w={"100%"}>
      <HStack justifyContent={"center"} alignItems={"center"} spacing={"95px"}>
        <ReactApexChart
          width={280}
          height={280}
          // @ts-ignore
          options={pieOptions}
          series={isEmpty ? [1, 0, 0, 0, 0, 0] : pieSeries}
          type="donut"
        />

        <SimpleGrid
          gridTemplateColumns={"repeat(2, 1fr)"}
          spacingY={"40px"}
          spacingX={"90px"}
        >
          {pieColors.map((item, index) => (
            <HStack key={index}>
              <Box
                bg={item.color}
                w={"16px"}
                h={"16px"}
                borderRadius={"100%"}
              />
              <Text color={"grays.12"} fontWeight={500} fontSize={"14px"}>
                {removeUnderscores(item.name)}
              </Text>
              <Tooltip label={item.label}>
                <Box cursor={"pointer"}>
                  <InfoCircleIcon />
                </Box>
              </Tooltip>
            </HStack>
          ))}
        </SimpleGrid>
      </HStack>
    </Box>
  );
}
