import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getEmailSenderIDLists, getSenderIDLists } from "../../../API/account/senderId";
import Empty from "../../UI/Empty";
import SenderIDListTable from "./SenderIDListTable";
import Loader from "../../UI/Loader";
import CustomModal from "../../UI/CustomModal";
import CreateSenderID from "./CreateSenderID";
import CreateEmailSenderID from "./CreateEmailSenderID";

function EmailSender() {
  const { isLoading, data } = useQuery(["senderIDData"], getEmailSenderIDLists);
  const disclosure = useDisclosure();

  return (
    <Box>
      <Flex>
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Manage Email Sender ID
        </Text>

        <Button
          onClick={disclosure.onOpen}
          colorScheme={"primary"}
          ml="auto"
          size={"sm"}
          isDisabled={isLoading}
        >
          Add new sender ID
        </Button>
      </Flex>

      <Box mt="32px">
        {/* Empty */}
        {!isLoading && data?.docs?.length === 0 && (
          <Empty
            // title='You currently don’t have any sender ID added'
            description={
              <Text as="span">
                You currently don’t have any
                <br /> sender ID added
              </Text>
            }
            buttonText="Add sender ID now"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M48 88C70.0914 88 88 70.0914 88 48C88 25.9086 70.0914 8 48 8C25.9086 8 8 25.9086 8 48C8 70.0914 25.9086 88 48 88Z"
                  fill="#7489AA"
                />
                <path
                  d="M69 40.7181V51.2382C69 51.9182 68.96 52.5581 68.88 53.1981C68.28 60.2781 64.08 63.7982 56.4 63.7982H55.36C54.72 63.7982 54.0801 64.1182 53.6801 64.6382L50.52 68.8381C49.12 70.7181 46.88 70.7181 45.48 68.8381L42.3199 64.6382C41.9599 64.1982 41.2 63.7982 40.64 63.7982H39.6C31.2 63.7982 27 61.7181 27 51.1981V40.6782C27 32.9982 30.56 28.7981 37.6 28.1981C38.24 28.1181 38.8801 28.0781 39.5601 28.0781H56.36C64.8 28.1581 69 32.3581 69 40.7181Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={disclosure.onOpen}
          />
        )}

        {isLoading && <Loader />}

        {!isLoading && data?.docs?.length > 0 && (
          <SenderIDListTable listsData={data?.docs} />
        )}
      </Box>

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" pr="38px">
            Add Email sender ID
          </Text>
        }
      >
        <CreateEmailSenderID close={disclosure.onClose} />
      </CustomModal>
    </Box>
  );
}

export default EmailSender;
