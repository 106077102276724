import { Box, Button, Divider, HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import SetEmailContentType from "../../../components/campaigns/Email/setContentType";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import Container from "../../../components/UI/Container";
import TextContentEditor from "../../../components/campaigns/Email/textEditor";

export interface BuilderInfoType {
  step: number;
  content_type: string;
}

const NewEmailCampaign = () => {
  const [builderInfo, setBuilderInfo] = useState<BuilderInfoType>({
    step: 1,
    content_type: "",
  });

  const onMutate = async (dir: "front" | "back") => {
    if (builderInfo.step === 1) {
      setBuilderInfo((prev) => ({
        ...prev,
        step: 2,
      }));

      return;
    }

    if (builderInfo.step === 2) {
      setBuilderInfo((prev) => ({
        ...prev,
        step: dir === "front" ? 2 : 1,
      }));

      return;
    }
  };

  return (
    <>
      <Box bg={"white"} py={10} minHeight={"calc(100vh - 100px)"}>
        <Box mb={9} px={{ base: "20px", md: "52px" }}>
          <Text
            mb={2}
            textTransform={"uppercase"}
            color={"grays.12"}
            fontWeight={600}
            fontSize="xs"
          >
            Step {builderInfo.step} of 7
          </Text>
          <HStack w={"100%"} gap={2}>
            {Array.from(Array(7)).map((_, index) => (
              <Box
                key={index}
                h={"5px"}
                flex={1}
                bg={builderInfo.step >= index + 1 ? "#0066FF" : "#E4E7EC"}
                borderRadius={"6px"}
              />
            ))}
          </HStack>
        </Box>

        <Divider bg={"#E6E6E670"} w="100%" h={"1px"} />

        <Box px={{ base: "20px", md: "52px" }} py={9}>
          {builderInfo.step === 1 ? (
            <SetEmailContentType
              builderInfo={builderInfo}
              setBuilderInfo={setBuilderInfo}
              onSubmit={() => {}}
            />
          ) : builderInfo.step === 2 ? (
            <TextContentEditor />
          ) : (
            <p>Complete</p>
          )}
        </Box>
      </Box>

      {/* CONTINUE DISCLOSURE */}
      <Box
        position={"fixed"}
        bottom={0}
        right={0}
        left={0}
        bg={"white"}
        borderTopWidth={"1px"}
        borderRadius="0px"
        borderColor={"#E6E6E6"}
        py={5}
        px={{ base: "20px", md: "64px" }}
      >
        <Container>
          <HStack justifyContent="flex-end">
            <Button
              // variant={"link"}
              bg={"transparent"}
              textDecoration={"underline"}
              color={"primary.500"}
              mr={"auto"}
              display={builderInfo.step > 1 ? "flex" : "none"}
            >
              Save & Exit
            </Button>

            <Button
              mr={4}
              colorScheme={"primary"}
              variant={"outline"}
              leftIcon={<IoIosArrowRoundBack size={24} />}
              display={builderInfo.step > 1 ? "flex" : "none"}
              onClick={() => {
                onMutate("back");
              }}
            >
              Back
            </Button>
            <Button
              colorScheme={"primary"}
              rightIcon={<IoIosArrowRoundForward size={24} />}
              isDisabled={
                builderInfo.step === 1 && !builderInfo.content_type
                  ? true
                  : false
              }
              onClick={() => {
                onMutate("front");
              }}
            >
              Next
            </Button>
          </HStack>
        </Container>
      </Box>
    </>
  );
};

export default NewEmailCampaign;
