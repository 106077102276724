export const VBuilderIcon = ({ selected = false }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: "all 0.25s ease-in-out",
      }}
    >
      <path
        opacity="0.4"
        d="M36.6667 27.5026V32.5026C36.6667 35.0026 35 36.6693 32.5 36.6693H10C10.6833 36.6693 11.3833 36.5693 12.0333 36.3526C12.2167 36.2859 12.4 36.2193 12.5833 36.1359C13.1666 35.9026 13.7333 35.5693 14.2333 35.1359C14.3833 35.0193 14.55 34.8693 14.7 34.7193L14.7666 34.6526L26.1 23.3359H32.5C35 23.3359 36.6667 25.0026 36.6667 27.5026Z"
        fill={selected ? "#0066FF" : "#101828"}
      />
      <path
        opacity="0.6"
        d="M30.6166 18.813L26.1 23.3297L14.7666 34.6464C15.9333 33.4464 16.6666 31.7964 16.6666 29.9964V13.8964L21.1833 9.37969C22.9499 7.61302 25.3166 7.61302 27.0833 9.37969L30.6166 12.913C32.3833 14.6797 32.3833 17.0464 30.6166 18.813Z"
        fill={selected ? "#0066FF" : "#101828"}
      />
      <path
        d="M12.5002 3.33594H7.50016C5.00016 3.33594 3.3335 5.0026 3.3335 7.5026V30.0026C3.3335 30.4526 3.38347 30.9026 3.46681 31.3359C3.51681 31.5526 3.56682 31.7693 3.63348 31.9859C3.71682 32.2359 3.80016 32.4859 3.90016 32.7193C3.91682 32.7359 3.91684 32.7526 3.91684 32.7526C3.93351 32.7526 3.93351 32.7526 3.91684 32.7693C4.15017 33.2359 4.41682 33.6859 4.73349 34.1026C4.91682 34.3193 5.10019 34.5193 5.28352 34.7193C5.46685 34.9193 5.66683 35.0859 5.88349 35.2526L5.90018 35.2693C6.31684 35.5859 6.76682 35.8526 7.23349 36.0859C7.25016 36.0693 7.25017 36.0693 7.25017 36.0859C7.50017 36.2026 7.75016 36.2859 8.01683 36.3693C8.23349 36.4359 8.45018 36.4859 8.66685 36.5359C9.10018 36.6193 9.55016 36.6693 10.0002 36.6693C10.6835 36.6693 11.3835 36.5693 12.0335 36.3526C12.2168 36.2859 12.4001 36.2193 12.5835 36.1359C13.1668 35.9026 13.7335 35.5693 14.2335 35.1359C14.3835 35.0193 14.5502 34.8693 14.7002 34.7193L14.7668 34.6526C15.9335 33.4526 16.6668 31.8026 16.6668 30.0026V7.5026C16.6668 5.0026 15.0002 3.33594 12.5002 3.33594ZM10.0002 32.5026C8.61683 32.5026 7.50016 31.3859 7.50016 30.0026C7.50016 28.6193 8.61683 27.5026 10.0002 27.5026C11.3835 27.5026 12.5002 28.6193 12.5002 30.0026C12.5002 31.3859 11.3835 32.5026 10.0002 32.5026Z"
        fill={selected ? "#0066FF" : "#101828"}
      />
    </svg>
  );
};

export const TBuilderIcon = ({ selected = false }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: "all 0.25s ease-in-out",
      }}
    >
      <path
        opacity="0.4"
        d="M36.6668 13.0193V26.9859C36.6668 33.0526 33.0502 36.6693 26.9835 36.6693H13.0168C12.6835 36.6693 12.3502 36.6526 12.0335 36.6359C9.9835 36.5026 8.25016 35.9193 6.8835 34.9193C6.1835 34.4359 5.56683 33.8193 5.0835 33.1193C3.9335 31.5359 3.3335 29.4693 3.3335 26.9859V13.0193C3.3335 7.28594 6.56683 3.73594 12.0335 3.38594C12.3502 3.3526 12.6835 3.33594 13.0168 3.33594H26.9835C29.4668 3.33594 31.5335 3.93594 33.1168 5.08594C33.8168 5.56927 34.4335 6.18594 34.9168 6.88594C36.0668 8.46927 36.6668 10.5359 36.6668 13.0193Z"
        fill={selected ? "#0066FF" : "#B8B8B8"}
      />
      <path
        d="M18.9833 23.9641H18.3667V13.1807H22.65C22.8333 13.1807 22.9833 13.3307 22.9833 13.5141V14.8141C22.9833 15.4974 23.55 16.0641 24.2333 16.0641C24.9167 16.0641 25.4833 15.4974 25.4833 14.8141V13.4974C25.4833 11.9307 24.2167 10.6641 22.65 10.6641H11.5833C10.0167 10.6641 8.75 11.9307 8.75 13.4974V14.7974C8.75 15.4974 9.31667 16.0474 10 16.0474C10.6833 16.0474 11.25 15.4974 11.25 14.7974V13.4974C11.25 13.3141 11.4 13.1641 11.5833 13.1641H15.8667V23.9641H14.2C13.5167 23.9641 12.95 24.5307 12.95 25.2141C12.95 25.8974 13.5167 26.4641 14.2 26.4641H18.9667C19.65 26.4641 20.2167 25.8974 20.2167 25.2141C20.2167 24.5307 19.6667 23.9641 18.9833 23.9641Z"
        fill={selected ? "#0066FF" : "#101828"}
      />
      <path
        d="M28.8667 17.3359H22.8667C22.1834 17.3359 21.6167 17.9026 21.6167 18.5859C21.6167 19.2693 22.1834 19.8359 22.8667 19.8359H23.6667V26.8359H23.0834C22.4 26.8359 21.8334 27.4026 21.8334 28.0859C21.8334 28.7693 22.4 29.3359 23.0834 29.3359H26.75C27.4334 29.3359 28 28.7693 28 28.0859C28 27.4026 27.4334 26.8359 26.75 26.8359H26.1667V19.8359H28.75V20.4026C28.75 21.0859 29.3167 21.6526 30 21.6526C30.6834 21.6526 31.25 21.0859 31.25 20.4026V19.7193C31.25 18.4026 30.1834 17.3359 28.8667 17.3359Z"
        fill={selected ? "#0066FF" : "#101828"}
      />
    </svg>
  );
};
