import { useCallback, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "clean",
];

const TextEditor = () => {
  // Editor state
  const [value, setValue] = useState("");
  const quill = useRef<ReactQuill | null>(null);

  const imageHandler = useCallback(() => {
    // Create an input element of type 'file'
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // When a file is selected
    input.onchange = () => {
      // @ts-ignore
      const file = input.files[0];
      const reader = new FileReader();

      // Read the selected file as a data URL
      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quill.current?.getEditor();

        // Get the current selection range and insert the image at that index
        const range = quillEditor?.getSelection(true);
        quillEditor?.insertEmbed(range?.index || 1, "image", imageUrl, "user");
      };

      reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  function handler() {
    console.log("curr value", value);
  }
  return (
    <div>
      <label>Editor Content</label>
      <QuillEditor
        ref={(el) => (quill.current = el)}
        id={"custom-text-editor"}
        //   className={styles.editor}
        // theme="bubble"
        theme="snow"
        value={value}
        onChange={(value) => setValue(value)}
        formats={formats}
        modules={modules}
      />

      <button onClick={handler}>Submit</button>
    </div>
  );
};

export default TextEditor;
