import { useToast, chakra, Button } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { createSenderID } from '../../../API/account/senderId';
import { toaster } from '../../../utils/toast';
import FormInput from '../../UI/form/FormInput';

const CreateSenderID: FC<{ close: () => void }> = ({ close }) => {
  const [name, setName] = useState('');

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createSenderID, {
    onSuccess(data) {
      const success = { ...data }
      toaster(toast, success)
      queryClient.invalidateQueries(['senderIDData']);
      close && close();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors)
    },
  });

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault();

        mutate({ name });
      }}
    >
      <FormInput
        label='Sender name'
        type='text'
        for='Sender name'
        formControlProps={{
          isRequired: true,
        }}
        inputProps={{
          placeholder: 'Enter sender name',
          onChange: (e) => {
            setName(e.target.value);
          },
          // max: '11',
          // isInvalid: name?.length > 11,
        }}
        // helperText={
        //   name?.length > 11
        //     ? 'Max. no. of characters exceeded'
        //     : 'Max 11 characters'
        // }
      />

      <Button
        isLoading={isLoading}
        // isDisabled={!name || name?.length > 11}
        w='full'
        colorScheme={'primary'}
        mt='32px'
        mb='40px'
        type='submit'
      >
        Add sender ID
      </Button>
    </chakra.form>
  );
};

export default CreateSenderID;
