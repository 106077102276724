import API from ".";









export const getDashboardCampaignData = async(data: {
    startDate?: Date | null;
    endDate?:  Date | null;
    // filterByDuration?: string;
  })=>{
    const response = await API.get(`/report/dashboard/show?startDate=${data?.startDate?.toISOString().split('T')[0] ?? null}&endDate=${data?.endDate?.toISOString().split('T')[0] ?? null}`);
    return response.data.data;
}