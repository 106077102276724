import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import SelectEmailTemplate from "./SelectEmailTemplate";
import CreateEmailTemplate from "./CreateEmailTemplate";
interface TemplateProps {
  setTemplateBody: Dispatch<SetStateAction<any>>  ;
  setTemplateDesign: Dispatch<SetStateAction<{}[]>>;
  templateDesign?:{}[];

}
function EmailTemplates({ setTemplateBody, setTemplateDesign,templateDesign}: TemplateProps) {
  const [selected, setSelected] = useState(true);
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const emailEditorRef = useRef<any>(null);
  return (
    <div>
      {!selected ? (
        <SelectEmailTemplate
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          setSelected={setSelected}
        />
      ) : (
        <CreateEmailTemplate
          emailEditorRef={emailEditorRef}
          htmlContent={htmlContent}
          setHtmlContent={setHtmlContent}
          selectedTemplate={selectedTemplate}
          setTemplateBody={setTemplateBody}
          setTemplateDesign={setTemplateDesign}
          templateDesign={templateDesign}

        />
      )}
    </div>
  );
}

export default EmailTemplates;
