import { MouseEventHandler, ReactNode } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  // MenuItemOption,
  // MenuGroup,
  // MenuOptionGroup,
  // MenuDivider,
  Button,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import { IoChevronBackOutline } from "react-icons/io5";

interface ButtonDropdownProps {
  list: Array<{
    option: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }>;
  title?: string;
  placeholder?: string;
  isPrimary?: boolean;
  menuItemProps?: {};
  menuButtonProps?: {};
  isAttr?: boolean;
  disableIcon?: boolean;
}

interface ButtonPrimaryProps {
  children: ReactNode;
  buttonProps?: {};
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export function ButtonDropdown({
  list,
  title = "Actions",
  isPrimary = true,
  menuItemProps,
  menuButtonProps,
  isAttr,
  disableIcon,
}: ButtonDropdownProps) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={isAttr ? <></> : disableIcon ? <></> : <FaCaretDown />}
        colorScheme="primary"
        fontSize={isAttr ? "lg" : "xs"}
        py="10px"
        px="16px"
        maxHeight="32px"
        {...(isPrimary
          ? {
              variant: "solid",

              ...menuButtonProps,
            }
          : {
              variant: "outline",
              ...menuButtonProps,
            })}
      >
        {title}
      </MenuButton>
      <MenuList
        boxShadow={"md"}
        zIndex={3}
        maxHeight={"170px"}
        overflowY={"auto"}
        className="thinSB"
      >
        {list.map((item, index) => (
          <MenuItem
            key={index}
            {...{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              mb: "6px",
              ...menuItemProps,
            }}
            onClick={item.onClick}
          >
            {item.option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export function ButtonPrimary({
  children,
  buttonProps,
  onClick,
  isLoading,
  isDisabled,
}: ButtonPrimaryProps) {
  return (
    <Button
      {...{
        variant: "solid",
        colorScheme: "primary",
        maxHeight: "32px",
        py: "10px",
        px: "16px",
        fontSize: "12px",
        fontWeight: 500,
        ...buttonProps,
      }}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
}

export function GoBack({ children }: { children: React.ReactNode }) {
  return (
    <Button
      color={"grays.12"}
      fontSize={"xs"}
      fontWeight={500}
      _hover={{ textDecoration: "underline" }}
      minHeight={"12px"}
      height={"fit-content"}
      p={"0px"}
      variant="ghost"
      onClick={() => {
        window.history.go(-1);
      }}
      leftIcon={<IoChevronBackOutline />}
    >
      {children ? children : "Back"}
    </Button>
  );
}
