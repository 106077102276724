import { atom } from 'recoil';

type stageProps = 'sample' | 'upload' | 'map' | 'final' | 'success';

const importContactsAtom = atom<{
  currentStage: stageProps;
  fileData?: any;
  file: any;
  csvDataToUse: any;
  list_uid: string;
  criteria: string;
}>({
  key: 'importContactsAtom',
  default: {
    currentStage: 'sample',
    fileData: null,
    file: null,
    csvDataToUse: [],
    list_uid: '',
    criteria: '',
  }, 
});

export { importContactsAtom };
