import {
  Box,
  Text,
  chakra,
  VStack,
  Button,
  useToast,
  SimpleGrid,
  Flex,
  useDisclosure,
  Heading,
  Center,
  Avatar,
  HStack,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { getData } from "../../../API/data";
import FormInput from "../../../components/UI/form/FormInput";
import SearchableSelect from "../../../components/UI/form/SearchableSelect";
import {
  getSingleTemplate,
  updateTemplate,
} from "../../../API/account/templates";
import { toaster } from "../../../utils/toast";
import dayjs from "dayjs";
import { ReadIcon } from "../../../icons/ReadIcon";
import { ButtonDropdown, GoBack } from "../../../components/UI/Buttons";
import Loader from "../../../components/UI/Loader";
import ReactQuill from "react-quill";
import { getContactAttributes } from "../../../API/contacts";
import {
  removeHTMLTags,
  removeSpacesAndJoin,
  removeUnderscores,
} from "../../../functions";
import { TiptapBox } from "../../../components/UI/Tiptap";
import Iphone from "../../../components/UI/Iphone";
import ProgressLoader from "../../../components/UI/ProgressLoader";
import { useRecoilState, useResetRecoilState } from "recoil";
import { tipTapAtom } from "../../../atoms/tiptap";
import { ColorGreen, ColorPink, ColorYellow } from "../../../icons/Builder";
import EmailTemplates from "./VisualEmailTemplates";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    // ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "font-size",
];

export const EditTemplate = () => {
  const [currChannel, setCurrChannel] = useState("");
  const [isReady, setIsReady] = useState(false);

  //   const [templateData, setTemplateData] = useState<{} | null>(null);
  const [templateName, setTemplateName] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [templateBody, setTemplateBody] = useState<string>("");
  const [templateType, setTemplateType]= useState<string>("");
  const [templateDesign, setTemplateDesign] = useState([{}]);
  const params = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const addLinkDisclosure = useDisclosure();

  const [tiptapValue, setTiptapValue] = useRecoilState(tipTapAtom);
  const resetTiptapData = useResetRecoilState(tipTapAtom);
  const [attributes, setAttributes] = useState<string[]>([]);
  const quillRef = useRef<InstanceType<typeof ReactQuill> | null>(null);

  const setVariable = (e: string) => {
    const cursorPos = quillRef?.current?.getEditor()?.getSelection()?.index;
    if (cursorPos == null) return;
    quillRef?.current?.getEditor()?.insertText(cursorPos, e);
  };
  console.log("templateDesign", templateDesign)
  console.log("template body", templateBody )


  useEffect(() => {
    setCurrChannel(params.all!);

    resetTiptapData();
  }, [params, resetTiptapData]);

  const displayModule = {
    toolbar: false,
  };

  const { isLoading } = useQuery({
    queryKey: ["fetchSingleTemplate", params.id, currChannel],
    queryFn: ({ queryKey }) => {
      return getSingleTemplate(queryKey[1]!, queryKey[2]!);
    },
    onSuccess: (data) => {
      //   setTemplateData(data);
      setTemplateName(data.name);
      setTiptapValue((prev) => ({
        ...prev,
        content: data.template_body,
      }));
      setTemplateBody(data.template_body[0])
      setTemplateType(data.template_type)
      setTemplateDesign(data.template_data)

      // setTemplateBody(data.template_body);

      if (currChannel === "Whatsapp") {
        setLanguage(data.language);
      }
      setIsReady(true);
    },
  });

  const contactAttributes = useQuery({
    queryKey: ["get_contact_attributes2"],
    queryFn: getContactAttributes,
    retry: 5,
    enabled: !isLoading,

    onSuccess: (data) => {
      setTiptapValue((prev) => ({
        ...prev,
        rawAttributes: data.data,
      }));
      let arr: { front: string; back: string }[] = [];
      for (let i = 0; i < data?.data.length; i++) {
        let str3 = data.data[i];
        let str2 = removeUnderscores(data.data[i]);
        let str = removeSpacesAndJoin(str2);
        arr.push({ front: str, back: str3 });
      }
      setTiptapValue((prev) => ({
        ...prev,
        attributes: arr,
      }));

      // console.log("doneeeeeee", attributes);
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
      setIsReady(true);
    },
  });

  //   get languages
  const { data: userData } = useQuery(["bodData"], getData);
  const languages = useMemo(() => {
    return userData?.languages?.map((language) => {
      return {
        label: language,
        value: language,
      };
    });
  }, [userData?.languages]);

  const editTemplateMutation = useMutation(updateTemplate, {
    onSuccess: (data) => {
      const success = { ...data };
      queryClient.invalidateQueries([
        "fetchSingleTemplate",
        params.id,
        currChannel,
      ]);
      toaster(toast, success);
      window.history.go(-1);
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  // const onEmojiClick = (emojiObject: any, event: any) => {
  //   setTemplateBody((prevInput: any) => prevInput + emojiObject.emoji);
  //   setShowPicker(false);
  // };
  const [view, setView] = useState("web");
  const contactAttributesQuery = useQuery({
    queryKey: ["get_contact_attributes2"],
    queryFn: getContactAttributes,
    retry: 3,

    onSuccess: (data) => {
      setAttributes(data.data);
      // setRawAttributes(data.data);
      // setTiptapValue((prev) => ({
      //   ...prev,
      //   rawAttributes: data.data,
      // }));
      // let arr: { front: string; back: string }[] = [];
      // for (let i = 0; i < data?.data.length; i++) {
      //   let str3 = data.data[i];
      //   let str2 = removeUnderscores(data.data[i]);
      //   let str = removeSpacesAndJoin(str2);
      //   arr.push({ front: str, back: str3 });
      // }
      // // setAttributes(arr);
      // setTiptapValue((prev) => ({
      //   ...prev,
      //   attributes: arr,
      // }));
      // setIsReady(true);
      // // console.log("doneeeeeee", attributes);
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
      // setIsReady(true);
    },
  });

  if (currChannel === "Email" && templateType === "Visual Builder") {

    return(
      <Box>
        <Box w="full" h="full">
        <GoBack>
          <Text fontSize={"xs"} color="grays.12">
            Back to {currChannel} Templates
          </Text>
        </GoBack>

        <Heading
          as="h3"
          mt={"20px"}
          mb={"48px"}
          fontSize={"20px"}
          color="grays.9"
          fontWeight={"700"}
        >
          Edit Template
        </Heading>

        {isLoading && !isReady ? (
          <Loader />
        ) : (
          <Box w="full" mt={"20px"}>
            
            <Text fontSize={"16px"} color="grays.9" fontWeight={"700"}>
              Template Information
            </Text>
            <>
              <chakra.form
                onSubmit={(e) => {
                  e.preventDefault();
                  editTemplateMutation.mutate({
                    channel: currChannel,
                    uid: params.id!,
                    name: templateName,
                    template_body: templateBody,
                    template_data:templateDesign
                  });
                }}
                mt="24px"
              >
                <VStack
                  maxW={"360px"}
                  align={"flex-start"}
                  spacing="20px"
                  mb={ "32px"}
                >
                  <FormInput
                    label="Template name"
                    type="text"
                    for="Template name"
                    inputProps={{
                      placeholder: "Enter Template name",
                      onChange: (e) => {
                        setTemplateName(e.target.value);
                      },
                      value: templateName,
                    }}
                    formControlProps={{
                      isRequired: true,
                    }}
                  />
                  <EmailTemplates setTemplateBody={setTemplateBody} setTemplateDesign={setTemplateDesign} templateDesign={templateDesign}/>
                  <Button
                      type="submit"
                      isLoading={editTemplateMutation.isLoading}
                      isDisabled={!templateName || !templateBody
                      }
                      w="full"
                      rounded="4px"
                      _hover={{ bg: "primary.500" }}
                      bg="primary.500"
                      _disabled={{
                        bg: "primary.400",
                      }}
                      color={"white"}
                      fontSize={"sm"}
                    >
                      Save Changes
                    </Button>
                  </VStack>
              </chakra.form>

              </>

          </Box>
        )}

        </Box>
      </Box>
    )
    
  }

  return (
    <Box>
      {contactAttributes.isLoading ? <ProgressLoader /> : null}

      <Box w="full" h="full">
        <GoBack>
          <Text fontSize={"xs"} color="grays.12">
            Back to {currChannel} Templates
          </Text>
        </GoBack>

        <Heading
          as="h3"
          mt={"20px"}
          mb={"48px"}
          fontSize={"20px"}
          color="grays.9"
          fontWeight={"700"}
        >
          Edit Template
        </Heading>

        {isLoading && !isReady ? (
          <Loader />
        ) : (
          <Box w="full" mt={"20px"}>
            <Text fontSize={"16px"} color="grays.9" fontWeight={"700"}>
              Template Information
            </Text>
            <>
              <chakra.form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (currChannel === "Email") {
                    editTemplateMutation.mutate({
                      channel: currChannel,
                      uid: params.id!,
                      name: templateName,
                      template_body:[templateBody],
                    });
                  } else {
                    editTemplateMutation.mutate({
                      channel: currChannel,
                      uid: params.id!,
                      name: templateName,
                      template_body:templateBody,
                    });
                  }
                  
                }}
                mt="24px"
              >
                <VStack
                  maxW={"360px"}
                  align={"flex-start"}
                  spacing="20px"
                  mb={currChannel !== "Whatsapp" ? "32px" : "0px"}
                >
                  <FormInput
                    label="Template name"
                    type="text"
                    for="Template name"
                    inputProps={{
                      placeholder: "Enter Template name",
                      onChange: (e) => {
                        setTemplateName(e.target.value);
                      },
                      value: templateName,
                    }}
                    formControlProps={{
                      isRequired: true,
                    }}
                  />

                  {currChannel === "Whatsapp" ? (
                    <SearchableSelect
                      isDisabled={true}
                      label="Language"
                      options={languages}
                      value={languages?.filter((lang) => {
                        return lang?.value === language;
                      })}
                      onChange={(selected: {
                        label: string;
                        value: string;
                      }) => {
                        setLanguage(selected?.value);
                      }}
                      formControlProps={{
                        isRequired: true,
                      }}
                    />
                  ) : null}
                </VStack>

                <SimpleGrid columns={2} spacing={14}>
                  <VStack maxWidth={"440px"} width="100%" spacing={"12px"}>
                    <Text
                      alignSelf={"flex-start"}
                      mt={"20px"}
                      mb={"4px"}
                      fontSize="16px"
                      fontWeight={"700"}
                    >
                      Template body
                    </Text>
                    <Box
                      display={"flex"}
                      px={4}
                      alignItems={"center"}
                      width={"100%"}
                      // w={"440px"}
                      // h={"32px"}
                      bg={"backgrounds.500"}
                      borderRadius={"4px"}
                      py={"8px"}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6673 8.0026C14.6673 11.6853 11.6827 14.6693 8.00065 14.6693C4.31865 14.6693 1.33398 11.6853 1.33398 8.0026C1.33398 4.32127 4.31865 1.33594 8.00065 1.33594C11.6827 1.33594 14.6673 4.32127 14.6673 8.0026Z"
                          fill="#7489AA"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.58073 8.42062C8.58073 8.74196 8.31873 9.00396 7.9974 9.00396C7.67606 9.00396 7.41406 8.74196 7.41406 8.42062V5.47396C7.41406 5.15262 7.67606 4.89062 7.9974 4.89062C8.31873 4.89062 8.58073 5.15262 8.58073 5.47396V8.42062ZM7.41733 10.5365C7.41733 10.2151 7.678 9.95312 7.99733 9.95312C8.326 9.95312 8.58733 10.2151 8.58733 10.5365C8.58733 10.8578 8.326 11.1198 8.004 11.1198C7.68067 11.1198 7.41733 10.8578 7.41733 10.5365Z"
                          fill="white"
                        />
                      </svg>
                      <Text ml={2} fontSize={"10px"} fontWeight={500}>
                        You can specify what variables you’d like to use while
                        sending your message.
                      </Text>
                    </Box>
                    {currChannel === "SMS" ? (
                      <Box
                        borderRadius={"4px"}
                        borderColor={"borders.500"}
                        borderWidth={"1px"}
                        width={"100%"}
                      >
                        {isReady ? (
                          <TiptapBox
                            // setPlainText={setPlainText}
                            addLinkDisclosure={addLinkDisclosure}
                            onChange={() => {
                              setTemplateBody(() => {
                                if (tiptapValue.linkObject.link) {
                                  return (
                                    tiptapValue.content +
                                    " " +
                                    tiptapValue.linkObject.link
                                  );
                                } else {
                                  return tiptapValue.content;
                                }
                              });
                            }}
                          />
                        ) : null}
                      </Box>
                    ) : (
                      <Box
                        display={"flex"}
                        borderWidth={"1px"}
                        position="relative"
                        borderColor={"borders.500"}
                        w={'full'}
                        h={"full"}
                      >
                        <Box
                          className="textbox-attribute"
                          position={"absolute"}
                          zIndex={2}
                          top={`100px`}
                          left={"-48px"}
                        >
                          <Tooltip
                            label="Add an attribute"
                            aria-label="Add an attribute dropdown"
                            openDelay={500}
                            closeDelay={350}
                            fontSize={"12px"}
                          >
                            <Box>
                              <ButtonDropdown
                                isAttr
                                menuButtonProps={{
                                  bg: "#197eef1f ",
                                  color: "#111",
                                  fontWeight: 700,
                                  fontSize: "22px",
                                  py: 5,
                                  pr: 1,
                                  pl: 3,
                                  borderRadius: "full",
                                  boxShadow: "sm",
                                  _hover: {
                                    bg: "gray.200",
                                  },
                                  _active: {
                                    bg: "gray.200",
                                  },
                                }}
                                title="@"
                                list={attributes.map((x) => {
                                  return {
                                    option: removeUnderscores(x),
                                    onClick: () => {
                                      setVariable(`{{${x}}}`);
                                    },
                                  };
                                })}
                                menuItemProps={{
                                  _hover: {
                                    bg: "gray.200",
                                  },
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>

                        <ReactQuill
                          style={{
                            width: "100%",
                            // height: "603px",
                            // maxHeight: "603px",
                            minHeight: "300px",
                            marginTop: "0px",
                          }}
                          modules={modules}
                          formats={formats}
                          theme="snow"
                          ref={quillRef}
                          onChange={setTemplateBody}
                          value={templateBody}
                        />
                      </Box>
                    )}
                    {currChannel === "SMS" ? (
                      <Flex
                      alignSelf={"flex-end"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      fontSize={"10px"}
                      w={"106px"}
                      h={"24px"}
                      bg={"borders.200"}
                      mb={"24px"}
                    >
                      <Text fontWeight={500} color={"grays.12"}>
                        LIMIT:{" "}
                        {templateBody?.length > 0
                          ? templateBody?.length - 7
                          : 0}
                        /320 Words
                      </Text>
                    </Flex>
                    ):(null)}
                    

                    <Button
                      type="submit"
                      isLoading={editTemplateMutation.isLoading}
                      isDisabled={
                        currChannel === "Whatsapp"
                          ? !templateName || !language || !templateBody
                          : !templateName || !templateBody
                      }
                      w="full"
                      rounded="4px"
                      _hover={{ bg: "primary.500" }}
                      bg="primary.500"
                      _disabled={{
                        bg: "primary.400",
                      }}
                      color={"white"}
                      fontSize={"sm"}
                    >
                      Save Changes
                    </Button>
                  </VStack>

                  <VStack>
                    <Text
                      alignSelf={"flex-start"}
                      mt={"20px"}
                      fontSize="16px"
                      fontWeight={700}
                    >
                      Preview
                    </Text>

                    {currChannel === "Email" ? (
                      // <Flex
                      //   justifyContent={"center"}
                      //   alignItems={"center"}
                      //   px={"26px"}
                      //   py={"42px"}
                      //   borderRadius={"4px"}
                      //   borderColor={"borders.500"}
                      //   borderWidth={"1px"}
                      // >
                      //   <Box
                      //     position="relative"
                      //     bg={"backgrounds.600"}
                      //     pt={"29px"}
                      //     px={"41px"}
                      //     pb={"41px"}
                      //     display="flex"
                      //     justifyContent={"center"}
                      //     alignItems={"center"}
                      //     borderRadius={"8px"}
                      //   >
                      //     <Box position={"relative"}>
                      //       <Text
                      //         mx={"auto"}
                      //         mb={"29px"}
                      //         borderRadius={"8px"}
                      //         fontSize={"12px"}
                      //         bg={"backgrounds.700"}
                      //         w={"100px"}
                      //         display={"flex"}
                      //         justifyContent={"center"}
                      //         px={"auto"}
                      //         h={"21px"}
                      //         overflow={"hidden"}
                      //         fontWeight={600}
                      //       >
                      //         {dayjs(new Date()).format("DD MMM, YYYY")}
                      //       </Text>
                      //       <Flex
                      //         justifyContent={"center"}
                      //         alignItems={"center"}
                      //       >
                      //         <Box
                      //           height={"156px"}
                      //           width={"306px"}
                      //           bg={"green.400"}
                      //           borderRadius={"8px"}
                      //           overflowY="auto"
                      //           className="thinSB"
                      //           position={"relative"}
                      //           display={"flex"}
                      //           flexDirection={"column"}
                      //           justifyContent={"space-between"}
                      //         >
                      //           <ReactQuill
                      //             modules={displayModule}
                      //             theme="snow"
                      //             value={
                      //               templateBody
                      //                 ? templateBody
                      //                 : "Sample message goes here..."
                      //             }
                      //             readOnly={true}
                      //           />
                      //           <Flex
                      //             bg={"green.400"}
                      //             pr={"8px"}
                      //             pb={"6px"}
                      //             bottom="8px"
                      //             right="11px"
                      //             justifyContent={"right"}
                      //             alignItems={"center"}
                      //           >
                      //             <Text
                      //               color={"grays.12"}
                      //               fontSize={"11px"}
                      //               fontWeight={"400"}
                      //             >
                      //               {dayjs(new Date()).format("hh:mm : A")}
                      //             </Text>
                      //             <ReadIcon />
                      //           </Flex>
                      //         </Box>
                      //       </Flex>
                      //     </Box>
                      //   </Box>
                      // </Flex>
                      <Box
                        borderWidth={"1px"}
                        borderColor={"borders.500"}
                        className="previewBox"
                        width={"full"}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          borderBottomWidth={"1px"}
                          borderColor={"borders.500"}
                        >
                          <HStack mx={3} p={2}>
                            <Box display={"flex"}>
                              <Box mx={1}>
                                <ColorPink />
                              </Box>
                              <Box mx={1}>
                                <ColorYellow />
                              </Box>
                              <Box mx={1}>
                                <ColorGreen />
                              </Box>
                            </Box>
                            <Box fontWeight={700}>Preview</Box>
                          </HStack>
                        </Flex>
                        <Box
                          bg={"borders.200"}
                          minH={"200px"}
                          mx={6}
                          mt={5}
                          py={10}
                          px={12}
                        >
                          <Box
                            bg="white"
                            pt={5}
                            m="auto"
                            maxW={view === "mobile" ? "280px" : "500px"}
                          >
                            <Text
                              fontSize={"12px"}
                              textAlign={"center"}
                              fontWeight={700}
                            >
                              LOGO
                            </Text>
                            <Box
                              w="full"
                              pt={2}
                              pb={8}
                              minH="380px"
                              fontSize="10px"
                              position="relative"
                            >
                              <ReactQuill
                                style={{ border: "1rem solid #fff" }}
                                modules={displayModule}
                                theme="snow"
                                value={
                                  templateBody
                                    ? templateBody
                                    : "Sample message goes here..."
                                }
                                readOnly={true}
                              />
                              <Text
                                textAlign={"center"}
                                bottom={6}
                                color={"gray.300"}
                                position="absolute"
                                right={0}
                                left={0}
                                fontSize="10px"
                              >
                                Sent using Bodsquare by GO54
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                        <Center flexDir={"row"} textAlign="center" pb={3}>
                          <Flex>
                            <Box>
                              <Text
                                mt="50px"
                                mb="5px"
                                fontWeight={700}
                                mx={2}
                                fontSize={"12px"}
                                w={"72px"}
                              >
                                Preview as:
                              </Text>
                            </Box>
                            <Button
                              onClick={() => setView("web")}
                              mt="40px"
                              mb="10px"
                              mx={2}
                              w="full"
                              maxW={"96px"}
                              borderWidth={"1px"}
                              color={
                                view === "mobile"
                                  ? "borders.400"
                                  : "primary.500"
                              }
                              bg={"backgrounds.300"}
                              borderColor={
                                view === "mobile"
                                  ? "borders.400"
                                  : "primary.500"
                              }
                            >
                              Web
                            </Button>
                            <Button
                              onClick={() => setView("mobile")}
                              mt="40px"
                              mb="10px"
                              mx={2}
                              w="full"
                              maxW={"96px"}
                              borderWidth={"1px"}
                              color={
                                view === "web" ? "borders.400" : "primary.500"
                              }
                              bg={"backgrounds.300"}
                              borderColor={
                                view === "web" ? "borders.400" : "primary.500"
                              }
                            >
                              Mobile
                            </Button>
                            {/* <Button
              mx={2}
              isDisabled={!currentStageData?.data?.message_body}
              onClick={() => {
                setCurrentStageData((prevData: any) => {
                  return {
                    ...prevData,
                    currentStage: "Recipients",
                  };
                });
              }}
              mt="40px"
              mb="10px"
              w="full"
              maxW={"238px"}
              colorScheme={"primary"}
            >
              Continue to recipients
            </Button> */}
                          </Flex>
                        </Center>
                      </Box>
                    ) : (
                      <Flex
                        borderWidth={"1px"}
                        // pt="29px"
                        borderColor={"borders.100"}
                        w="full"
                        overflow={"hidden"}
                      >
                        <Box pos="relative" transform={"scale(0.9)"}>
                          <Iphone />
                          <Box
                            pos="absolute"
                            top={16}
                            bottom={0}
                            left="33px"
                            w="full"
                            maxW={"370px"}
                          >
                            {/* header */}
                            <Center
                              h="123px"
                              flexDir={"column"}
                              textAlign="center"
                              borderBottomWidth={"1px"}
                            >
                              <Avatar name="R" w="51px" h="51px" />

                              <Text mt="4px">YOUR SENDER ID</Text>
                            </Center>

                            <Flex
                              height={"100%"}
                              mt="6px"
                              flexDir={"column"}
                              bg="#Fff"
                            >
                              {/* message info */}
                              <Box textAlign={"center"} fontWeight={500}>
                                <Text
                                  textAlign={"center"}
                                  fontSize="12px"
                                  color="grays.12"
                                >
                                  Text Message
                                </Text>
                                <Text
                                  textAlign={"center"}
                                  fontSize="12px"
                                  color="grays.12"
                                >
                                  Today {dayjs(new Date()).format("h:mm A")}
                                </Text>
                              </Box>

                              {/* Message bubble */}
                              <Box
                                mt="18px"
                                borderRadius={"19px"}
                                py="8px"
                                px="4px"
                                bg="#E9E9EB"
                                maxW="258px"
                                ml="15px"
                              >
                                {/* <TipTapReadOnly content={tiptapValue.content} /> */}
                                {removeHTMLTags(tiptapValue.content)}
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      </Flex>
                    )}
                  </VStack>
                </SimpleGrid>
              </chakra.form>
            </>
          </Box>
        )}
      </Box>
    </Box>
  );
};
