import { Box, Button, Input, Text } from "@chakra-ui/react";


function AddDomain() {
    return (
        <Box  w={'360px'} m={'auto'} mt={10}>
           <Text fontWeight={700} fontSize={12}>Add Domain</Text>
           <Text color={'#7489AA'} fontSize={10} fontWeight={500}>Add yours or you can use our default domain</Text>
           <Input type="text" placeholder="Bodsquare.com" border={'1px solid #0066FF29'}/>
           <Text color={'#7489AA'} fontSize={10} fontWeight={500}>mywebsite.com or mail.mywebsite.com, don't include "http://" or "https://"</Text>

           <Text fontWeight={700} fontSize={12} mt={5}>Sender</Text>
           <Input type="text" placeholder="Enter sender name" border={'1px solid #0066FF29'}/>

           <Button mt={10} w={'full'} rounded={'lg'} color={'white'} bg={'#B2B6BD'}>Next</Button>
           
        </Box>
    );
}

export default AddDomain;