import React from "react";
import TextEditor from "../../UI/QuillEditor/setup";

const EmailTextBuilder = () => {
  return (
    <div>
      <TextEditor />
    </div>
  );
};

export default EmailTextBuilder;
