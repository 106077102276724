const AUTH_ROUTES = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  CONFIRM_EMAIL: "/confirmEmail",
};

const DASH_ROUTES = {
  GET_STARTED: "/getStarted",
  CONVERSATIONS: "/conversations",
  REPORTS: "/reports",
  CONTACTS: "/contacts",
  SINGLE_CONTACT: "/contacts/$",
  IMPORTCONTACTS: "/contacts/import",
  DASHBOARD:"/dashboard",

  CAMPAIGNS: "/campaigns",
  CAMPAIGN_REPORT: "/campaigns/$/report",
  NEW_CAMPAIGN: "/campaign/new",
  CAMPAIGN_WALLET: "/campaign/wallet",
  NEW_EMAIL_CAMPAIGN: "/campaign/email/new",

  PROFILE: "/account/profile",
  LISTS: "/account/lists",
  BILLINGS: "/account/billings",
  BILLINGS_PLANS: "/account/billings/plans",
  PRICING: "/account/pricing",
  CARDS: "/account/billings/cards",
  CONTACTS_FOR_LISTS: "/account/lists/$/contacts",
  OPT_IN: "/account/optin",
  TEMPLATES: "/accounts/templates",

  CHANNELS: "/account/channels",
  ADD_CHANNELS: "/account/channels/addChannel",
  HELP_SUPPORT: "/account/helpSupport",
};

export { AUTH_ROUTES, DASH_ROUTES };
