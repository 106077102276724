import { useState, useRef, FC, SetStateAction, Dispatch } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Button,
  Center,
  HStack,
  Stack,
  chakra,
  useToast,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomModal from "../../../components/UI/CustomModal";
import FormInput from "../../../components/UI/form/FormInput";
import { ColorGreen, ColorPink, ColorYellow } from "../../../icons/Builder";
import { removeUnderscores } from "../../../functions";
import { ButtonDropdown } from "../../../components/UI/Buttons";
import { getContactAttributes } from "../../../API/contacts";
import { useQuery } from "@tanstack/react-query";
import { toaster } from "../../../utils/toast";

const displayModule = {
  toolbar: false,
};
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    // ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "font-size",
];
interface TemplateProps {
  setTemplateBody: Dispatch<SetStateAction<string>>;
}

function TextEmailTemplates({ setTemplateBody }: TemplateProps) {
  const [view, setView] = useState("web");
  const [attributes, setAttributes] = useState<string[]>([]);
  const quillRef = useRef<InstanceType<typeof ReactQuill> | null>(null);
  const disclosure = useDisclosure();
  const setVariable = (e: string) => {
    const cursorPos = quillRef?.current?.getEditor()?.getSelection()?.index;
    if (cursorPos == null) return;
    quillRef?.current?.getEditor()?.insertText(cursorPos, e);
  };
  const toast = useToast();
  const contactAttributesQuery = useQuery({
    queryKey: ["get_contact_attributes2"],
    queryFn: getContactAttributes,
    retry: 3,

    onSuccess: (data) => {
      setAttributes(data.data);
      // setRawAttributes(data.data);
      // setTiptapValue((prev) => ({
      //   ...prev,
      //   rawAttributes: data.data,
      // }));
      // let arr: { front: string; back: string }[] = [];
      // for (let i = 0; i < data?.data.length; i++) {
      //   let str3 = data.data[i];
      //   let str2 = removeUnderscores(data.data[i]);
      //   let str = removeSpacesAndJoin(str2);
      //   arr.push({ front: str, back: str3 });
      // }
      // // setAttributes(arr);
      // setTiptapValue((prev) => ({
      //   ...prev,
      //   attributes: arr,
      // }));
      // setIsReady(true);
      // // console.log("doneeeeeee", attributes);
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
      // setIsReady(true);
    },
  });
  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} w="full" h="full" spacing={8}>
      {/* Message editor */}
      <Box
        display={"flex"}
        borderWidth={"1px"}
        position="relative"
        borderColor={"borders.500"}
      >
        <Box
          className="textbox-attribute"
          position={"absolute"}
          zIndex={2}
          top={`100px`}
          left={"-48px"}
        >
          <Tooltip
            label="Add an attribute"
            aria-label="Add an attribute dropdown"
            openDelay={500}
            closeDelay={350}
            fontSize={"12px"}
          >
            <Box>
              <ButtonDropdown
                isAttr
                menuButtonProps={{
                  bg: "#197eef1f ",
                  color: "#111",
                  fontWeight: 700,
                  fontSize: "22px",
                  py: 5,
                  pr: 1,
                  pl: 3,
                  borderRadius: "full",
                  boxShadow: "sm",
                  _hover: {
                    bg: "gray.200",
                  },
                  _active: {
                    bg: "gray.200",
                  },
                }}
                title="@"
                list={attributes.map((x) => {
                  return {
                    option: removeUnderscores(x),
                    onClick: () => {
                      setVariable(`{{${x}}}`);
                    },
                  };
                })}
                menuItemProps={{
                  _hover: {
                    bg: "gray.200",
                  },
                }}
              />
            </Box>
          </Tooltip>
        </Box>

        <ReactQuill
          style={{
            width: "100%",
            // height: "603px",
            // maxHeight: "603px",
            minHeight: "300px",
            marginTop: "0px",
          }}
          modules={modules}
          formats={formats}
          theme="snow"
          ref={quillRef}
          onChange={setTemplateBody}
        />
      </Box>

      <Box
        borderWidth={"1px"}
        borderColor={"borders.500"}
        className="previewBox"
      >
        <Flex
          justifyContent={"space-between"}
          borderBottomWidth={"1px"}
          borderColor={"borders.500"}
        >
          <HStack mx={3} p={2}>
            <Box display={"flex"}>
              <Box mx={1}>
                <ColorPink />
              </Box>
              <Box mx={1}>
                <ColorYellow />
              </Box>
              <Box mx={1}>
                <ColorGreen />
              </Box>
            </Box>
            <Box fontWeight={700}>Preview</Box>
          </HStack>
        </Flex>
        <Box bg={"borders.200"} minH={"200px"} mx={6} mt={5} py={10} px={12}>
          <Box
            bg="white"
            pt={5}
            m="auto"
            maxW={view === "mobile" ? "280px" : "500px"}
          >
            <Text fontSize={"12px"} textAlign={"center"} fontWeight={700}>
              LOGO
            </Text>
            <Box
              w="full"
              pt={2}
              pb={8}
              minH="380px"
              fontSize="10px"
              position="relative"
            >
              <ReactQuill
                style={{ border: "1rem solid #fff" }}
                modules={displayModule}
                theme="snow"
                // value={currentStageData?.data?.message_body}
                readOnly={true}
              />
              <Text
                textAlign={"center"}
                bottom={6}
                color={"gray.300"}
                position="absolute"
                right={0}
                left={0}
                fontSize="10px"
              >
                Sent using Bodsquare by GO54
              </Text>
            </Box>
          </Box>
        </Box>
        <Center flexDir={"row"} textAlign="center" pb={3}>
          <Flex>
            <Box>
              <Text
                mt="50px"
                mb="5px"
                fontWeight={700}
                mx={2}
                fontSize={"12px"}
                w={"72px"}
              >
                Preview as:
              </Text>
            </Box>
            <Button
              onClick={() => setView("web")}
              mt="40px"
              mb="10px"
              mx={2}
              w="full"
              maxW={"96px"}
              borderWidth={"1px"}
              color={view === "mobile" ? "borders.400" : "primary.500"}
              bg={"backgrounds.300"}
              borderColor={view === "mobile" ? "borders.400" : "primary.500"}
            >
              Web
            </Button>
            <Button
              onClick={() => setView("mobile")}
              mt="40px"
              mb="10px"
              mx={2}
              w="full"
              maxW={"96px"}
              borderWidth={"1px"}
              color={view === "web" ? "borders.400" : "primary.500"}
              bg={"backgrounds.300"}
              borderColor={view === "web" ? "borders.400" : "primary.500"}
            >
              Mobile
            </Button>
            {/* <Button
              mx={2}
              isDisabled={!currentStageData?.data?.message_body}
              onClick={() => {
                setCurrentStageData((prevData: any) => {
                  return {
                    ...prevData,
                    currentStage: "Recipients",
                  };
                });
              }}
              mt="40px"
              mb="10px"
              w="full"
              maxW={"238px"}
              colorScheme={"primary"}
            >
              Continue to recipients
            </Button> */}
          </Flex>
        </Center>
      </Box>
    </SimpleGrid>
  );
}

export default TextEmailTemplates;
