export const styles = {
  global: {
    ".react-datepicker__input-container input": {
      fontSize: "xs",
      color: "grays.9",
      bg: "white",
      borderWidth: "1px",
      borderColor: "#D2D2D4",
      borderRadius: "4px",
      maxH: "40px",
      padding: "12px",
      w: "162px",
      fontWeight: "{400}",
    },
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      bg: "#F2F4F7",
      minHeight: "100vh",
      overflowX: "hidden",
    },
    "h1, h2, h3, h4, h5": {
      fontFamily: "Satoshi, sans-serif",
    },
    a: {
      textDecoration: "none !important",
      _hover: {
        opacity: ".8",
        // textDecoration: 'underline',
      },
    },
    ".hideSB": {
      overflowY: "scroll",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
    },
    ".hideSB::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    // ".thinSB::-webkit-scrollbar": {
    //   width: "9px",
    //   height: "7px",
    // },
    // ".thinSB::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#7489AA30",
    //   // outline: "1px solid #7489AA",
    //   borderRadius: "5px",
    // },
    // ".thinSB::-webkit-scrollbar-track": {
    //   // boxShadow: "inset 0 0 4px rgba(0,0,0,0.2)",
    //   // backgroundColor: "#00000010",
    // },
    // ".thinSB": {
    //   scrollbarColor: "rgb(210,210,210, 0) rgb(46,54,69 0.5)",
    //   scrollbarWidth: "thin",
    // },
    ".thinSB::-webkit-scrollbar": {
      width: "9px",
      height: "7px",
    },
    ".thinSB::-webkit-scrollbar-thumb": {
      backgroundColor: "#B8B9C2",
      // outline: "1px solid #7489AA",
      borderRadius: "1px",
    },
    ".thinSB::-webkit-scrollbar-track": {
      backgroundColor: "#E7E8EB",
    },
    ".thinSB": {
      scrollbarColor: "#EDEEF2 #FAFCFF",
      scrollbarWidth: "thin",
    },
    ".react-datetime-picker__wrapper": {
      color: "red",
      border: "0px",
    },
    ".search-emoji": {
      display: "none",
    },
    ".react-tel-input": {
      _focusWithin: {
        rounded: "8px",
        borderColor: "#3182ce",
        boxShadow: "0 0 0 1px #3182ce",
      },
    },
    ".ql-container.ql-snow": {
      border: "none !important",
    },
    ".templates": {
      // paddingTop: "5px !important",
      // overflowY: "scroll",
    },
    ".templates > p": {
      // overflowY: "scroll",
    },
    ".ql-editor": {
      // paddingTop: "80px",
    },
    ".ql-toolbar.ql-snow": {
      border: "none !important",
    },
    ".previewBox .ql-editor": {
      paddingTop: "0px",
    },
  },
};
