import React, { FC, useMemo } from 'react';

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
} from '@chakra-ui/react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import SortArrowUp from '../../../icons/SortArrowUp';
import SortArrowDown from '../../../icons/SortArrowDown';
import { ButtonPrimary } from '../../UI/Buttons';

export interface senderIdListProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  status: string;
  created_at: Date | string;
  updated_at: Date | string;
}

const SenderIDListTable: FC<{ listsData: senderIdListProps[] }> = ({
  listsData,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<senderIdListProps>[]>(() => {
    const statusColorsToUse = (status: string) => {
      switch (status) {
        case 'approved':
          return {
            color: '#159D6F',
            bg: 'rgba(21, 157, 111, 0.04)',
          };
        case 'pending':
          return {
            color: 'rgb(220, 168, 10)',
            bg: 'rgba(220, 168, 10, 0.04)',
          };

        default:
      }
    };

    
    return [
      {
        header: () => <Text>Sender name</Text>,
        accessorKey: 'name',
        id: 'name',
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: () => <Text>Sender email</Text>,
        accessorKey: 'email',
        id: 'email',
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        id: 'status',
        cell: (props: any) => {
          return (
            <Box
              bg={statusColorsToUse(props?.getValue())?.bg}
              w='fit-content'
              py='4px'
              px='8px'
              rounded='full'
              borderWidth={'0.5px'}
              borderColor={statusColorsToUse(props?.getValue())?.color}
              color={statusColorsToUse(props?.getValue())?.color}
              textTransform='capitalize'
              fontWeight={500}
            >
              {props?.getValue()}
            </Box>
          );
        },
      },
      {
        header: () => <Text>Action</Text>,
        accessorKey: 'name',
        id: 'name',
        cell: (props: any) => {
          return <ButtonPrimary>Delete</ButtonPrimary>;
        },
      },
    ];
  }, []);

  const data = listsData;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <TableContainer
        borderWidth={'1px'}
        borderColor='borders.200'
        rounded={'8px'}
      >
        <Table variant={'simple'}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform='none'
                        color={'grays.12'}
                        fontSize='xs'
                        letterSpacing={'unset'}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={'flex'}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing='2px' my='auto' ml='2'>
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width='8px' />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width='8px' />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize='xs'
                    color='grays.12'
                    fontWeight={'medium'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SenderIDListTable;
