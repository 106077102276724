import API from "../.";

// =========================== get Templates ==============================================

export const getSingleTemplate = async (uid: string, channel: string) => {
  if (channel === "Email") {
    const responseEmail = await API.get(`/campaign/email/templates/${uid}`);
    return responseEmail.data.data;
  }
  if (channel === "SMS") {
    const responseSMS = await API.get(`/campaign/sms/templates/${uid}`);
    return responseSMS.data.data;
  }
};

export const getTemplates = async (page?: number, limit?: number) => {
  const responseWhatsapp = await API.get(
    `campaign/email/templates/?page=${page}&limit=${limit}`
  );
  const responseSMS = await API.get(`campaign/sms/templates`);
  return {
    whatsappTemplates: responseWhatsapp.data.data,
    smsTemplates: responseSMS.data.data,
  };
};
export const getApprovedTemplates = async () => {
  // const response = await API.get(`/whatsapp/templates`);
  const response = await API.get(`/whatsapp/templates/?status=approved`);
  return response.data.data;
};

// =========================== create Template ==============================================

export const createTemplates = async (data: {
  channel: string;
  name: string;
  template_body: any;
  language?: string;
  template_subject?: string | null;
  template_type?: string;
  template_data?:{}[];
}) => {
  if (data.channel === "Email") {
    const response = await API.post(`/campaign/email/templates/`, {
      name: data.name,
      template_subject: data.template_subject,
      template_body: data.template_body,
      template_type: data.template_type,
      template_data: data.template_data
    });

    return response.data;
  }

  if (data.channel === "SMS") {
    const response = await API.post(`/campaign/sms/templates`, {
      name: data.name,
      template_body: data.template_body,
    });
    return response.data;
  }
};

export const updateTemplate = async (data: {
  channel: string;
  uid: string;
  name: string;
  template_body: any;
  template_data?: {}[];
}) => {
  if (data.channel === "Email") {
    const response = await API.patch(`campaign/email/templates/${data.uid}`, {
      name: data.name,
      template_body: data.template_body,
      template_data: data.template_data
    });

    return response.data;
  }

  if (data.channel === "SMS") {
    const response = await API.patch(`/campaign/sms/templates/${data.uid}`, {
      name: data.name,
      template_body: data.template_body,
    });
    return response.data;
  }
};

export const deleteTemplate = async (data: {
  uid: string;
  channel: string;
}) => {
  if (data.channel === "SMS") {
    const response = await API.delete(`/campaign/sms/templates/${data.uid}`);
    return response.data;
  } else {
    const response = await API.delete(`campaign/email/templates/${data.uid}`);
    return response.data;
  }
};
