import React, { useRef, useState } from "react";
import SelectTemplate from "./EmailVisualBuilderComp/SelectTemplate";
import EditTemplate from "./EmailVisualBuilderComp/EditTemplate";

interface EmailCampaignProps {
  currentStageData: any;
  setCurrentStageData: any;
  disclosure: any;
}

function EmailCampaignVisualBuilder({currentStageData,setCurrentStageData,disclosure}:EmailCampaignProps) {
  const [selected, setSelected] = useState(false);
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const emailEditorRef = useRef<any>(null);

  return (
    <div>
      {selected ? (
        <EditTemplate
          setHtmlContent={setHtmlContent}
          htmlContent={htmlContent}
          emailEditorRef={emailEditorRef}
          selectedTemplate={selectedTemplate}
          disclosure={disclosure}
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
        />
      ) : (
        <SelectTemplate
          setSelected={setSelected}
          emailEditorRef={emailEditorRef}
          setHtmlContent={setHtmlContent}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
    </div>
  );
}

export default EmailCampaignVisualBuilder;
