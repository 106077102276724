import {
  Box,
  Text,
  Button,
  Flex,
  CheckboxGroup,
  Stack,
  Checkbox,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { getListData } from "../../../API/account/list";
import { importContacts } from "../../../API/contacts";
import { importContactsAtom } from "../../../atoms/contacts";
import { toaster } from "../../../utils/toast";
import AddNewList from "../../account/lists/AddNewList";
import CustomModal from "../../UI/CustomModal";
// import SearchableSelect from '../../UI/form/SearchableSelect';
import SearchableSelect2 from "../../UI/form/SS2";

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
}

const FinalStage = () => {
  const [importContactsData, setImportContactsData] =
    useRecoilState(importContactsAtom);

  const addListDisclosure = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data } = useQuery(["listData"], getListData);
  // const [items, setItems] = useState(data || [])
  const allLists = useMemo(() => {
    return data?.map((list: listProps) => {
      return {
        label: list?.name,
        value: list?.uid,
      };
    });
  }, [data]);

  const { mutate, isLoading } = useMutation(importContacts, {
    onSuccess() {
      // const success = { ...data };
      // toaster(toast, success);
      toast({
        title: "Upload processing, you will receive an email notification",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["allContacts"]);
      setImportContactsData((prev) => {
        return {
          ...prev,
          currentStage: "success",
        };
      });
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);


  // const [selectedOption, setSelectedOption] = useState(null);
  // console.log("show meeeee",(importContactsData?.criteria));

  return (
    <Flex flexDir={"column"} w="full" maxW={"328px"} mx="auto">
      {/* header */}
      <Box textAlign={"center"} mx="auto" maxWidth="375px">
        <Text fontSize={"20px"} fontWeight={700} color="grays.8">
          Assigning list & final agreement
        </Text>
        <Text color={"grays.12"} fontSize="xs" mt="8px">
          Select a list you’d like to add the contacts to.
        </Text>
      </Box>

      {/*  */}
      <Box mt="32px" w="full">
        <Flex flexDirection={"column"}>
          <SearchableSelect2
            // list = {true}
            label="List"
            placeholder="Search list option"
            options={allLists}
            onChange={(e: { label: string; value: string }) => {
              setImportContactsData((prev) => {
                return {
                  ...prev,
                  list_uid: e.value,
                };
              });
              if (e?.value === "new") {
                // Open create list modal
                addListDisclosure.onOpen();
                // setItems([...items, data.length -1])
              }
            }}
          />

          {/* Add list button */}
          <Button
            size="xs"
            variant={"ghost"}
            colorScheme="primary"
            mt="3px"
            ml="auto"
            onClick={addListDisclosure.onOpen}
          >
            + Add list
          </Button>
        </Flex>

        {/* Agreement terms */}
        <Box mt="40px" w="full">
          <Text fontSize={"sm"} fontWeight={700} textAlign="center" mb="20px">
            Agreement
          </Text>

          <CheckboxGroup colorScheme="primary">
            <Stack spacing={"20px"} direction={"column"}>
              <Checkbox
                isChecked={agree1}
                onChange={(e) => {
                  setAgree1(e.target.checked);
                }}
              >
                <Text fontSize="xs" color={"grays.12"} fontWeight={500}>
                  By uploading, you agree that all contacts in this import are
                  expecting to hear from me or my organisation.
                </Text>
              </Checkbox>
              <Checkbox
                isChecked={agree2}
                onChange={(e) => {
                  setAgree2(e.target.checked);
                }}
              >
                <Text fontSize="xs" color={"grays.12"} fontWeight={500}>
                  I have a prior relationship with these contacts and I have
                  emailed or messaged them at least once in the past year.
                </Text>
              </Checkbox>
              <Checkbox
                isChecked={agree3}
                onChange={(e) => {
                  setAgree3(e.target.checked);
                }}
              >
                <Text fontSize="xs" color={"grays.12"} fontWeight={500}>
                  I can confirm that this list wasn't purchased, rented,
                  appended, or provided by a third party.
                </Text>
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </Box>

        <Button
          w="full"
          maxW="360px"
          mx="auto"
          mt="40px"
          mb="10px"
          colorScheme={"primary"}
          isLoading={isLoading}
          isDisabled={
            !agree1 || !agree2 || !agree3 || !importContactsData?.list_uid
          }
          onClick={() => {
            const formData = new FormData();
            formData.append("file", importContactsData?.file);
            formData.append("list_uid", importContactsData?.list_uid);
            formData.append(
              "map",
              JSON.stringify(importContactsData?.csvDataToUse)
            );
            formData.append("criteria", importContactsData?.criteria);

            mutate(formData);
          }}
        >
          Finish
        </Button>
      </Box>

      <CustomModal
        disclosure={addListDisclosure}
        titleUnderLine={true}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Create new list
          </Text>
        }
      >
        <AddNewList close={addListDisclosure.onClose} />
      </CustomModal>
    </Flex>
  );
};

export default FinalStage;
