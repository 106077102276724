import {
  Box,
  // HStack,
  Button,
  SimpleGrid,
  Text,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getPlansData,
  getCards,
  getCurrentPlan,
} from "../../../API/account/billings";
import { useState } from "react";
import CustomModal from "../../../components/UI/CustomModal";
import SelectUpgradePaymentMethod from "../../../components/account/billings/SelectUpgradePaymentMethod";
import Loader from "../../../components/UI/Loader";
import AddCardModal from "../../../components/account/billings/AddCardModal";
import { GoBack } from "../../../components/UI/Buttons";

const BillingPlan = () => {
  const disclosure = useDisclosure();
  const addNewCardDisclosure = useDisclosure();
  const [title, setTitle] = useState("Upgrade Your Plan");
  const [planId, setPlanId] = useState("");
  const [amount, setAmount] = useState(0);

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(["listPlans"], getPlansData);
  const { data: cardList } = useQuery(["getCards"], getCards);

  const upgrade = (id: string) => {
    // cardList?.length > 0 ? disclosure.onOpen() : addNewCardDisclosure.onOpen();
    disclosure.onOpen();
    setPlanId(id);
    // setAmount(amount);
  };

  const openSelectCardModal = () => {
    disclosure.onOpen();
    queryClient.invalidateQueries(["getCards"]);
  };
  const dataPlans = [
    {
      id: 1,
      uid: "64a4e4d5-7ff3-48fc-8eaf-b7a232374f44",
      name: "Free",
      amount: "0",
      created_at: "2022-11-21T15:59:35.000Z",
      updated_at: "2022-11-21T15:59:35.000Z",
      details: {
        name: "free",
        amount: 0,
        "Allowed Users": "3",
        Description: "For Individuals",
        core: {
          "Monthly Mail": "7000",
          "Daily Send Limit": "200",
          Contacts: "2500",
          "Facebook Messenger": true,
          "Instagram Messenger": true,
          "SMS & Email Campaign": true,
          "Access to basic reports": true,
          "Basic Customer Support": true,
        },
        advanced: {
          "Twitter Support": false,
          "Additional Users": "Up to 3",
          "Access to advanced reports": false,
          "Priority Support": false,
        },
      },
    },
    {
      id: 2,
      uid: "d41e4ad0-fa13-41be-9910-14aa756f7f59",
      name: "Basic",
      amount: "15",
      created_at: "2022-11-21T15:59:35.000Z",
      updated_at: "2022-11-21T15:59:35.000Z",
      details: {
        name: "basic",
        amount: 15,
        "Allowed Users": "Unlimited",
        Description: "For Teams",
        core: {
          "Monthly Mail": "50,000",
          "Daily Send Limit": "Unlimited",
          Contacts: "Unlimited",
          "Facebook Messenger": true,
          "Instagram Messenger": true,
          "SMS & Email Campaign": true,
          "Access to basic reports": true,
          "Basic Customer Support": true,
        },
        advanced: {
          "Twitter Support": true,
          "Additional Users": "Unlimited",
          "Access to advanced reports": true,
          "Priority Support": true,
        },
      },
    },
    {
      id: 3,
      uid: "d41e4ad0-fa13-41be-9910-14aa756f7f59",
      name: "Pro",
      amount: "30",
      created_at: "2022-11-21T15:59:35.000Z",
      updated_at: "2022-11-21T15:59:35.000Z",
      details: {
        name: "basic",
        amount: 15,
        "Allowed Users": "Unlimited",
        Description: "For Teams",
        core: {
          "Monthly Mail": "150,000",
          "Daily Send Limit": "Unlimited",
          Contacts: "Unlimited",
          "Facebook Messenger": true,
          "Instagram Messenger": true,
          "SMS & Email Campaign": true,
          "Access to basic reports": true,
          "Basic Customer Support": true,
        },
        advanced: {
          "Twitter Support": true,
          "Additional Users": "Unlimited",
          "Access to advanced reports": true,
          "Priority Support": true,
        },
      },
    },
    {
      id: 4,
      uid: "d41e4ad0-fa13-41be-9910-14aa756f7f59",
      name: "Enterprise",
      amount: "",
      created_at: "2022-11-21T15:59:35.000Z",
      updated_at: "2022-11-21T15:59:35.000Z",
      details: {
        name: "Enterprise",
        amount: "",
        "Allowed Users": "Unlimited",
        Description: "For Businesses",
        core: {
          "Monthly Mail": "300,000+",
          "Daily Send Limit": "Unlimited",
          Contacts: "Unlimited",
          "Facebook Messenger": true,
          "Instagram Messenger": true,
          "SMS & Email Campaign": true,
          "Access to basic reports": true,
          "Basic Customer Support": true,
        },
        advanced: {
          "Twitter Support": true,
          "Additional Users": "Unlimited",
          "Access to advanced reports": true,
          "Priority Support": true,
        },
      },
    },
  ];
  const [sliderValue, setSliderValue] = useState(5);
  const [showTooltip, setShowTooltip] = useState(false);
  const { data: currentPlan } = useQuery(["currentPlan"], getCurrentPlan);

  console.log(data);

  const boxColorsToUse = (value: number) => {
    // switch (value) {
    //   case 20:
    //     return {
    //       color: '#159D6F',
    //       bg: 'red',
    //     };
    //   default:
    // }
    if (value === 7 && sliderValue > 5 && sliderValue <= 20) {
      return {
        color: "white",
        border: "#0066FF",
        shadow: "lg",
        btncolor: "black",
      };
    } else if (value === 8 && sliderValue > 20 && sliderValue <= 40) {
      return {
        color: "white",
        border: "#0066FF",
        shadow: "lg",
        btncolor: "black",
      };
    } else if (value === 9 && sliderValue > 40 && sliderValue <= 60) {
      return {
        color: "white",
        border: "#0066FF",
        shadow: "lg",
        btncolor: "black",
      };
    } else if (value === 10 && sliderValue > 60) {
      return {
        color: "white",
        border: "#0066FF",
        shadow: "lg",
        btncolor: "black",
      };
    }
  };
  console.log("currentPlan", currentPlan);
  return (
    <Box w="full" h="full">
      <GoBack>Back to Billings</GoBack>

      {/* Table header */}
      <Box mt="20px">
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Plans
        </Text>
        <Text color={"grays.12"} fontSize={"12px"}>
          You’re currently on the{" "}
          <Text as="span" color={"grays.8"} fontWeight={700}>
            {currentPlan?.plan?.name?.toLowerCase() || "free"} Plan
          </Text>
        </Text>
      </Box>
      <Box w={"674px"} margin={"auto"} my={5}>
        <Text
          color={"#171D26"}
          fontWeight={700}
          fontSize={16}
          textAlign={"center"}
        >
          How many emails do you want to send per month?
        </Text>
        <Slider
          id="slider"
          defaultValue={5}
          min={0}
          max={100}
          colorScheme="primary"
          onChange={(v) => setSliderValue(v)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderMark value={15} mt="1" ml="-2.5" fontSize="sm">
            7,000
          </SliderMark>
          <SliderMark value={35} mt="1" ml="-2.5" fontSize="sm">
            50,000
          </SliderMark>
          <SliderMark value={55} mt="1" ml="-2.5" fontSize="sm">
            150,000
          </SliderMark>
          <SliderMark value={75} mt="1" ml="-2.5" fontSize="sm">
            300,000
          </SliderMark>
          <SliderMark value={90} mt="1" ml="-2.5" fontSize="sm">
            More
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg="#0066FF"
            color="white"
            placement="top"
            isOpen={showTooltip}
            label={`${sliderValue}%`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </Box>

      {isLoading ? (
        <Loader />
      ) : !isLoading && data?.length > 0 ? (
        <Box mt="32px">
          <SimpleGrid columns={5}>
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px"}
              position="relative"
            >
              <Text
                position="absolute"
                bottom="0"
                fontSize={"14px"}
                fontWeight={"700"}
              >
                Core
              </Text>
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor={boxColorsToUse(x.id)?.border}
                shadow={boxColorsToUse(x.id)?.shadow}
                borderWidth="1px"
                p={"20px"}
              >
                <Text
                  textTransform={"capitalize"}
                  fontSize={"12px"}
                  color="grays.9"
                  fontWeight="700"
                >
                  {x?.name}
                </Text>
                <Text fontSize={"12px"} color="grays.12" fontWeight="500">
                  {x?.details.Description}
                </Text>
                {x.name !== "Enterprise" ? (
                  <Text fontSize={"32px"} color="grays.9" fontWeight="700">
                    ${x.amount}
                  </Text>
                ) : (<Box h={12}>

                </Box>)}

                <Box h="60px" mt={2}>
                  {currentPlan?.plan?.id < x?.id && x?.name !== "Enterprise" ? (
                    <Button
                      mb={"15px"}
                      colorScheme={"primary"}
                      size="xs"
                      h="32px"
                      w="88px"
                      onClick={() => {
                        // const plan_number=x.id;
                        // const plan_string= plan_number.toString()
                        upgrade(x.id.toString());
                      }}
                      // border={sliderValue  }
                    >
                      Upgrade
                    </Button>
                  ) : x.name === "Enterprise" ? (
                    <Link
                      href="https://support.go54.com/ticket/create"
                      isExternal
                    >
                      <Button
                        colorScheme={"primary"}
                        size="xs"
                        h="32px"
                        w="88px"
                        onClick={() => {
                        }}
                        // border={sliderValue  }
                      >
                        Get a Quote
                      </Button>
                    </Link>
                  ) : null}
                </Box>
                <Text fontSize={"12px"} color="grays.12" fontWeight="500">
                  {x.details.maximum_users} users
                </Text>
              </Box>
            ))}
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px 20px 60px 20px"}
              position="relative"
            >
              {Object.keys(data[0]?.details.core).map((key) => (
                <Text
                  key={key}
                  fontSize={"12px"}
                  color="grays.12"
                  fontWeight="500"
                  my={2}
                >
                  {key}
                </Text>
              ))}
              {/* <Text
                position="absolute"
                bottom="0"
                fontSize={"14px"}
                fontWeight={"700"}
              >
                Advanced
              </Text> */}
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor="borders.600"
                borderWidth="1px"
                p={"20px"}
                
              >
                {Object.values<string | boolean>(x.details.core).map(
                  (text, i) => (
                    <Text
                      key={i}
                      my={"10px"}
                      fontSize={"12px"}
                      color="grays.8"
                      fontWeight="500"
                    >
                      {typeof text === "number"
                        ? text
                        : typeof text === "string"
                        ? text
                        : text === true
                        ? "✓"
                        : "-"}
                    </Text>
                  )
                )}
              </Box>
            ))}
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px"}
            >
              {/* {Object.keys(data[0].details.advanced).map((key, i) => (
                <Text
                  key={i}
                  fontSize={"12px"}
                  color="grays.12"
                  fontWeight="500"
                  my={2}
                >
                  {key}
                </Text>
              ))} */}
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor="borders.600"
                borderWidth="1px"
                p={"20px"}
              >
                {/* {Object.values<string | boolean>(x.details.advanced).map(
                  (text, i) => (
                    <Text
                      key={i}
                      my={"8px"}
                      fontSize={"12px"}
                      color="grays.8"
                      fontWeight="500"
                    >
                      {typeof text === "string"
                        ? text
                        : text === true
                        ? "✓"
                        : "—"}
                    </Text>
                  )
                )} */}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      ) : null}

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" pr="38px">
            {title}
          </Text>
        }
      >
        <SelectUpgradePaymentMethod
          close={disclosure.onClose}
          setTitle={setTitle}
          planId={planId}
          amount={amount}
          addNewCardDisclosure={addNewCardDisclosure}
        />
      </CustomModal>
      <AddCardModal
        disclosure={addNewCardDisclosure}
        onAddCardSuccess={openSelectCardModal}
      />
    </Box>
  );
};

export default BillingPlan;
