import React, { FC } from "react";

export const InfoCircleIcon: FC<any> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.0026 14.6654C11.6693 14.6654 14.6693 11.6654 14.6693 7.9987C14.6693 4.33203 11.6693 1.33203 8.0026 1.33203C4.33594 1.33203 1.33594 4.33203 1.33594 7.9987C1.33594 11.6654 4.33594 14.6654 8.0026 14.6654Z"
        stroke="#6682A2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.63541 9.78566C7.60985 9.78566 7.58913 9.76494 7.58913 9.73938C7.59427 9.24824 7.6457 8.85738 7.74341 8.56682C7.84113 8.27625 7.97998 8.04096 8.15998 7.86097C8.33998 7.68097 8.55598 7.51511 8.80797 7.3634C8.95969 7.27083 9.09597 7.16154 9.21683 7.03554C9.33768 6.90697 9.43283 6.75912 9.50225 6.59198C9.57425 6.42484 9.61025 6.23969 9.61025 6.03655C9.61025 5.78456 9.55111 5.56599 9.43283 5.38084C9.31454 5.1957 9.1564 5.05299 8.9584 4.95271C8.7604 4.85242 8.54055 4.80228 8.29884 4.80228C8.08798 4.80228 7.88484 4.84599 7.68941 4.93342C7.49399 5.02085 7.3307 5.15842 7.19956 5.34613C7.1407 5.43038 7.09298 5.52627 7.0564 5.63383C6.97335 5.87797 6.77174 6.08284 6.51386 6.08284C6.23406 6.08284 6.0043 5.84632 6.07248 5.57495C6.12942 5.34831 6.21841 5.14346 6.33943 4.96042C6.54771 4.64928 6.82156 4.41142 7.16099 4.24685C7.50299 4.08228 7.88227 4 8.29884 4C8.7514 4 9.14483 4.09 9.47911 4.27C9.81597 4.45 10.0757 4.69685 10.2582 5.01056C10.4434 5.32427 10.536 5.6817 10.536 6.08284C10.536 6.36569 10.4922 6.62155 10.4048 6.8504C10.32 7.07926 10.1965 7.28368 10.0345 7.46368C9.87511 7.64368 9.68225 7.80311 9.45597 7.94196C9.22969 8.08339 9.0484 8.23253 8.91212 8.38939C8.77583 8.54367 8.67683 8.72753 8.61512 8.94096C8.55341 9.15438 8.51998 9.42052 8.51483 9.73938C8.51483 9.76494 8.49411 9.78566 8.46855 9.78566H7.63541ZM8.08284 12.0691C7.89255 12.0691 7.72927 12.0009 7.59299 11.8646C7.4567 11.7284 7.38856 11.5651 7.38856 11.3748C7.38856 11.1845 7.4567 11.0212 7.59299 10.8849C7.72927 10.7487 7.89255 10.6805 8.08284 10.6805C8.27312 10.6805 8.43641 10.7487 8.57269 10.8849C8.70898 11.0212 8.77712 11.1845 8.77712 11.3748C8.77712 11.5008 8.74498 11.6165 8.68069 11.7219C8.61898 11.8274 8.53541 11.9122 8.42998 11.9765C8.32712 12.0382 8.21141 12.0691 8.08284 12.0691Z"
        fill="#6682A2"
      />
    </svg>
  );
};
