import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import EmailEditor from "react-email-editor";
import { template1 } from "./Templates";
import FormInput from "../../UI/form/FormInput";
import CustomModal from "../../UI/CustomModal";
export interface ExportedData {
  design: object;
  html: string;
}
interface EditTemplateProps {
  htmlContent: string;
  setHtmlContent: Dispatch<SetStateAction<string>>;
  emailEditorRef: MutableRefObject<any>;
  selectedTemplate: number | null;
  disclosure: any;
  currentStageData: any;
  setCurrentStageData: any;
}
interface Design {
  body: object;
}
const EditTemplate = ({
  htmlContent,
  setHtmlContent,
  emailEditorRef,
  selectedTemplate,
  disclosure,
  setCurrentStageData,
  currentStageData,
}: EditTemplateProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [templateBody, setTemplateBody] = useState('')

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data:any) => {
      const { design, html } = data;
      console.log('exportHtml', html);
      setTemplateBody(html)
      console.log("design",design)
    });
  };

  const exportAndPreview = () => {
    emailEditorRef.current?.editor.exportHtml((data: ExportedData) => {
      const { html } = data;
      setHtmlContent(html);
      //   console.log(html);
      onOpen();
    });
    emailEditorRef.current?.editor.saveDesign((design: Design) => {
      console.log("Design saved:", design);
      // Optionally, send the design to a server or store it in a database
    });
  };
  
  const onLoad = () => {
    emailEditorRef.current.editor.loadDesign(template1);
    
  };

  // useEffect(() => {
  //   console.log("fired", emailEditorRef.current, selectedTemplate);
  //   if (emailEditorRef.current?.editor && selectedTemplate !== 0) {
  //     const template = selectedTemplate === 1 ? template1 : template1;
  //     emailEditorRef.current?.editor?.loadDesign(template);
  //   }
  // }, [emailEditorRef, selectedTemplate, emailEditorRef.current?.editor]);

  // const onReady = () => {
  //   // Load the legacy HTML template
  //   emailEditorRef.current.editor.loadDesign({
  //     html: '<html><body><div>This is a legacy HTML template.</div></body></html>',
  //     classic: true
  //   });
  // };

  //  // Replace 'yourTemplateJson' with your actual template JSON object
  //  const yourTemplateJson = {
  //   /* ... Your template JSON ... */
  // };

  // const onReady = () => {
  //   // Load the template into the editor
  //   emailEditorRef.current.editor.loadDesign(yourTemplateJson);
  // };

  return (
    <Box minHeight={"100vh"}>
      <Box display={"flex"} justifyContent={"end"} my={"2"}>
        <Box display={"flex"} justifyContent={"space-between"} width={"full"}>
          <Box>
            <Text fontWeight={700} fontSize={20}>
              Edit Template
            </Text>
            <Text color={"#7489AA"} fontSize={12} fontWeight={500}>
              Please select a campaign template below
            </Text>
          </Box>
          <Box>
            <Button
              mx={"2"}
              border={"3px solid #0066FF"}
              color={"#0066FF"}
              bg={"white"}
              rounded={"none"}
              onClick={disclosure.onOpen}
            >
              Send Test Mail
            </Button>
            <CustomModal
              disclosure={disclosure}
              title={
                <Text fontSize={"20px"} color="grays.8" mt="35px" pr="38px">
                  Send a sample mail
                </Text>
              }
            >
              {/* {currentStage === 'entry' && ( */}
              <chakra.form
                onSubmit={(e) => {
                  e.preventDefault();

                  //   mutate({
                  //     amount,
                  //     currency: 'NGN',
                  //   });
                }}
              >
                <Box
                  borderBottomWidth={"1px"}
                  h={"0.1px"}
                  borderColor={"borders.100"}
                ></Box>
                <FormInput
                  label="Enter one or more recipients"
                  type="email"
                  for="amount"
                  formControlProps={{
                    mt: "32px",
                  }}
                  inputProps={{
                    placeholder: "test@hostmail.com, test2@hostmail.com",
                    onChange: (e) => {
                      e.preventDefault();
                      setCurrentStageData((prevData: any) => {
                        return {
                          ...prevData,
                          data: {
                            ...prevData?.data,
                            email: e.target.value,
                          },
                        };
                      });
                    },
                    autoFocus: true,
                  }}
                />
                <Text
                  fontSize={"14px"}
                  fontWeight={700}
                  color="grays.8"
                  mt="5px"
                >
                  Use commas to seperate multiple emails
                </Text>
                <Button
                  // isLoading={isLoading || getLowestLoading}
                  isDisabled={!currentStageData?.data?.email}
                  w="full"
                  colorScheme={"primary"}
                  mt="32px"
                  mb="40px"
                  type="submit"
                  onClick={disclosure.onClose}
                >
                  Send sample
                </Button>
              </chakra.form>
            </CustomModal>
            <Button
              mx={"2"}
              rounded={"none"}
              bg={"#0066FF"}
              color={"white"}
              colorScheme="primary"
              onClick={exportHtml}
            >
              Save Changes
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Email Template Preview</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <iframe
                    title="Email Preview"
                    srcDoc={htmlContent}
                    width="100%"
                    height="500px"
                    style={{ border: "none" }}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Box>
      <Box bg={"#0066FF"} width={"100%"} height={"50px"} textAlign={"center"} color={"white"} p={2}>
        <Text fontSize={"18px"} fontWeight={700}>To add an attribute to your mail , follow the format &#10100;&#10100;.attribute&#10101;&#10101; example to add  first name  &#10100;&#10100;.FirstName&#10101;&#10101;, last name &#10100;&#10100;.FirstName&#10101;&#10101; </Text>
      </Box>
      <EmailEditor
        // onReady={onReady}
        onLoad={onLoad}
        ref={emailEditorRef}
        minHeight={"100vh"}
        options={{
          appearance: {
            panels: {
              tools: {
                dock: "left",
              },
            },
          },
        }}
      />
      <Box my={"2"} display={"flex"} justifyContent={"end"} pr={"10"}>
        <Button
          mx={"2"}
          bg={"#0066FF"}
          color={"white"}
          colorScheme="primary"
          isDisabled={!templateBody}
          onClick={() => {
            setCurrentStageData((prevData: any) => {
              return {
                ...prevData,
                currentStage: "Recipients",
                data: {
                  ...prevData?.data,
                  message_body: templateBody,
                },
              };
            });
            console.log(templateBody)
          }}
        >
          {templateBody ? "Continue to Recipients": "Save changes to continue"}
        </Button>
      </Box>
    </Box>
  );
};

export default EditTemplate;
