import {
  Box,
  Text,
  HStack,
  chakra,
  VStack,
  Button,
  useToast,
  SimpleGrid,
  Flex,
  useDisclosure,
  Heading,
  Center,
  Avatar,
  Radio,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { getData } from "../../../API/data";
import FormInput from "../../../components/UI/form/FormInput";
import SearchableSelect from "../../../components/UI/form/SearchableSelect";
import { createTemplates } from "../../../API/account/templates";
import { toaster } from "../../../utils/toast";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ReadIcon } from "../../../icons/ReadIcon";
import Iphone from "../../../components/UI/Iphone";
import { TiptapBox } from "../../../components/UI/Tiptap";
import { getContactAttributes } from "../../../API/contacts";
import {
  removeHTMLTags,
  removeSpacesAndJoin,
  removeUnderscores,
} from "../../../functions";
import ProgressLoader from "../../../components/UI/ProgressLoader";
import { useRecoilState, useResetRecoilState } from "recoil";
import { tipTapAtom } from "../../../atoms/tiptap";
import SelectTemplate from "../../../components/campaigns/EmailVisualBuilderComp/SelectTemplate";
import EmailCampaignVisualBuilder from "../../../components/campaigns/EmailCampaignVisualBuilder";
import EmailTemplates from "./VisualEmailTemplates";
import { TextBuilderIcon, VisualBuilderIcon } from "../../../icons/Builder";
import TextEmailTemplates from "./TextEmailTemplates";

const displayModule = {
  toolbar: false,
};
// const modules = {
//   toolbar: false
// };

const CreateTemplates = () => {
  const [currChannel, setCurrChannel] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();

  const [isReady, setIsReady] = useState(false);
  const toast = useToast();
  const addLinkDisclosure = useDisclosure();

  useEffect(() => {
    setCurrChannel(params.all!);
  }, [params]);

  const { isLoading: creatingTemplates, mutate } = useMutation(
    createTemplates,
    {
      onSuccess(data) {
        const success = { ...data };
        queryClient.invalidateQueries(["templateData"]);
        toaster(toast, success);
        navigate(`/account/templates/${currChannel}`);
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
      retry: false,
    }
  );

  const [name, setName] = useState<any>("");
  const [language, setLanguage] = useState<any>("");
  const [templateBody, setTemplateBody] = useState<any>();
  const [templateSubject, setTemplateSubject] = useState<any>("");
  const [templateDesign, setTemplateDesign] = useState([{}]);
  const [builder, setBuilder] = useState<any>("");
  // const [plainText, setPlainText] = useState("");

  const [tiptapValue, setTiptapValue] = useRecoilState(tipTapAtom);
  const resetTiptap = useResetRecoilState(tipTapAtom);
  const [selectedBuilder, setSelectedBuilder] = useState<number>(0);

  const { data } = useQuery(["bodData"], getData);
  const languages = useMemo(() => {
    return data?.languages?.map((language) => {
      return {
        label: language,
        value: language,
      };
    });
  }, [data?.languages]);

  useEffect(() => {
    resetTiptap();
  }, [resetTiptap]);

  const contactAttributes = useQuery({
    queryKey: ["get_contact_attributes2"],
    queryFn: getContactAttributes,
    retry: 5,

    onSuccess: (data) => {
      // setRawAttributes(data.data);
      setTiptapValue((prev) => ({
        ...prev,
        rawAttributes: data.data,
      }));
      let arr: { front: string; back: string }[] = [];
      for (let i = 0; i < data?.data.length; i++) {
        let str3 = data.data[i];
        let str2 = removeUnderscores(data.data[i]);
        let str = removeSpacesAndJoin(str2);
        arr.push({ front: str, back: str3 });
      }
      // setAttributes(arr);
      setTiptapValue((prev) => ({
        ...prev,
        attributes: arr,
      }));
      setIsReady(true);
      // console.log("doneeeeeee", attributes);
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
      setIsReady(true);
    },
  });
  if (currChannel === "Email") {
    return (
      <Box>
        <Link to={`/account/templates/${currChannel}`}>
          <HStack spacing={"4px"} ml={"-8px"}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13.5L8.5 10.25L12 7"
                stroke="#7489AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Text fontSize={"xs"} color="grays.12">
              Back to {currChannel} Templates
            </Text>
          </HStack>
        </Link>
        <Heading
          as="h3"
          mt={"20px"}
          mb={"10px"}
          fontSize={"20px"}
          color="grays.9"
          fontWeight={"700"}
        >
          Create Template
        </Heading>
        {selectedBuilder === 0 ? (
          <Box>
            <Text fontSize="sm" color="grays.9" mb="4px">
              Choose Builder Type
            </Text>
            <HStack mt={10}>
              <Box
                position={"relative"}
                borderWidth={"1px"}
                w="190px"
                h="190px"
                borderRadius="4px"
                gap="24px"
                mr={20}
                p="12px"
                borderColor={"borders.100"}
                _hover={{ border: "1px solid blue" }}
                onClick={() => setSelectedBuilder(1)}
                
              >
                <Box display={"flex"} alignItems="center" flexDir={"column"}>
                  <VisualBuilderIcon />
                  <Text fontSize={"10px"} fontWeight={700} pt={2}>
                    VISUAL BUILDER
                  </Text>
                  <Text
                    color={"grays.12"}
                    fontSize="10px"
                    w={"146px"}
                    textAlign="center"
                    pt={1}
                  >
                    Design emails using drag and drop editor to create engaging
                    visual content without touching code
                  </Text>
                </Box>
              </Box>
              <Box
                position={"relative"}
                borderWidth={"1px"}
                w={"190px"}
                h="190px"
                ml={3}
                borderRadius="4px"
                gap="24px"
                p="12px"
                borderColor={"borders.100"}
                _hover={{ border: "1px solid blue" }}
                onClick={() => setSelectedBuilder(2)}
              >
                <Box display={"flex"} alignItems="center" flexDir={"column"}>
                  <TextBuilderIcon />
                  <Text fontSize={"10px"} fontWeight={700} pt={1}>
                    TEXT/HTML BUILDER
                  </Text>
                  <Text
                    color={"grays.12"}
                    fontSize="10px"
                    w={"146px"}
                    textAlign="center"
                    pt={1}
                  >
                    Create emails faster and easier using a text/html editor. No
                    code required.
                  </Text>
                </Box>
              </Box>
            </HStack>
          </Box>
        ) : selectedBuilder === 1 ? (
          <Box>
            <chakra.form
              onSubmit={(e) => {
                e.preventDefault();
                mutate({
                  channel: currChannel,
                  name: name,
                  language: language,
                  template_body: templateBody,
                  template_data: templateDesign,
                  template_subject: templateSubject,
                  template_type: builder,
                });
              }}
              mt="24px"
            >
            <FormInput
                  autoFocus
                  my="25px"
                  w="50%"
                  label="Template name"
                  type="text"
                  for="Template name"
                  inputProps={{
                    placeholder: "Enter Template name",
                    onChange: (e) => {
                      setName(e.target.value);
                      setBuilder("Visual Builder");
                    },
                    value: name,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />
                {/* <FormInput
                  autoFocus
                  my="25px"
                  w="50%"
                  label="Template Subject"
                  type="text"
                  for="Template Subject"
                  inputProps={{
                    placeholder: "Enter Template Subject",
                    onChange: (e) => {
                      setTemplateSubject(e.target.value);
                    },
                    value: templateSubject,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                /> */}
            <EmailTemplates setTemplateBody={setTemplateBody} setTemplateDesign={setTemplateDesign} />
            <Button
              type="submit"
              isLoading={creatingTemplates}
              isDisabled={
                currChannel === "Email"
                  ? !name || !templateBody
                  : !name || !templateBody
              }
              w="full"
              mt={5}
              rounded="4px"
              _hover={{ bg: "primary.500" }}
              bg="primary.500"
              _disabled={{
                bg: "primary.400",
                cursor: "not-allowed",
              }}
              color={"white"}
              fontSize={"sm"}
            >
              Create Template
            </Button>
            </chakra.form>
          </Box>
        ) : (
          <Box>
            <chakra.form
              onSubmit={(e) => {
                e.preventDefault();
                mutate({
                  channel: currChannel,
                  name: name,
                  language: language,
                  template_body: [templateBody],
                  template_subject: templateSubject,
                  template_type: builder,
                });
              }}
              mt="24px"
            >
            <FormInput
                  autoFocus
                  my="25px"
                  w="50%"
                  label="Template name"
                  type="text"
                  for="Template name"
                  inputProps={{
                    placeholder: "Enter Template name",
                    onChange: (e) => {
                      setName(e.target.value);
                      setBuilder('Text Builder')
                    },
                    value: name,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />
                {/* <FormInput
                  autoFocus
                  my="25px"
                  w="50%"
                  label="Template Subject"
                  type="text"
                  for="Template Subject"
                  inputProps={{
                    placeholder: "Enter Template Subject",
                    onChange: (e) => {
                      setTemplateSubject(e.target.value);
                    },
                    value: templateSubject,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                /> */}
            <TextEmailTemplates setTemplateBody={setTemplateBody} />
            <Button
              type="submit"
              isLoading={creatingTemplates}
              isDisabled={
                currChannel === "Email"
                  ? !name || !templateBody
                  : !name || !templateBody
              }
              w="full"
              mt={5}
              rounded="4px"
              _hover={{ bg: "primary.500" }}
              bg="primary.500"
              _disabled={{
                bg: "primary.400",
                cursor: "not-allowed",
              }}
              color={"white"}
              fontSize={"sm"}
            >
              Create Template
            </Button>
            </chakra.form>
          </Box>
        )}

        {/* <EmailTemplates/> */}
      </Box>
    );
  }

  return (
    <>
      {contactAttributes.isLoading ? <ProgressLoader /> : null}
      <Box w="full" h="full">
        <Link to={`/account/templates/${currChannel}`}>
          <HStack spacing={"4px"} ml={"-8px"}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13.5L8.5 10.25L12 7"
                stroke="#7489AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Text fontSize={"xs"} color="grays.12">
              Back to {currChannel} Templates
            </Text>
          </HStack>
        </Link>

        <Heading
          as="h3"
          mt={"20px"}
          mb={"48px"}
          fontSize={"20px"}
          color="grays.9"
          fontWeight={"700"}
        >
          Create Template
        </Heading>

        <Box w="full" mt={"20px"}>
          <Text fontSize={"16px"} color="grays.9" fontWeight={"700"}>
            Template Information
          </Text>
          <>
            <chakra.form
              onSubmit={(e) => {
                e.preventDefault();
                mutate({
                  channel: currChannel,
                  name: name,
                  language: language,
                  template_body: templateBody,
                });
              }}
              mt="24px"
            >
              <VStack
                maxW={"360px"}
                align={"flex-start"}
                spacing="20px"
                mb={currChannel !== "Whatsapp" ? "30px" : "0px"}
              >
                <FormInput
                  autoFocus
                  label="Template name"
                  type="text"
                  for="Template name"
                  inputProps={{
                    placeholder: "Enter Template name",
                    onChange: (e) => {
                      setName(e.target.value);
                    },
                    value: name,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />

                {currChannel === "Whatsapp" ? (
                  <SearchableSelect
                    label="Language"
                    options={languages}
                    value={languages?.filter((lang) => {
                      return lang?.value === language;
                    })}
                    onChange={(selected: { label: string; value: string }) => {
                      setLanguage(selected?.value);
                    }}
                    formControlProps={{
                      isRequired: true,
                    }}
                  />
                ) : null}
              </VStack>

              <SimpleGrid columns={2} spacing={14}>
                <VStack maxWidth={"440px"} width="100%" spacing={"12px"}>
                  <Text
                    alignSelf={"flex-start"}
                    mt={"20px"}
                    mb={"4px"}
                    fontSize="16px"
                    fontWeight={"700"}
                  >
                    Template body
                  </Text>
                  <Box
                    display={"flex"}
                    px={4}
                    alignItems={"center"}
                    width={"100%"}
                    // w={"440px"}
                    // h={"32px"}
                    bg={"backgrounds.500"}
                    borderRadius={"4px"}
                    py={"8px"}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.6673 8.0026C14.6673 11.6853 11.6827 14.6693 8.00065 14.6693C4.31865 14.6693 1.33398 11.6853 1.33398 8.0026C1.33398 4.32127 4.31865 1.33594 8.00065 1.33594C11.6827 1.33594 14.6673 4.32127 14.6673 8.0026Z"
                        fill="#7489AA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.58073 8.42062C8.58073 8.74196 8.31873 9.00396 7.9974 9.00396C7.67606 9.00396 7.41406 8.74196 7.41406 8.42062V5.47396C7.41406 5.15262 7.67606 4.89062 7.9974 4.89062C8.31873 4.89062 8.58073 5.15262 8.58073 5.47396V8.42062ZM7.41733 10.5365C7.41733 10.2151 7.678 9.95312 7.99733 9.95312C8.326 9.95312 8.58733 10.2151 8.58733 10.5365C8.58733 10.8578 8.326 11.1198 8.004 11.1198C7.68067 11.1198 7.41733 10.8578 7.41733 10.5365Z"
                        fill="white"
                      />
                    </svg>
                    <Text ml={2} fontSize={"10px"} fontWeight={500}>
                      You can specify what variables you’d like to use while
                      sending your message.
                    </Text>
                  </Box>

                  <Box
                    borderRadius={"4px"}
                    borderColor={"borders.500"}
                    borderWidth={"1px"}
                    width={"100%"}
                  >
                    {isReady ? (
                      <TiptapBox
                        channel={currChannel}
                        // setPlainText={setPlainText}
                        addLinkDisclosure={addLinkDisclosure}
                        onChange={() => {
                          setTemplateBody(() => {
                            if (tiptapValue.linkObject.link) {
                              return (
                                tiptapValue.content +
                                " " +
                                tiptapValue.linkObject.link
                              );
                            } else {
                              return tiptapValue.content;
                            }
                          });
                        }}
                      />
                    ) : null}
                  </Box>

                  <Flex
                    alignSelf={"flex-end"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    fontSize={"10px"}
                    w={"106px"}
                    h={"24px"}
                    bg={"borders.200"}
                    mb={"24px"}
                  >
                    <Text fontWeight={500} color={"grays.12"}>
                      LIMIT:{" "}
                      {templateBody?.length > 0 ? templateBody?.length - 7 : 0}
                      /320 Words
                    </Text>
                  </Flex>
                  <Button
                    type="submit"
                    isLoading={creatingTemplates}
                    isDisabled={
                      currChannel === "Whatsapp"
                        ? !name || !language || !templateBody
                        : !name || !templateBody
                    }
                    w="full"
                    rounded="4px"
                    _hover={{ bg: "primary.500" }}
                    bg="primary.500"
                    _disabled={{
                      bg: "primary.400",
                    }}
                    color={"white"}
                    fontSize={"sm"}
                  >
                    Create Template
                  </Button>
                </VStack>

                <VStack>
                  <Text
                    alignSelf={"flex-start"}
                    mt={"20px"}
                    fontSize="16px"
                    fontWeight={700}
                  >
                    Preview
                  </Text>

                  {currChannel === "Whatsapp" ? (
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      px={"26px"}
                      py={"42px"}
                      borderRadius={"4px"}
                      borderColor={"borders.500"}
                      borderWidth={"1px"}
                    >
                      <Box
                        position="relative"
                        bg={"backgrounds.600"}
                        pt={"29px"}
                        px={"41px"}
                        pb={"41px"}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"8px"}
                      >
                        <Box position={"relative"}>
                          <Text
                            mx={"auto"}
                            mb={"29px"}
                            borderRadius={"8px"}
                            fontSize={"12px"}
                            bg={"backgrounds.700"}
                            w={"100px"}
                            display={"flex"}
                            justifyContent={"center"}
                            px={"auto"}
                            h={"21px"}
                            overflow={"hidden"}
                            fontWeight={600}
                          >
                            {dayjs(new Date()).format("DD MMM, YYYY")}
                          </Text>
                          <Flex justifyContent={"center"} alignItems={"center"}>
                            <Box
                              height={"156px"}
                              width={"306px"}
                              bg={"green.400"}
                              borderRadius={"8px"}
                              overflowY="auto"
                              className="thinSB"
                              position={"relative"}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"space-between"}
                            >
                              <ReactQuill
                                modules={displayModule}
                                theme="snow"
                                value={
                                  templateBody
                                    ? templateBody
                                    : "Sample message goes here..."
                                }
                                readOnly={true}
                              />
                              <Flex
                                bg={"green.400"}
                                pr={"8px"}
                                pb={"6px"}
                                bottom="8px"
                                right="11px"
                                justifyContent={"right"}
                                alignItems={"center"}
                              >
                                <Text
                                  color={"grays.12"}
                                  fontSize={"11px"}
                                  fontWeight={"400"}
                                >
                                  {dayjs(new Date()).format("hh:mm : A")}
                                </Text>
                                <ReadIcon />
                              </Flex>
                              {/* <Box
                            h={"100%"}
                            width={"100%"}
                            pb={"8px"}
                            pt={"8px"}
                            px={"12px"}
                            position={"relative"}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                          >
                            <Text
                              fontWeight={400}
                              fontSize={"14px"}
                              // pb={!templateBody ? "100px" : "0px"}
                            >
                              {templateBody
                                ? templateBody
                                : "Sample message goes here..."}
                            </Text>
                            <Flex
                              bg={"green.400"}
                              pl={"12px"}
                              // position="absolute"
                              bottom="8px"
                              right="11px"
                              justifyContent={"right"}
                              alignItems={"center"}
                            >
                              <Text
                                color={"grays.12"}
                                fontSize={"11px"}
                                fontWeight={"400"}
                              >
                                {dayjs(new Date()).format("hh:mm : A")}
                              </Text>
                              <ReadIcon />
                            </Flex>
                          </Box> */}
                            </Box>
                          </Flex>
                        </Box>
                      </Box>
                    </Flex>
                  ) : (
                    <Flex
                      borderWidth={"1px"}
                      // pt="29px"
                      borderColor={"borders.100"}
                      w="full"
                      overflow={"hidden"}
                    >
                      <Box pos="relative" transform={"scale(0.9)"}>
                        <Iphone />
                        <Box
                          pos="absolute"
                          top={16}
                          bottom={0}
                          left="33px"
                          w="full"
                          maxW={"370px"}
                        >
                          {/* header */}
                          <Center
                            h="123px"
                            flexDir={"column"}
                            textAlign="center"
                            borderBottomWidth={"1px"}
                          >
                            <Avatar name="R" w="51px" h="51px" />

                            <Text mt="4px">YOUR SENDER ID</Text>
                          </Center>

                          <Flex
                            height={"100%"}
                            mt="6px"
                            flexDir={"column"}
                            bg="#Fff"
                          >
                            {/* message info */}
                            <Box textAlign={"center"} fontWeight={500}>
                              <Text
                                textAlign={"center"}
                                fontSize="12px"
                                color="grays.12"
                              >
                                Text Message
                              </Text>
                              <Text
                                textAlign={"center"}
                                fontSize="12px"
                                color="grays.12"
                              >
                                Today {dayjs(new Date()).format("h:mm A")}
                              </Text>
                            </Box>

                            {/* Message bubble */}
                            <Box
                              mt="18px"
                              borderRadius={"19px"}
                              py="8px"
                              px="4px"
                              bg="#E9E9EB"
                              maxW="258px"
                              ml="15px"
                            >
                              {/* <TipTapReadOnly content={} /> */}
                              {removeHTMLTags(tiptapValue.content)}
                            </Box>
                          </Flex>
                        </Box>
                      </Box>
                    </Flex>
                  )}
                </VStack>
              </SimpleGrid>
            </chakra.form>
          </>
        </Box>
      </Box>
    </>
  );
};

export default CreateTemplates;
