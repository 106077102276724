import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    chakra,
    useDisclosure,
  } from "@chakra-ui/react";
import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import CustomModal from "../../../components/UI/CustomModal";
import FormInput from "../../../components/UI/form/FormInput";
import EmailEditor from "react-email-editor";
export interface ExportedData {
    design: object;
    html: string;
  }
interface Design {
    design: object;
    html: string;
  }
interface CreateTemplateProps {
    htmlContent: string;
    setHtmlContent: Dispatch<SetStateAction<string>>;
    emailEditorRef: MutableRefObject<any>;
    selectedTemplate: number | null;
    setTemplateBody: Dispatch<SetStateAction<string | string[]>>;
    setTemplateDesign: Dispatch<SetStateAction<{}[]>>;
    templateDesign?:{}[];

  }
function CreateEmailTemplate({emailEditorRef,setTemplateDesign,htmlContent,setTemplateBody,templateDesign}:CreateTemplateProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const exportHtml = () => {
      emailEditorRef?.current?.editor?.exportHtml((data:Design) => {
        const { design, html } = data;
        // console.log('exportHtml', html);
        console.log('design', (design));
        setTemplateBody([html]);
        setTemplateDesign([design]);
      });
    };
    const onLoad = () => {
      // editor instance is created
      // you can load your template here;
      // const templateJson = {};
      emailEditorRef?.current?.editor?.loadDesign(templateDesign?.[0]);
      console.log("yayyyy", templateDesign?.[0])
    }
    console.log("emailEditor",emailEditorRef?.current?.editor)

    return (
        <Box minHeight={"100vh"}>
      <Box display={"flex"} justifyContent={"end"} my={"2"}>
        <Box display={"flex"} justifyContent={"space-between"} width={"full"}>
          <Box>
            <Text fontWeight={700} fontSize={20}>
              Edit Template
            </Text>
            <Text color={"#7489AA"} fontSize={12} fontWeight={500}>
              Please create a campaign template below
            </Text>
          </Box>
          <Box>
        
           
            <Button
              mx={"2"}
              rounded={"none"}
              bg={"#0066FF"}
              color={"white"}
              colorScheme="primary"
              onClick={exportHtml}
            >
              Save
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Email Template Preview</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <iframe
                    title="Email Preview"
                    srcDoc={htmlContent}
                    width="100%"
                    height="500px"
                    style={{ border: "none" }}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Box>
      <EmailEditor
        // onReady={onReady}
        ref={emailEditorRef}
        onLoad={onLoad}
        minHeight={"100vh"}
        options={{
          appearance: {
            panels: {
              tools: {
                dock: "left",
              },
            },
          },
        }}
      />
    </Box>
    );
}

export default CreateEmailTemplate;