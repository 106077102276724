import { Box, Button, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";


const domains = [
    { id: 1, type: "cname", host: "s2._domainkey.sendchamip.com", value: "s2.domainkey.u18785994.wl245.sendgrid.net", status: "Unverified" },
    { id: 2, type: "cname", host: "s2._domainkey.sendchamip.com", value: "s2.domainkey.u18785994.wl245.sendgrid.net", status: "Unverified" },
    { id: 3, type: "cname", host: "s2._domainkey.sendchamip.com", value: "s2.domainkey.u18785994.wl245.sendgrid.net", status: "Unverified" },
    
  ];

function Verification() {
  return (
    <Box w={"1057px"} m={"auto"} mt={10}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
        <Text fontWeight={700} fontSize={14}>
          Perform domain and sender authentication
        </Text>
        <Text color={"#7489AA"} fontSize={12} fontWeight={500}>
          You will need to install the records to complete the process
        </Text>
        </Box>
        <Box>
            <Button color={'#0066FF'} border={'1px solid #0066FF'} w={'96px'} h={'40px'} bg={'none'}>Verify</Button>
        </Box>
      </Box>

      <Table variant="simple" mt={5} w={'full'} border={'1px solid #0066FF1F'}>
      <Thead>
        <Tr>
          <Th border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#7489AA'}>Type</Th>
          <Th border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#7489AA'}>Host</Th>
          <Th border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#7489AA'}>Value</Th>
          <Th border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#7489AA'}>Status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {domains.map((domain) => (
          <Tr key={domain.id} >
            <Td border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#171D26'}>{domain.type} <Box display={'inline'} marginLeft={'70px'} border={'1px solid #7489AA'} color={'#7489AA'} fontSize={8} p={1} fontWeight={500}>Copy</Box> </Td>
            <Td border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#171D26'}>{domain.host} <Box display={'inline'} marginLeft={'70px'} border={'1px solid #7489AA'} color={'#7489AA'} fontSize={8} p={1} fontWeight={500}>Copy</Box> </Td>
            <Td border={'1px solid #0066FF1F'} fontSize={12} fontWeight={500} color={'#171D26'}>{domain.value} <Box display={'inline'} marginLeft={'70px'} border={'1px solid #7489AA'} color={'#7489AA'} fontSize={8} p={1} fontWeight={500}>Copy</Box> </Td>
            <Td border={'1px solid #0066FF1F'}><Box display={'inline'} border={'1px solid #7489AA'} color={'#7489AA'} fontSize={12} p={2} rounded={'2xl'} fontWeight={500}>{domain.status}</Box></Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    <Box m={'auto'} mt={10} w={'306px'}>
    <Button  w={'306px'}  rounded={'lg'} color={'white'} bg={'#B2B6BD'}>Next</Button>
    </Box>
    </Box>
  );
}

export default Verification;
