import { Box, Button, Checkbox, Text, Image} from "@chakra-ui/react";
import { Dispatch, SetStateAction, MutableRefObject } from "react";

interface SelectTemplateProps{
    setSelected:Dispatch<SetStateAction<boolean>>;
    selectedTemplate: number|null;
    setSelectedTemplate:Dispatch<SetStateAction<number|null>>;

}
function SelectEmailTemplate({selectedTemplate,setSelected, setSelectedTemplate}:SelectTemplateProps) {
    function handleSelection(){
        setSelected(true);
    }
    return (
        <Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
          <Text fontWeight={700} fontSize={20} > Select Email Template</Text>
          <Text color={'#7489AA'} fontSize={12} fontWeight={500}> Select your campaign type below</Text>
          </Box>
          <Box> <Button mx={'2'} rounded={'md'} bg={'#0066FF'} color={'white'} w={'133px'} onClick={handleSelection} >Next</Button></Box>
        </Box>
        <Box mt={10} display={'flex'}>
          <Box height={259.92} w={241.63} border={selectedTemplate === 0 ? '1px solid #0066FF': '1px solid #D6E7FF'} mr={5} role="button" onClick={()=>{setSelectedTemplate(0)}}>
              <Box h={'80%'} bg={'#E6E6E680'}>
                  <Box display={'flex'} justifyContent={'end'} p={2}>
                  <Checkbox isChecked={selectedTemplate === 0} bg={"white"}/>
                  </Box>
                  <Box mt={10}>
                  <Image src="/blank.png" margin={'auto'}/>
                  </Box>
              </Box>
              <Text fontWeight={500} fontSize={12} textAlign={'center'} mt={3}>Blank Template</Text>
  
          </Box>
  
          <Box height={259.92} w={241.63} border={selectedTemplate === 1 ? '1px solid #0066FF': '1px solid #D6E7FF'} mr={5} role="button" onClick={()=>{setSelectedTemplate(1)}} >
              <Box h={'80%'} bg={'#F7FBFF'}>
                  <Box display={'flex'} justifyContent={'end'} p={2}>
                  <Checkbox isChecked={selectedTemplate === 1} defaultChecked bg={"white"}/>
                  </Box>
                  <Box mt={10}>
                  {/* <Image src="/blank.png" margin={'auto'}/> */}
                  </Box>
              </Box>
              <Text fontWeight={500} fontSize={12} textAlign={'center'} mt={3}>Template 2</Text>
  
          </Box>
        </Box>
      </Box>
    );
}

export default SelectEmailTemplate;