import {
  StatNumber,
  Stat as ChakraStat,
  StatHelpText,
  StatLabel,
  Badge,
} from "@chakra-ui/react";
import React from "react";

interface StatProps {
  label: string;
  amount: string | number;
  subText?: string | React.ReactNode;
  preText?: string | React.ReactNode;
  mainStat?: boolean;
}
export default function Stat({
  label,
  amount,
  subText,
  preText,
  mainStat,
}: StatProps) {
  return (
    <ChakraStat p="1rem">
      {preText && (
        <StatHelpText
          textTransform={"uppercase"}
          fontSize={"12px"}
          fontWeight={500}
          color={"grays.12"}
          mb={2}
        >
          {preText}
        </StatHelpText>
      )}
      <StatLabel
        mb={!preText ? 3 : 0}
        color={"grays.8"}
        fontSize={mainStat ? "12px" : "14px"}
        fontWeight={mainStat ? 600 : 500}
        textTransform={mainStat ? "uppercase" : "capitalize"}
      >
        {label}
      </StatLabel>
      <StatNumber fontSize={"20px"}>{amount}</StatNumber>
      {subText && (
        <StatHelpText color={"grays.8"} fontSize={"14px"} fontWeight={500}>
          {subText}
        </StatHelpText>
      )}
    </ChakraStat>
  );
}
