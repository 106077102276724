import {
  Box,
  Text,
  Button,
  HStack,
  Flex,
  useDisclosure,
  // Input,
  // FormLabel,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getListData } from "../../../API/account/list";
// import { getAllContacts } from "../../../API/contacts";
// import { ArrowDown } from "../../../assets/svgs/svg";
import { createCampaignAtom } from "../../../atoms/campaigns";
import AddNewList from "../../account/lists/AddNewList";
import CustomModal from "../../UI/CustomModal";
// import SearchableSelect from "../../UI/form/SearchableSelect";
// import { DropDownMenu } from "../../Ziz_components/drop-down/dropDown";
import { SwipeUp } from "../../animations";
import SearchableSelect from "../../UI/form/SearchableSelect";

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  color: string;
  no_of_contacts: number;
  created_at: Date;
  updated_at: Date;
}

const RecipientsStage = () => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  // const [dataToShow] = useState(15);
  // const [myCurrentPage] = useState(1);

  // const { data: Contacts } = useQuery(
  //   ["allContacts", dataToShow, myCurrentPage],
  //   () => {
  //     return getAllContacts({
  //       page: myCurrentPage,
  //       limit: dataToShow,
  //     });
  //   }
  // );

  // const [MenuLabel, setMenuLabel] = useState({});
  // const [onselectLabel, setOnSelectLabel] = useState(false);

  const topup = true;

  const { data } = useQuery(["listData"], getListData);
  const allLists = useMemo(() => {
    return data?.map((list: listProps) => {
      return {
        label: `${list?.name} (${list.no_of_contacts} contacts)`,
        value: list?.uid,
      };
    });
  }, [data]);

  const addListDisclosure = useDisclosure();
  // console.log("we havveeeee", allLists);

  return (
    <Box w="full" maxW={"480px"} mx="auto">
      {/* header */}
      <Text fontSize={"20px"} fontWeight={700} color="grays.8">
        This campaign will be sent to
      </Text>

      {/* form */}
      <Flex mt="26px" flexDirection={"column"}>
        {/* <FormLabel fontWeight={700} fontSize="12px">
            Select a list
          </FormLabel> */}
        {/* @ts-ignore */}
        {/* <DropDownMenu
            menuOptionStyle={{
              // width: '10em',
              paddingBottom: ".4em",
              paddingTop: ".4em",
              zIndex: "1",
              border: "1px solid rgba(0, 102, 255, 0.16)",
            }}
            MenubtnStyle={{
              width: "100%",
              border: "1px solid rgba(0, 102, 255, 0.16)",
              color: "#171D26",
            }}
            btnVariant="primary2"
            floatLeft
            svgIcon={
              <Box>
                <ArrowDown fill="#171D26" />
              </Box>
            }
            px="4"
            listOptionRole={"button"}
            customComponent={
              <Input
                mt=".4em"
                mx="auto"
                py=".2em"
                type="text"
                placeholder="Search"
                _placeholder={{
                  color: "#7489AA",
                  fontSize: "11px",
                }}
                w="96%"
                //  h = '1.9em'
              />
            }
            menuTitle={
              onselectLabel
                ? MenuLabel
                : `All Contacts (${Contacts?.totalDocs})`
            }
            menuListOptions={allLists}
            optionTitle="List"
            //  optionValue = {currentStageData?.data?.list_uid}
            //  optionValue={allLists?.filter((list: { label: string; optionValue: string }) => {
            //   return list?.optionValue === currentStageData?.data?.list_uid;
            // })}
            onOptionClick={(e: any) => {
              setOnSelectLabel(true);
              // @ts-ignore
              setMenuLabel(e.target.innerText);
              setCurrentStageData((prevValue) => {
                return {
                  ...prevValue,
                  data: {
                    ...prevValue.data,
                    list_uid: e.target.dataset.key,
                  },
                };
              });
            }}
          /> */}

        {/* TODO: add segemnt dropdown tab */}
        <SearchableSelect
          label="Select a list"
          placeholder="Select your Recipeints"
          options={allLists}
          value={allLists?.filter((list: { label: string; value: string }) => {
            return list?.value === currentStageData?.data?.list_uid;
          })}
          onChange={(e: { label: string; value: string }) => {
            setCurrentStageData((prevValue) => {
              return {
                ...prevValue,
                data: {
                  ...prevValue.data,
                  list_uid: e.value,
                },
              };
            });
          }}
        />

        {/* Add list button */}
        <Button
          // onClick={() => console.log(MenuLabel)}
          size="xs"
          variant={"ghost"}
          colorScheme="primary"
          mt="3px"
          ml="auto"
          onClick={addListDisclosure.onOpen}
        >
          + Add list
        </Button>
      </Flex>

      {!topup && (
        <>
          <SwipeUp delay={0.5} style={{ width: "100%" }}>
            <HStack
              mt="12px"
              py="8px"
              px={"16px"}
              bg="rgba(251, 167, 5, 0.08)"
              rounded={"2px"}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6663 8.0026C14.6663 11.6853 11.6817 14.6693 7.99967 14.6693C4.31767 14.6693 1.33301 11.6853 1.33301 8.0026C1.33301 4.32127 4.31767 1.33594 7.99967 1.33594C11.6817 1.33594 14.6663 4.32127 14.6663 8.0026Z"
                  fill="#FBA705"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.57975 8.42062C8.57975 8.74196 8.31775 9.00396 7.99642 9.00396C7.67509 9.00396 7.41309 8.74196 7.41309 8.42062V5.47396C7.41309 5.15262 7.67509 4.89062 7.99642 4.89062C8.31775 4.89062 8.57975 5.15262 8.57975 5.47396V8.42062ZM7.41636 10.5365C7.41636 10.2151 7.67702 9.95312 7.99636 9.95312C8.32502 9.95312 8.58636 10.2151 8.58636 10.5365C8.58636 10.8578 8.32502 11.1198 8.00302 11.1198C7.67969 11.1198 7.41636 10.8578 7.41636 10.5365Z"
                  fill="white"
                />
              </svg>

              <Text fontSize={"10px"} color="grays.8">
                To send this campaign to 1500 contacts, you have to topup your
                wallet balance with additional N500
              </Text>
            </HStack>

            <Link to="/wallet?topup=true">
              <Button
                mb="20px"
                w="full"
                mt="40px"
                colorScheme={"primary"}
                variant="outline"
              >
                Top up wallet
              </Button>
            </Link>
          </SwipeUp>
        </>
      )}

      <Button
        isDisabled={!currentStageData?.data?.list_uid}
        onClick={() => {
          // console.log(allLists);
          setCurrentStageData((prevData) => {
            return {
              ...prevData,
              currentStage: "Settings",
            };
          });
        }}
        w="full"
        mt={!topup ? "" : "40px"}
        mb="10px"
        colorScheme={"primary"}
      >
        Continue to settings
      </Button>

      <CustomModal
        titleUnderLine={true}
        disclosure={addListDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Create new list
          </Text>
        }
      >
        <AddNewList close={addListDisclosure.onClose} />
      </CustomModal>
    </Box>
  );
};

export default RecipientsStage;
