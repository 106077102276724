import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
  HStack,
  useDisclosure,
  Checkbox,
  Button,
  useToast,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import CustomMenu from "../UI/CustomMenu";
// import ChevronDown from "../../icons/ChevronDown";
import CustomModal from "../UI/CustomModal";
import { useNavigate } from "react-router-dom";
import { actualListProps } from "../account/lists/ListsTable";
import { senderIdListProps } from "../account/SenderIDs/SenderIDListTable";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCampaign } from "../../API/campaign";

import { fuzzyFilter } from "../../utils/table";
import { toaster } from "../../utils/toast";
import { ArrowDown } from "../../assets/svgs/svg";
import { SwipeIn } from "../animations";

interface listProps {
  id: number | string;
  uid: string;
  company_uid: string;
  list_uid: string;
  sms_sender_uid: string;
  campaign_name: string;
  message_body: string;
  no_of_contacts: number;
  timezone: string;
  send_date: Date | string;
  status: string;
  channel: string;
  sent_at: Date | string;
  created_at: Date | string;
  updated_at: Date | string;
  list: actualListProps;
  sms_sender: senderIdListProps;
}


const CampaignList: FC<{
  data: listProps[];
  globalFilter: any;
  setGlobalFilter: any;
}> = ({ data, globalFilter, setGlobalFilter }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [idToDelete, setIdToDelete] = useState("");
  const [channel, setChannel] = useState("");

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const deleteDisclosure = useDisclosure();

  // const setCampaignToEdit = useSetRecoilState(createCampaignAtom);

  // accessor functions are for when you need to concatenate data e.g campaignName and Contacts
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    const statusColorsToUse = (status: string) => {
      switch (status) {
        case "sent":
          return {
            color: "#159D6F",
            bg: "rgba(21, 157, 111, 0.04)",
          };
        case "in-progress":
          return {
            color: "rgb(220, 168, 10)",
            bg: "rgba(220, 168, 10, 0.04)",
          };
        case "scheduled":
          return {
            color: "rgb(0, 102, 255)",
            bg: "rgba(0, 102, 255, 0.04)",
          };
        case "draft":
          return {
            color: "rgb(25, 63, 100)",
            bg: "rgba(25, 63, 100, 0.04)",
          };

        default:
      }
    };

    return [
      {
        header: "",
        id: "checkbox",
        cell: (props: any) => <Checkbox />,
      },
      {
        header: () => <Text>Campaign name</Text>,
        accessorKey: "campaign_name",
        id: "campaign_name",
        cell: (props: any) => {
          return <Text>{props?.getValue() || "---"}</Text>;
        },
      },
      {
        header: () => <Text>Contacts</Text>,
        accessorKey: "no_of_contacts",
        id: "no_of_contacts",
        cell: (props: any) => {
          return <Text>{props?.getValue() || "---"}</Text>;
        },
      },
      {
        header: () => <Text>Channels</Text>,
        accessorKey: "channel",
        id: "channel",
        cell: (props: any) => {
          return (
            <Box
              bg="rgba(116, 137, 170, 0.04)"
              w="fit-content"
              py="4px"
              px="8px"
              rounded="full"
              borderWidth={"0.5px"}
              borderColor="grays.12"
            >
              {props?.getValue()}
            </Box>
          );
        },
      },

      {
        header: () => <Text>Created at</Text>,
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Sent at",
        accessorKey: "sent_at",
        id: "sent_at",
        cell: (props: any) => {
          return (
            <Text>
              {props?.getValue()
                ? dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")
                : "---"}
            </Text>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        id: "status",
        cell: (props: any) => {
          return (
            <Box
              bg={statusColorsToUse(props?.getValue())?.bg}
              w="fit-content"
              py="4px"
              px="8px"
              rounded="full"
              borderWidth={"0.5px"}
              borderColor={statusColorsToUse(props?.getValue())?.color}
              color={statusColorsToUse(props?.getValue())?.color}
              textTransform="capitalize"
              fontWeight={500}
            >
              {props?.getValue() === "in-progress"
                ? "In Progress"
                : props?.getValue()}
            </Box>
          );
        },
      },

      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <CustomMenu
            buttonTitle={
              <HStack spacing={"14px"}>
                <Text fontWeight={"medium"}>View actions</Text>
                <ArrowDown width="14px" height="14px" />
              </HStack>
            }
            listProps={{
              minWidth: "140px",
            }}
            itemsData={[
              {
                title: "Reports",
                itemProps: {
                  fontWeight: "medium",
                  display:
                    props?.row?.original?.status === "draft" ? "none" : "flex",
                  onClick: () => {
                    navigate(
                      `/campaigns/${props?.row?.original?.channel?.toLowerCase()}/${
                        props?.row?.original?.uid
                      }/reports`
                    );
                  },
                },
              },
              {
                title: "Complete campaign",
                itemProps: {
                  fontWeight: "medium",
                  display:
                    props?.row?.original?.status === "draft" ? "flex" : "none",
                  onClick: () => {
                    // @ts-ignore
                    // setCampaignToEdit((prev) => {
                    //   return {
                    //     ...prev,
                    //     data: {
                    //       ...prev?.data,
                    //       campaign_name: props?.row?.original?.campaign_name,
                    //       list_uid: props?.row?.original?.list_uid,
                    //       sms_sender_uid: props?.row?.original?.sms_sender_uid,
                    //       timezone: props?.row?.original?.timezone,
                    //       send_date: props?.row?.original?.send_date,
                    //       message_body: props?.row?.original?.message_body,
                    //     },
                    //   };
                    // });
                    navigate(
                      `/campaigns/new?uid=${props?.row?.original?.uid}&channel=${props?.row?.original?.channel}`
                    );
                    //
                  },
                },
              },

              {
                title: "Delete campaign",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    deleteDisclosure.onOpen();
                    setIdToDelete(props?.row?.original?.uid);
                    setChannel(props?.row?.original?.channel);
                  },
                },
              },
            ]}
          />
        ),
      },
    ];
  }, [deleteDisclosure, navigate]);

  const { mutate, isLoading } = useMutation(
    () => deleteCampaign(idToDelete, channel),
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["allCampaignsData"]);
        deleteDisclosure.onClose();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
  });

  return (
    <>
      <TableContainer overflowX="unset" overflowY="unset">
        <Table variant={"simple"} position={"relative"}>
          <Thead
            position={"sticky"}
            top={0}
            bg={"white"}
            boxShadow={"sm"}
            zIndex={1}
          >
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    <SwipeIn delay={0.2 * index}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </SwipeIn>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Delete campaign */}
      <CustomModal disclosure={deleteDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Delete campaign
          </Text>
          <Box
            mt="5px"
            fontSize={"sm"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
            mx="auto"
          >
            <Text>
              Following the deletion of this campaign, all transaction reports
              will continue to be available in the campaign transaction page.
            </Text>
          </Box>

          <Button
            isLoading={isLoading}
            onClick={() => {
              mutate();
            }}
            w="full"
            colorScheme={"red"}
            mt="40px"
          >
            Delete campaign
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default CampaignList;
