import { Box, Checkbox, SimpleGrid, Text } from "@chakra-ui/react";
import { BuilderInfoType } from "../../../pages/Campaigns/Email/newCampaign";
import { Dispatch, SetStateAction } from "react";
import {
  TBuilderIcon,
  VBuilderIcon,
} from "../../../pages/Campaigns/Email/icons";

const SetEmailContentType = ({
  builderInfo,
  setBuilderInfo,
}: {
  builderInfo: BuilderInfoType;
  setBuilderInfo: Dispatch<SetStateAction<BuilderInfoType>>;
  onSubmit: () => void;
}) => {
  return (
    <Box>
      <Text mb={1} fontSize={"20px"} fontWeight={600} color="grays.8">
        Select Content Type
      </Text>
      <Text mb={8} color={"grays.12"} fontWeight={400} fontSize="sm" mt="6px">
        Select your email content type below
      </Text>

      <SimpleGrid columns={2} gap="36px">
        <Box
          role={"button"}
          tabIndex={0}
          position={"relative"}
          borderWidth={"1px"}
          borderRadius="2px"
          borderColor={
            builderInfo.content_type === "visual" ? "#0066FF50" : "#E6E6E6"
          }
          bg={builderInfo.content_type === "visual" ? "#F7FBFF" : "transparent"}
          p={"20px"}
          _hover={{
            borderColor: "#0066FF50",
          }}
          transition={"all 0.25s ease-in-out"}
          userSelect="none"
          onClick={() => {
            setBuilderInfo((prev) => ({
              ...prev,
              content_type: "visual",
            }));
          }}
        >
          <Box position={"absolute"} zIndex={2} top={4} right={4}>
            <Checkbox
              isChecked={builderInfo.content_type === "visual"}
              colorScheme={"primary"}
            />
          </Box>
          <Box mb={3}>
            <VBuilderIcon selected={builderInfo.content_type === "visual"} />
          </Box>
          <Text
            mb={3}
            fontSize={"md"}
            fontWeight={600}
            color={
              builderInfo.content_type === "visual" ? "#0066FF" : "grays.8"
            }
          >
            Visual Builder
          </Text>
          <Text
            mb={6}
            color={"grays.12"}
            fontWeight={400}
            fontSize="sm"
            mt="6px"
          >
            Create your emails in a visual sandbox, preview, edit and save.
          </Text>
        </Box>

        <Box
          role={"button"}
          tabIndex={0}
          position={"relative"}
          borderWidth={"1px"}
          borderRadius="2px"
          borderColor={
            builderInfo.content_type === "text" ? "#0066FF50" : "#E6E6E6"
          }
          bg={builderInfo.content_type === "text" ? "#F7FBFF" : "transparent"}
          p={"20px"}
          _hover={{
            borderColor: "#0066FF50",
          }}
          transition={"all 0.25s ease-in-out"}
          userSelect="none"
          onClick={() => {
            setBuilderInfo((prev) => ({
              ...prev,
              content_type: "text",
            }));
          }}
        >
          <Box position={"absolute"} zIndex={2} top={4} right={4}>
            <Checkbox
              isChecked={builderInfo.content_type === "text"}
              colorScheme={"primary"}
            />
          </Box>
          <Box mb={3}>
            <TBuilderIcon selected={builderInfo.content_type === "text"} />
          </Box>
          <Text
            mb={3}
            fontSize={"md"}
            fontWeight={600}
            color={builderInfo.content_type === "text" ? "#0066FF" : "grays.8"}
          >
            Text/HTML Builder
          </Text>
          <Text
            mb={6}
            color={"grays.12"}
            fontWeight={400}
            fontSize="sm"
            mt="6px"
          >
            Quickly create your emails with plain text.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default SetEmailContentType;
