import {
  Box,
  Center,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import SmsSender from "../../components/account/SenderIDs/SmsSender";
import EmailSender from "../../components/account/SenderIDs/EmailSender";

const SenderIDs = () => {
  const [isActive, setIsActive] = useState(1);

  

  return (
    <Box w="full" h="full">
      <Flex
        w="full"
        px={{ base: "24px", md: "40px" }}
        alignItems={"center"}
        borderColor={"#eee"}
        mb={5}
      >
        <HStack m="auto" spacing={"25px"} borderBottomWidth="1px">
          <Center
            flexDir={"column"}
            textAlign="center"
            pt="20px"
            pb="10px"
            mb={"-3px"}
            px="32px"
            cursor={"pointer"}
            borderBottom={isActive === 1 ? "1px solid #0066FF" : "none"}
            onClick={() => {
              setIsActive(1);
            }}
          >
            <Text
              fontWeight={500}
              fontSize="10px"
              mt="8px"
              color={isActive === 1 ? "#171D26" : "#7489AA"}
            >
              1. SMS
            </Text>
          </Center>
          <Center
            flexDir={"column"}
            textAlign="center"
            pt="20px"
            pb="10px"
            mb={"-3px"}
            px="32px"
            cursor={"pointer"}
            borderBottom={isActive === 2 ? "1px solid #0066FF" : "none"}
            onClick={() => {
              setIsActive(2);
            }}
          >
            <Text
              fontWeight={500}
              fontSize="10px"
              mt="8px"
              color={isActive === 2 ? "#171D26" : "#7489AA"}
            >
              2. Email
            </Text>
          </Center>
        </HStack>
      </Flex>

      {isActive === 1 ? <SmsSender/>:<EmailSender/>}
    </Box>
  );
};

export default SenderIDs;
