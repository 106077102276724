import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import CustomMenu from "../../UI/CustomMenu";
// import ChevronDown from '../../../icons/ChevronDown';
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import CustomModal from "../../UI/CustomModal";
import EditList from "./EditList";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteList } from "../../../API/account/list";
import { fuzzyFilter } from "../../../utils/table";
import { toaster } from "../../../utils/toast";
import { ArrowDown } from "../../../assets/svgs/svg";
import Loader from "../../UI/Loader";
import { SwipeIn } from "../../animations";

export interface actualListProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  color: string;
  no_of_contacts: number;
  created_at: Date | string;
  updated_at: Date | string;
}

const ListsTable: FC<{
  listsData: actualListProps[];
  globalFilter: any;
  setGlobalFilter: any;
  isLoading: boolean;
}> = ({ listsData, globalFilter, setGlobalFilter }) => {
  const navigate = useNavigate();

  const [dataToUpdate, setDataToUpdate] = useState<actualListProps>({
    id: 0,
    uid: "",
    company_uid: "",
    name: "",
    created_at: new Date(),
    updated_at: new Date(),
    no_of_contacts: 16,
    color: "",
  });

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const disclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<actualListProps>[]>(() => {
    return [
      {
        header: () => <Text>Name</Text>,
        accessorKey: "name",
        id: "name",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Date Created",
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "No. of contacts",
        accessorKey: "no_of_contacts",
        id: "no_of_contacts",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <CustomMenu
            buttonTitle={
              <HStack spacing={"14px"}>
                <Text fontWeight={"medium"}>View actions</Text>
                <ArrowDown width="14px" height="14px" />
              </HStack>
            }
            itemsData={[
              {
                title: "Edit List",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    disclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  },
                },
              },
              {
                title: "View Contacts",
                itemProps: {
                  fontWeight: "medium",

                  onClick: () => {
                    navigate(
                      `${props?.row?.original?.uid}/contacts?name=${props?.row?.original?.name}`
                    );
                    //
                  },
                },
              },
              {
                title: "Delete List",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    deleteDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  },
                },
              },
            ]}
          />
        ),
      },
    ];
  }, [deleteDisclosure, disclosure, navigate]);

  const data = listsData;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,

    debugTable: true,
  });

  // delete single list
  const queryClient = useQueryClient();

  const toast = useToast();

  const { mutate, isLoading } = useMutation(deleteList, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["listData"]);
      deleteDisclosure.onClose();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <Box
      h={"62vh"}
      overflowY={"auto"}
      className="thinSB"
      boxShadow={"xs"}
      bg={"white"}
    >
      <TableContainer
        overflowX="unset"
        overflowY="unset"
        borderWidth={"1px"}
        borderColor="borders.200"
        rounded={"8px"}
      >
        <Table variant={"simple"} position={"relative"}>
          <Thead position={"sticky"} top={0} bg={"white"} boxShadow={"xs"}>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          {isLoading ? (
            <Loader />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <Td
                      key={cell.id}
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={"medium"}
                    >
                      <SwipeIn delay={0.2 * index}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </SwipeIn>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>

      {/* Edit list disclosure */}
      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Edit list
          </Text>
        }
      >
        <EditList data={dataToUpdate} close={disclosure.onClose} />
      </CustomModal>

      {/* Delere list */}
      <CustomModal disclosure={deleteDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Delete list
          </Text>
          <Text
            mt="5px"
            fontSize={"sm"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
            mx="auto"
          >
            Following the deletion of the list, the contacts are not deleted,
            but instead assigned to the default list.
          </Text>

          <Button
            w="full"
            isLoading={isLoading}
            onClick={() => {
              mutate(dataToUpdate?.uid);
            }}
            colorScheme={"red"}
            mt="40px"
          >
            Delete list
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default ListsTable;
