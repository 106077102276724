import {
  Flex,
  Text,
  chakra,
  HStack,
  Button,
  Input,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { getAllCampaigns } from "../../API/campaign";
import CampaignList from "../../components/campaigns/CampaignList";
import Empty from "../../components/UI/Empty";
import ChakraSelect from "../../components/UI/form/ChakraSelect";
import Loader from "../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import { useState } from "react";
import CustomPaginaton from "../../components/UI/CustomPaginaton";
import { PageAnimate } from "../../components/animations/pageAnimate";

const Campaigns = () => {
  const navigate = useNavigate();

  const [dataToShow, setDataToShow] = useState(10);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [campaignType, setCampaignType] = useState("sms");

  const { isLoading, data } = useQuery(
    ["allCampaignsData", dataToShow, myCurrentPage, campaignType],
    () => {
      return getAllCampaigns({
        page: myCurrentPage,
        limit: dataToShow,
        type: campaignType,
      });
    }
  );
  console.log("fetched data",data)

  const dataEmail=[
    {
    id: 1,
    uid: '01',
    company_uid: 'company',
    list_uid: "the uid",
    sms_sender_uid: "sms sender",
    campaign_name: "new campaign",
    message_body: 'new message',
    no_of_contacts: 5,
    timezone: 'WAT',
    send_date: '24/7/2024',
    status: 'scheduled',
    channel: 'Email',
    sent_at: "25/7/2024",
    created_at: '24/7/2024',
    updated_at: "25/7/2024",
    list: {
    id: 1,
    uid: "01",
    company_uid: "company",
    name: "new",
    color: "red",
    no_of_contacts: 5,
    created_at:'24/7/2024',
    updated_at:'25/7/2024',
    },
    sms_sender:{
      id: 1,
    uid: "01",
    company_uid: "company",
    name: "new",
    status: "Scheduled",
    created_at:'24/7/2024',
    updated_at:'25/7/2024',
    }
    },
    {
      id: 1,
      uid: '01',
      company_uid: 'company',
      list_uid: "the uid",
      sms_sender_uid: "sms sender",
      campaign_name: "new campaign",
      message_body: 'new message',
      no_of_contacts: 5,
      timezone: 'WAT',
      send_date: '24/7/2024',
      status: 'in-progress',
      channel: 'Email',
      sent_at: "25/7/2024",
      created_at: '24/7/2024',
      updated_at: "25/7/2024",
      list: {
      id: 1,
      uid: "01",
      company_uid: "company",
      name: "new",
      color: "red",
      no_of_contacts: 5,
      created_at:'24/7/2024',
      updated_at:'25/7/2024',
      },
      sms_sender:{
        id: 1,
      uid: "01",
      company_uid: "company",
      name: "new",
      status: "Scheduled",
      created_at:'24/7/2024',
      updated_at:'25/7/2024',
      }
      },
      {
        id: 1,
        uid: '01',
        company_uid: 'company',
        list_uid: "the uid",
        sms_sender_uid: "sms sender",
        campaign_name: "new campaign",
        message_body: 'new message',
        no_of_contacts: 5,
        timezone: 'WAT',
        send_date: '24/7/2024',
        status: 'sent',
        channel: 'Email',
        sent_at: "25/7/2024",
        created_at: '24/7/2024',
        updated_at: "25/7/2024",
        list: {
        id: 1,
        uid: "01",
        company_uid: "company",
        name: "new",
        color: "red",
        no_of_contacts: 5,
        created_at:'24/7/2024',
        updated_at:'25/7/2024',
        },
        sms_sender:{
          id: 1,
        uid: "01",
        company_uid: "company",
        name: "new",
        status: "Scheduled",
        created_at:'24/7/2024',
        updated_at:'25/7/2024',
        }
        },
        {
          id: 1,
          uid: '01',
          company_uid: 'company',
          list_uid: "the uid",
          sms_sender_uid: "sms sender",
          campaign_name: "new campaign",
          message_body: 'new message',
          no_of_contacts: 5,
          timezone: 'WAT',
          send_date: '24/7/2024',
          status: 'draft',
          channel: 'Email',
          sent_at: "25/7/2024",
          created_at: '24/7/2024',
          updated_at: "25/7/2024",
          list: {
          id: 1,
          uid: "01",
          company_uid: "company",
          name: "new",
          color: "red",
          no_of_contacts: 5,
          created_at:'24/7/2024',
          updated_at:'25/7/2024',
          },
          sms_sender:{
            id: 1,
          uid: "01",
          company_uid: "company",
          name: "new",
          status: "Scheduled",
          created_at:'24/7/2024',
          updated_at:'25/7/2024',
          }
          },
  ]
  // console.log("real data", data.docs)

  const [globalFilter, setGlobalFilter] = useState("");

  // focus bg
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(true);
  const [isFocused3, setIsFocused3] = useState(false);

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <PageAnimate>
      <Flex flexDir={"column"} w="full" pt={"16px"} pb="40px">
        {/* header */}
        <Flex w="full" justifyContent={"space-between"}>
          <chakra.h1
            fontSize={"20px"}
            color="grays.8"
            fontWeight={700}
            my="auto"
          >
            Manage campaigns
          </chakra.h1>

          <Link to="/campaigns/new">
            <Button colorScheme={"primary"} ml="auto" size="sm">
              New campaign
            </Button>
          </Link>
        </Flex>

        <Box>
          <SimpleGrid
            gridTemplateColumns="14% 86%"
            mt="24px"
            py="20px"
            bg="white"
          >
            <Box
              py="1.5em"
              fontSize="12px"
              fontWeight="500"
              borderRight="1px solid rgba(0, 102, 255, 0.08)"
              transition={"200ms ease-in"}
            >
              <Text px="1.5em" fontWeight="700" pb="2em">
                Channels
              </Text>

              {/* <Box
                bg={isFocused1 ? "rgba(0, 102, 255, 0.09)" : ""}
                // @ts-ignore
                onClick={(e) => {
                  // @ts-ignore
                  setCampaignType(e.target?.innerText);
                  setDataToShow(10);
                  setIsFocused1(true);
                  setIsFocused2(false);
                  setIsFocused3(false);
                }}
                px="1.5em"
                cursor="default"
                py=".8em"
                _hover={{
                  bg: "rgba(0, 102, 255, 0.04)",
                }}
                _focus={{
                  bg: "rgba(0, 102, 255, 0.04)",
                }}
              >
                Whatsapp
              </Box> */}
              <Box
                bg={isFocused2 ? "rgba(0, 102, 255, 0.09)" : ""}
                // @ts-ignore
                onClick={(e) => {
                  // @ts-ignore
                  setCampaignType(e.target?.innerText);
                  setDataToShow(10);
                  setIsFocused1(false);
                  setIsFocused2(true);
                  setIsFocused3(false);
                }}
                px="1.5em"
                cursor="default"
                py=".8em"
                _hover={{
                  bg: "rgba(0, 102, 255, 0.04)",
                }}
              >
                SMS
              </Box>
              <Box
                bg={isFocused3 ? "rgba(0, 102, 255, 0.09)" : ""}
                // @ts-ignore
                onClick={(e) => {
                  // @ts-ignore
                  setCampaignType(e.target?.innerText);
                  setDataToShow(10);
                  setIsFocused1(false);
                  setIsFocused2(false);
                  setIsFocused3(true);
                }}
                px="1.5em"
                cursor="default"
                py=".8em"
                _hover={{
                  bg: "rgba(0, 102, 255, 0.04)",
                }}
              >
                Email
              </Box>
              {/* <Box
                // bg = {isFocusd? 'rgba(0, 102, 255, 0.04)' : ''}
                px="1.5em"
                cursor="default"
                py=".8em"
                _hover={{
                  bg: "rgba(0, 102, 255, 0.04)",
                }}
              >
                Voice
              </Box> */}
            </Box>

            <Flex
              py=".7em"
              flexDir={"column"}
              // px={{ base: '10px', md: '40px' }}
            >
              {/* table filters */}
              <Flex rounded="4px" pb="16px" px="20px">
                {/* toggle no of entries shown */}
                <HStack spacing={"10px"}>
                  <Text color="grays.12" fontWeight={500} fontSize="xs">
                    Showing
                  </Text>

                  <ChakraSelect
                    selectProps={{
                      h: "24px",
                      borderColor: "grays.12",
                      fontWeight: 500,
                      color: "grays.12",
                      // @ts-ignore
                      onChange: (e) => {
                        // @ts-ignore
                        setDataToShow(Number(e.target.value));
                      },
                    }}
                  >
                    <chakra.option value={10}>10</chakra.option>
                    <chakra.option value={15}>15</chakra.option>
                    <chakra.option value={20}>20</chakra.option>
                    <chakra.option value={25}>25</chakra.option>
                    <chakra.option value={30}>30</chakra.option>
                  </ChakraSelect>

                  <Text color="grays.12" fontWeight={500} fontSize="xs">
                    entries
                  </Text>
                </HStack>
                <HStack spacing={"10px"} mx={"10"}></HStack>

                {/* search input */}
                <Input
                  ml="auto"
                  type="search"
                  placeholder="Search by campaign name, channel type.."
                  borderRadius={"8px"}
                  borderColor="borders.100"
                  _hover={{
                    borderColor: "borders.100",
                  }}
                  color="grays.9"
                  _placeholder={{
                    color: "grays.12",
                  }}
                  fontWeight={500}
                  h="32px"
                  maxW="285px"
                  w="full"
                  fontSize={"xs"}
                  value={globalFilter ?? ""}
                  // @ts-ignore
                  onChange={(e) => {
                    setGlobalFilter(e.target.value.toString());
                  }}
                />
              </Flex>

              {!isLoading && data?.docs.length === 0 && (
                <Empty
                  // title='You currently don’t have any sender ID added'
                  description={
                    <Text as="span">You haven’t created any campaign yet</Text>
                  }
                  buttonText="Send a campaign now"
                  icon={
                    <svg
                      width="96"
                      height="96"
                      viewBox="0 0 96 96"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M77 40.0078C77 50.9234 71.4 61.0078 66 61.0078C60.6 61.0078 55 50.9234 55 40.0078C55 29.0921 60.6 19.0078 66 19.0078C71.4 19.0078 77 29.0921 77 40.0078Z"
                        stroke="#7489AA"
                        strokeWidth="5"
                      />
                      <path
                        d="M66 61.0078C66 61.0078 32 54.0078 28 53.0078C24 52.0078 19 46.765 19 40.0078C19 33.2505 24 28.0078 28 27.0078C32 26.0078 66 19.0078 66 19.0078"
                        stroke="#7489AA"
                        strokeWidth="5"
                      />
                      <path
                        d="M27 54V69C27 73.4184 30.5817 77 35 77H37C41.4184 77 45 73.4184 45 69V58"
                        stroke="#7489AA"
                        strokeWidth="5"
                      />
                    </svg>
                  }
                  buttonAction={() => {
                    navigate("/campaigns/new");
                  }}
                />
              )}

              {isLoading && <Loader />}

              {!isLoading && data?.docs.length > 0 && (
                <>
                  <Box h="60vh" overflowY={"auto"} className="thinSB">
                    <CampaignList
                      data={data?.docs}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </Box>

                  <HStack justifyContent={"flex-end"}>
                    <CustomPaginaton
                      pagesCount={pagesCount}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      pages={pages}
                    />
                  </HStack>
                </>
              )}
            </Flex>
            {/* </Box> */}
          </SimpleGrid>
        </Box>
      </Flex>
    </PageAnimate>
  );
};

export default Campaigns;
