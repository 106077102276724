import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { createCampaignAtom } from "../../../atoms/campaigns";

import SmsCapaign from "../SmsCapaign";
import WhatsappCampaign from "../WhatsappCampaign";
import EmailCampaignTextBuilder from "../EmailCampaignTextBuilder";
import EmailCampaignVisualBuilder from "../EmailCampaignVisualBuilder";

const CampaignBuilderStage: FC<{
  disclosure: any;
}> = ({ disclosure }) => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  return (
    <Flex
      flexDir={"column"}
      w="full"
      px={{ base: "14px", sm: "24px", lg: "72px" }}
    >
      {currentStageData.data.channel === "SMS" ? (
        <SmsCapaign
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
        />
      ) : currentStageData.data.channel === "Email" ? (
        currentStageData.data?.builder === "VISUAL BUILDER" ? (
          <EmailCampaignVisualBuilder 
            currentStageData={currentStageData}
            setCurrentStageData={setCurrentStageData}
            disclosure={disclosure}
          />
        ) : (
          <EmailCampaignTextBuilder
            currentStageData={currentStageData}
            setCurrentStageData={setCurrentStageData}
            disclosure={disclosure}
          />
        )
      ) : (
        <WhatsappCampaign
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
        />
      )}
    </Flex>
  );
};

export default CampaignBuilderStage;
